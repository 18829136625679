import $, { Wrapper } from "untrue";

import RequestContext from "../../../../../../../context/RequestContext";
import TranslationContext from "../../../../../../../context/TranslationContext";

function Load({ requestKey, parentId, loading, childSentByLogged }) {
  const onClick = () => {
    RequestContext.request(requestKey, { message: { messageId: parentId } });
  };

  return $(
    "div",
    {
      class: `rounded-10 flex justify-between align-center cursor-pointer ${
        childSentByLogged ? "bg-color-primary-80" : "bg-color-secondary-15"
      }`,
      onclick: onClick,
    },
    [
      $(
        "div",
        { class: "px-10 py-5 flex-1" },
        $(
          "span",
          {
            class: `font-bold font-size-14 ${
              childSentByLogged
                ? "color-white opacity-75%"
                : "color-secondary-60"
            }`,
          },
          TranslationContext.getData().chats.seeMessage
        )
      ),
      loading
        ? $(
            "div",
            {
              class: `w-25 h-25 flex justify-center align-center font-size-16 ${
                childSentByLogged
                  ? "color-white opacity-75%"
                  : "color-secondary-60"
              }`,
            },
            $("i", { class: "fas fa-circle-notch fa-spin" })
          )
        : null,
    ]
  );
}

export default Wrapper.wrapContext(Load, RequestContext, ({ requestKey }) => {
  const requests = RequestContext.getRequests();

  const { loading = false } = requests?.[requestKey] ?? {};

  return { loading };
});
