import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import Helper from "../helpers/Helper";

import DocumentContext from "../context/DocumentContext";

function RichText({
  style = null,
  linkStyle = null,
  text,
  mentionIds,
  mentionNames,
  mentionPermalinks,
}) {
  const tmpText = text.replace(/\n{3,}/g, "\n\n");

  const paragraphs = tmpText.split("\n\n");

  return $(
    "div",
    { class: `${style !== null ? style : ""} overflow-wrap-[break-word]` },
    paragraphs.map((paragraph, i) => {
      const tmpLines = paragraph.split("\n");

      const lines = [];

      let j = 0;

      for (const tmpLine of tmpLines) {
        const textSlots = [];

        const words = tmpLine.split(" ");

        let string = "";

        let k = 0;

        for (const word of words) {
          if (word.match(new RegExp(Helper.USER_ID_PATTERN))) {
            const userId = word.slice(1);

            const index = mentionIds.indexOf(userId);

            if (index === -1) {
              string += word;

              if (k < words.length - 1) {
                string += " ";
              }
            } else {
              const name = mentionNames[index];
              const permalink = mentionPermalinks[index];

              if (string.length > 0) {
                textSlots.push(string);
              }

              textSlots.push(
                $(
                  "a",
                  {
                    class: linkStyle,
                    href: permalink,
                    onclick: Router.onClick,
                  },
                  name
                )
              );

              string = "";

              if (k < words.length - 1) {
                string += " ";
              }
            }
          } else if (word.match(new RegExp(Helper.HASHTAG_PATTERN))) {
            const hashtag = word.slice(1);

            if (string.length > 0) {
              textSlots.push(string);
            }

            textSlots.push(
              $(
                "a",
                {
                  class: linkStyle,
                  href: `/hashtag/${hashtag}`,
                  onclick: Router.onClick,
                },
                word
              )
            );

            string = "";

            if (k < words.length - 1) {
              string += " ";
            }
          } else if (word.match(new RegExp(Helper.URL_PATTERN))) {
            if (string.length > 0) {
              textSlots.push(string);
            }

            textSlots.push(
              $("a", { class: linkStyle, href: word, target: "_blank" }, word)
            );

            string = "";

            if (k < words.length - 1) {
              string += " ";
            }
          } else {
            string += word;

            if (k < words.length - 1) {
              string += " ";
            }
          }

          k++;
        }

        if (string.length > 0) {
          textSlots.push(string);
        }

        lines.push($(null, textSlots));

        if (j < tmpLines.length - 1) {
          lines.push($("br"));
        }

        j++;
      }

      return $(
        "p",
        { class: i !== paragraphs.length - 1 ? "mb-15" : null },
        lines
      );
    })
  );
}

export default Wrapper.wrapContext(
  RichText,
  DocumentContext,
  ({ mentionIds }) => {
    const documents = DocumentContext.getDocuments();

    const mentions = mentionIds.map((mentionId) => documents.User[mentionId]);

    const mentionNames = mentions.map((mention) => mention.name);
    const mentionPermalinks = mentions.map((mention) => mention.permalink);

    return { mentionNames, mentionPermalinks };
  }
);
