import $ from "untrue";

function Button({ icon, onClick }) {
  const onHandleClick = (event) => {
    onClick();

    event.preventDefault();
    event.stopPropagation();
  };

  return $(
    "button",
    {
      class:
        "w-50 h-50 flex justify-center align-center font-size-16 color-white",
      onclick: onHandleClick,
    },
    $("i", { class: icon })
  );
}

export default Button;
