import $, { Wrapper } from "untrue";

import RequestContext from "../../../context/RequestContext";

import User from "./User";

function Content({ requestKey, userIds, hasNextPage, nextPageCursor }) {
  const onNext = () => {
    RequestContext.load(requestKey, {
      friends: { limit: 20, cursor: nextPageCursor },
    });
  };

  return $(
    "ul",
    userIds.map((userId) => $("li", $(User, { userId })))
  );
}

export default Wrapper.wrapContext(
  Content,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      nodes: userIds,
      hasNextPage,
      nextPageCursor,
    } = requests[requestKey].data.friends.data;

    return { userIds, hasNextPage, nextPageCursor };
  }
);
