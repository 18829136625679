import $, { Component, Wrapper } from "untrue";

import { Head } from "@untrue/web";

import { RequestWrapper } from "@superbia/untrue";

import RequestContext from "../../context/RequestContext";
import TranslationContext from "../../context/TranslationContext";

import Logo from "../../../images/logo.png";

import Form from "./Form";

import SignIn from "./SignIn";
import SignUp from "./SignUp";

import Error from "./Error";

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = { signIn: true, showError: false };

    this.on("update", this.handleUpdateError);
  }

  handleUpdateError = () => {
    const { error } = this.props;
    const { error: prevError } = this.prevProps;

    if (error !== prevError && error !== null) {
      this.updateState({ showError: true });
    }
  };

  onSwitch = () => {
    const { signIn } = this.state;

    this.onReset();

    this.updateState({ signIn: !signIn });
  };

  onReset = () => {
    this.updateState({ showError: false });
  };

  render() {
    const { requestKey, error } = this.props;

    const { signIn, showError } = this.state;

    return [
      $(
        Head,
        $(
          "title",
          `${TranslationContext.getData().title} | ${
            TranslationContext.getData().slogan
          }`
        )
      ),
      $("div", { class: "w-900 m-auto relative h-100% flex align-center" }, [
        $("div", { class: "w-400" }, [
          $("div", { class: "flex" }, [
            $("img", {
              src: Logo,
              class: "block w-240 pointer-events-none",
            }),
          ]),
          $(
            "p",
            { class: "ml-10 mt-15 font-size-24" },
            TranslationContext.getData().screens.auth.description
          ),
        ]),
        $("div", { class: "relative flex-1 ml-80 h-100%" }, [
          $(
            Form,
            {
              shown: signIn,
              switchText: TranslationContext.getData().buttons.signUp,
              onSwitch: this.onSwitch,
            },
            $(SignIn, {
              requestKey,
              shown: signIn,
              onReset: this.onReset,
            })
          ),
          $(
            Form,
            {
              shown: !signIn,
              switchText: TranslationContext.getData().buttons.signIn,
              onSwitch: this.onSwitch,
            },
            $(SignUp, {
              requestKey,
              shown: !signIn,
              onReset: this.onReset,
            })
          ),
        ]),
        $(Error, { error, shown: showError, onReset: this.onReset }),
      ]),
    ];
  }
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(Auth, RequestContext, ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const { error = null } = requests?.[requestKey] ?? {};

    return { error };
  }),
  "auth"
);
