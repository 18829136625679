"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Emitter = void 0;
const everemitter_1 = require("everemitter");
class Emitter extends everemitter_1.EverEmitter {
    constructor() {
        super({
            onError: (error, name, ...args) => {
                Emitter.onError(error, name, ...args);
            },
        });
    }
}
exports.Emitter = Emitter;
Emitter.onError = (error) => {
    queueMicrotask(() => {
        throw error;
    });
};
