"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimationFrame = void 0;
class AnimationFrame {
    static request(callback) {
        if (typeof requestAnimationFrame !== "undefined") {
            return requestAnimationFrame(callback);
        }
        return setTimeout(() => {
            const now = performance.now();
            callback(now);
        }, 1000 / 60);
    }
    static cancel(frame) {
        if (frame === undefined) {
            return;
        }
        if (typeof cancelAnimationFrame !== "undefined") {
            cancelAnimationFrame(frame);
            return;
        }
        clearTimeout(frame);
    }
}
exports.AnimationFrame = AnimationFrame;
