import { Component } from "untrue";

import ChatTypingContext from "../../../context/ChatTypingContext";

import { client } from "../../../client";

class ChatTypingSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMount);
    this.on("unmount", this.handleUnmount);
  }

  handleMount = () => {
    this.subscription = client.subscribe({ chatTyping: null });

    this.subscription.on("data", (data) => {
      const { chatTyping } = data;

      ChatTypingContext.insert(chatTyping);
    });
  };

  handleUnmount = () => {
    this.subscription.unsubscribe();
  };
}

export default ChatTypingSubscriber;
