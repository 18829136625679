import $, { Component, Ref } from "untrue";

import TranslationContext from "../../../../../context/TranslationContext";
import SendMessageContext from "../../../../../context/SendMessageContext";

import Parent from "./Parent";

import { client } from "../../../../../client";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = { empty: true };

    this.textareaRef = new Ref();

    this.typingTime = null;

    this.on("mount", this.handleMountFocus);
    this.on("update", this.handleUpdateFocus);
  }

  handleMountFocus = () => {
    this.onApplyFocus();
  };

  handleUpdateFocus = () => {
    const { focusId } = this.props;
    const { focusId: prevFocusId } = this.prevProps;

    if (focusId !== prevFocusId) {
      this.onApplyFocus();
    }
  };

  onApplyFocus = () => {
    const { focusId } = this.props;

    if (focusId === null) {
      return;
    }

    const textarea = this.textareaRef.current;

    textarea.focus();
  };

  onSend = () => {
    const { chatId, parentId, onDismissReply } = this.props;

    const textarea = this.textareaRef.current;

    const { value: text } = textarea;

    if (chatId === null) {
      return;
    }

    if (text.trim().length === 0) {
      return;
    }

    this.updateState({ empty: true });

    textarea.value = "";

    this.onResize();

    SendMessageContext.insert(chatId, text, null, false, parentId);

    onDismissReply();
  };

  onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      this.onSend();

      return false;
    }
  };

  onInput = () => {
    const textarea = this.textareaRef.current;

    const { value: text } = textarea;

    const empty = text.trim().length === 0;

    this.updateState({ empty });

    this.onResize();

    if (this.typingTime === null || this.typingTime < Date.now() - 1000) {
      this.onEmitTyping();
    }
  };

  onResize = () => {
    const textarea = this.textareaRef.current;

    textarea.style.height = 0;

    textarea.style.height = `${Math.min(textarea.scrollHeight, 130)}px`;
  };

  onEmitTyping = () => {
    const { chatId } = this.props;

    if (chatId === null) {
      return;
    }

    const subscription = client.subscribe({ emitChatTyping: { chatId } });

    subscription.unsubscribe();

    this.typingTime = Date.now();
  };

  render() {
    const { parentId, onDismissReply } = this.props;

    const { empty } = this.state;

    return $("footer", [
      parentId !== null ? $(Parent, { parentId, onDismissReply }) : null,
      $("div", { class: "flex" }, [
        $(
          "div",
          { class: "min-h-50 flex-1 flex align-center" },
          $("textarea", {
            ref: this.textareaRef,
            class: "w-100% px-15 py-5 resize-none scrollable",
            placeholder: TranslationContext.getData().placeholders.message,
            rows: 1,
            oninput: this.onInput,
            onkeydown: this.onKeyDown,
          })
        ),
        $("div", { class: "mr-5 flex gap-5" }, [
          $(
            "div",
            { class: "h-50 w-40 flex align-center" },
            $(
              "button",
              {
                class:
                  "w-40 h-40 rounded-100% color-secondary-60 font-size-18 hover:bg-color-secondary-20 transition-all-300ms",
              },
              $("i", { class: "fas fa-image" })
            )
          ),
          empty
            ? $(
                "div",
                { class: "h-50 w-40 flex align-center" },
                $(
                  "button",
                  {
                    class:
                      "w-40 h-40 rounded-100% color-secondary-60 font-size-18 hover:bg-color-secondary-20 transition-all-300ms",
                  },
                  $("i", { class: "fas fa-microphone" })
                )
              )
            : null,
        ]),
      ]),
    ]);
  }
}

export default Content;
