import $, { Wrapper } from "untrue";

import RequestContext from "../../../../../context/RequestContext";
import TranslationContext from "../../../../../context/TranslationContext";

import User from "./User";

function Content({ userIds, onHide }) {
  if (userIds.length === 0) {
    return $(
      "div",
      {
        class:
          "flex-1 flex justify-center text-center color-secondary-60 py-20",
      },
      TranslationContext.getData().template.auth.header.search.noResults
    );
  }

  return $(
    "ul",
    { class: "flex-1 py-10" },
    userIds.map((userId) => $("li", $(User, { userId, onHide })))
  );
}

export default Wrapper.wrapContext(
  Content,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const { nodes: userIds } = requests[requestKey].data.searchUsers.data;

    return { userIds };
  }
);
