import $ from "untrue";

function Option({ icon, text, accent = false, onClick }) {
  return $(
    "button",
    {
      class: `flex-1 flex justify-center align-center color-secondary-60 p-10 transition-all-300ms hover:bg-color-secondary-15 ${
        accent ? "color-primary-100 font-bold" : ""
      }`,
      onclick: onClick,
    },
    [
      $(
        "span",
        { class: "mr-10 font-size-18" },
        $("i", { class: `fas ${icon}` })
      ),
      text,
    ]
  );
}

export default Option;
