import $, { Animation, Component, Ref, Wrapper } from "untrue";

import AuthContext from "../../context/AuthContext";
import CreatePostContext from "../../context/CreatePostContext";
import CreatePostModalContext from "../../context/CreatePostModalContext";
import TranslationContext from "../../context/TranslationContext";

import UserPictureBorder from "../../components/UserPictureBorder";
import UserName from "../../components/UserName";

import Figure from "./Figure/Figure";
import Option from "./Option";

class CreatePostModal extends Component {
  constructor(props) {
    super(props);

    const { text } = props;

    this.state = { text };

    this.overlayRef = new Ref();
    this.modalRef = new Ref();

    this.textareaRef = new Ref();
    this.inputRef = new Ref();

    this.showAnimation = new Animation(0);

    this.showAnimation.bind(this, this.handleShowAnimation);

    this.on("mount", this.handleMountShowAnimation);

    this.on("mount", this.handleMountWindow);
    this.on("unmount", this.handleUnmountWindow);

    this.on("mount", this.handleMountKeyUp);
    this.on("unmount", this.handleUnmountKeyUp);

    this.on("mount", this.handleMountAutoFocus);

    this.on("mount", this.handleMountTextarea);

    this.on("update", this.handleUpdateTextarea);
  }

  handleMountWindow = () => {
    document.body.style.overflow = "hidden";
  };

  handleUnmountWindow = () => {
    document.body.removeAttribute("style");
  };

  handleMountKeyUp = () => {
    window.addEventListener("keyup", this.keyUpListener);
  };

  handleUnmountKeyUp = () => {
    window.removeEventListener("keyup", this.keyUpListener);
  };

  keyUpListener = (event) => {
    if (event.key === "Escape") {
      this.onHide();
    }
  };

  handleMountAutoFocus = () => {
    const { autoFocus } = this.props;

    const textarea = this.textareaRef.current;

    if (autoFocus) {
      textarea.focus();
    }

    CreatePostModalContext.autoFocus(false);
  };

  handleMountShowAnimation = () => {
    this.showAnimation.animate(1, 150);
  };

  handleShowAnimation = () => {
    const overlay = this.overlayRef.current;
    const modal = this.modalRef.current;

    if (overlay === null) {
      return;
    }

    if (modal === null) {
      return;
    }

    overlay.style.opacity = this.showAnimation.interpolate(
      [0, 1],
      ["0%", "100%"]
    );

    modal.style.transform = `translateY(${this.showAnimation.interpolate(
      [0, 1],
      ["50%", "0%"]
    )})`;

    modal.style.opacity = this.showAnimation.interpolate(
      [0, 1],
      ["0%", "100%"]
    );
  };

  handleMountTextarea = () => {
    this.onInput();
  };

  handleUpdateTextarea = () => {
    const { figures } = this.props;
    const { figures: prevFigures } = this.prevProps;

    if (figures.length !== prevFigures.length) {
      this.onInput();
    }
  };

  onHide = (reset = false) => {
    const { text } = this.state;

    const transition = this.showAnimation.animate(0, 250);

    transition.on("end", () => {
      CreatePostModalContext.toggleShown();

      if (reset) {
        CreatePostModalContext.changeText("");

        CreatePostModalContext.deleteFigures();
      } else {
        CreatePostModalContext.changeText(text);
      }
    });
  };

  onInput = () => {
    const textarea = this.textareaRef.current;

    const { value } = textarea;

    this.updateState({ text: value });

    textarea.style.height = 0;

    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  onMedia = () => {
    const input = this.inputRef.current;

    input.click();
  };

  onFile = () => {
    const input = this.inputRef.current;

    const file = input.files[0];

    if (file === undefined) {
      return;
    }

    CreatePostModalContext.addFigure(file);

    input.value = "";
  };

  onShare = () => {
    const { figures } = this.props;

    const { text } = this.state;

    CreatePostContext.insert(text, figures);

    this.onHide(true);
  };

  render() {
    const { loggedId, figures } = this.props;

    const { text } = this.state;

    let placeholder = null;

    if (figures.length === 0) {
      placeholder = TranslationContext.getData().placeholders.postText;
    } else {
      const allPhotos = figures.every(
        (figure) => figure.media.type === "image"
      );

      const allVideos = figures.every(
        (figure) => figure.media.type === "video"
      );

      if (allPhotos) {
        placeholder =
          figures.length === 1
            ? TranslationContext.getData().placeholders.postPhotoText
            : TranslationContext.getData().placeholders.postPhotosText;
      }

      if (allVideos) {
        placeholder =
          figures.length === 1
            ? TranslationContext.getData().placeholders.postVideoText
            : TranslationContext.getData().placeholders.postVideosText;
      }

      if (!allPhotos && !allVideos) {
        placeholder = TranslationContext.getData().placeholders.postText;
      }
    }

    return $(
      "div",
      { class: "fixed z-20 inset-0 flex justify-center align-center" },
      [
        $("div", {
          ref: this.overlayRef,
          class: "absolute inset-0 bg-color-blank-0-50% backdrop-blur-15",
        }),
        $(
          "div",
          {
            ref: this.modalRef,
            class:
              "relative w-650 flex flex-column rounded-12 box-shadow-0-0-6 box-shadow-color-blank-0",
          },
          [
            $(
              "div",
              {
                class:
                  "relative bg-color-secondary-15 rounded-t-12 flex justify-center py-10 color-white font-size-18 font-bold",
              },
              [
                TranslationContext.getData().modals.createPostModal.title,
                $(
                  "div",
                  {
                    class: "absolute top-0 bottom-0 right-0 flex align-center",
                  },
                  $(
                    "button",
                    {
                      class:
                        "absolute w-30 h-30 rounded-100% right-10 bg-color-secondary-20 color-secondary-60 font-size-18 flex justify-center align-center transition-all-300ms hover:bg-color-secondary-25",
                      onclick: () => this.onHide(),
                    },
                    $("i", { class: "fas fa-xmark" })
                  )
                ),
              ]
            ),
            $(
              "div",
              {
                class: "bg-color-secondary-10 rounded-b-12",
              },
              [
                $(
                  "div",
                  {
                    class:
                      "max-h-70vh border-b-1 border-solid border-color-secondary-25 overflow-y-auto scrollable",
                  },
                  [
                    $("div", { class: "flex align-center p-20 pb-0" }, [
                      $(UserPictureBorder, {
                        userId: loggedId,
                        size: "w-40",
                      }),
                      $(
                        "div",
                        { class: "ml-15" },
                        $(UserName, { userId: loggedId })
                      ),
                    ]),
                    $(
                      "textarea",
                      {
                        ref: this.textareaRef,
                        class: `w-100% p-20 ${
                          figures.length === 0 ? "font-size-20" : "font-size-16"
                        } resize-none`,
                        placeholder,
                        rows: 1,
                        oninput: this.onInput,
                      },
                      text
                    ),
                    $(
                      "div",
                      { class: "flex flex-column" },
                      figures.map((figure, i) =>
                        $(
                          "div",
                          { key: figure, class: "my-1" },
                          $(Figure, { figure, index: i })
                        )
                      )
                    ),
                  ]
                ),
                $(
                  "div",
                  {
                    class:
                      "flex border-b-1 border-solid border-color-secondary-25",
                  },
                  [
                    $(Option, {
                      text: TranslationContext.getData().modals.createPostModal
                        .buttons.media,
                      icon: "fa-image",
                      onClick: this.onMedia,
                    }),
                    $(Option, {
                      text: TranslationContext.getData().modals.createPostModal
                        .buttons.album,
                      icon: "fa-images",
                      onClick: this.onAlbum,
                    }),
                    $(Option, {
                      text: TranslationContext.getData().modals.createPostModal
                        .buttons.location,
                      icon: "fa-map-marker-alt",
                      onClick: this.onLocation,
                    }),
                  ]
                ),
                $("input", {
                  ref: this.inputRef,
                  type: "file",
                  class: "hidden",
                  oninput: this.onFile,
                }),
                $(
                  "div",
                  { class: "p-10" },
                  $(
                    "button",
                    {
                      class: "button",
                      disabled:
                        text.trim().length === 0 && figures.length === 0
                          ? ""
                          : null,
                      onclick: this.onShare,
                    },
                    TranslationContext.getData().buttons.create
                  )
                ),
              ]
            ),
          ]
        ),
      ]
    );
  }
}

function CreatePostModalContainer({ shown, ...props }) {
  if (!shown) {
    return null;
  }

  return $(CreatePostModal, props);
}

export default Wrapper.wrapContext(
  CreatePostModalContainer,
  [AuthContext, CreatePostModalContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  () => {
    const { shown, autoFocus, text, figures } =
      CreatePostModalContext.getState();

    return { shown, autoFocus, text, figures };
  }
);
