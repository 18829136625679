import $ from "untrue";

function Loading() {
  return $(
    "div",
    {
      class:
        "p-300 flex align-center justify-center font-size-40 color-secondary-50",
    },
    $("i", { class: "fas fa-circle-notch fa-spin" })
  );
}

export default Loading;
