import $ from "untrue";

function Form({ shown, switchText, onSwitch, children }) {
  return $(
    "div",
    {
      class: `absolute inset-0 flex flex-column justify-center ${
        shown ? "opacity-100%" : "opacity-0% pointer-events-none"
      } transition-all-300ms`,
    },
    $("div", { class: "relative" }, [
      children,
      $(
        "div",
        { class: "absolute right-0 left-0 bottom-[-65] px-20" },
        $(
          "button",
          {
            class:
              "w-100% color-secondary-60 rounded-12 h-45 px-15 flex justify-center align-center transition-all-300ms hover:bg-color-secondary-15",
            onclick: onSwitch,
          },
          switchText
        )
      ),
    ])
  );
}

export default Form;
