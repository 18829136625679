import { Component } from "untrue";

import AudioHelper from "../../../helpers/AudioHelper";

import AuthContext from "../../../context/AuthContext";
import ChatWindowContext from "../../../context/ChatWindowContext";
import RequestContext from "../../../context/RequestContext";

import { client } from "../../../client";

class NewMessageSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMountSubscribe);
    this.on("unmount", this.handleUnmountSubscribe);
  }

  handleMountSubscribe = () => {
    this.subscription = client.subscribe({ newMessage: null });

    this.subscription.on("data", (data) => {
      const { newMessage } = data;

      const {
        chat: { id: chatId, user },
        message: { sender },
      } = newMessage;

      const { loggedId } = AuthContext.getState();

      let userId = null;

      if (user !== null) {
        userId = user.id;
      }

      let senderId = null;

      if (sender !== null) {
        senderId = sender.id;
      }

      if (senderId !== loggedId) {
        const id =
          userId !== null
            ? ChatWindowContext.getId({ userId })
            : ChatWindowContext.getId({ chatId });

        const window = ChatWindowContext.get(id);

        if (window !== null) {
          if (window.focused) {
            AudioHelper.playMessageCurrentWindow();
          } else {
            AudioHelper.playMessageNewWindow();
          }
        } else {
          AudioHelper.playMessageNewWindow();
        }

        const focus = false;

        if (userId !== null) {
          ChatWindowContext.add({ userId, focus });
        } else {
          ChatWindowContext.add({ chatId, focus });
        }
      }

      RequestContext.onNewMessage(newMessage);

      const subscription = client.subscribe({ markChatAsReceived: { chatId } });

      subscription.unsubscribe();
    });
  };

  handleUnmountSubscribe = () => {
    this.subscription.unsubscribe();
  };
}

export default NewMessageSubscriber;
