import $, { Wrapper } from "untrue";

import DocumentContext from "../context/DocumentContext";

import Helper from "../helpers/Helper";

function ReelThumbnail({ poster, viewsCount, showViews = true }) {
  return $("div", { class: "aspect-9/13 relative" }, [
    $("img", { class: "w-100% h-100% object-fit-cover", src: poster }),
    showViews
      ? $(
          "div",
          {
            class:
              "absolute bottom-0 right-0 left-0 bg-gradient-linear bg-gradient-from-color-transparent bg-gradient-to-color-blank-0-50% p-5 pt-50 flex align-center font-size-12",
          },
          [
            $("i", { class: "fas fa-play" }),
            $("span", { class: "ml-5" }, Helper.formatCount(viewsCount)),
          ]
        )
      : null,
  ]);
}

export default Wrapper.wrapContext(
  ReelThumbnail,
  DocumentContext,
  ({ reelId }) => {
    const documents = DocumentContext.getDocuments();

    const { media: mediaId, viewsCount } = documents.Reel[reelId];

    const { poster: posterId } = documents.Media[mediaId];

    const { small: smallId } = documents.Media[posterId];

    const { source: poster } = documents.MediaSize[smallId];

    return { poster, viewsCount };
  }
);
