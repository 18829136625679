import $, { Animation, Component, Ref, Wrapper } from "untrue";

import emojiRegex from "emoji-regex";

import DocumentContext from "../../../../../../context/DocumentContext";

import RichText from "../../../../../../components/RichText";

const regex = emojiRegex();

const hearts = ["❤", "🧡", "💛", "💚", "💙", "💜", "🤎", "🖤", "🤍"];

class Heart extends Component {
  constructor(props) {
    super(props);

    this.paragraphRef = new Ref();

    this.animation = new Animation(0);

    this.transition = null;

    this.on("mount", this.handleMountAnimation);
    this.on("unmount", this.handleUnmountTransition);

    this.animation.bind(this, this.handleAnimation);
  }

  handleMountAnimation = () => {
    this.onAnimate();
  };

  handleUnmountTransition = () => {
    this.transition.cancel();
  };

  onAnimate = () => {
    const value = this.animation.getValue() === 0 ? 1 : 0;

    this.transition = this.animation.animate(value, 1500);

    this.transition.on("end", this.onAnimate);
  };

  handleAnimation = () => {
    const paragraph = this.paragraphRef.current;

    if (paragraph === null) {
      return;
    }

    paragraph.style.transform = `scale(${this.animation.interpolate(
      [0, 0.25, 0.5, 1],
      ["0.9", "1.1", "1.1", "0.9"]
    )})`;
  };

  render() {
    const { text } = this.props;

    return $("p", { ref: this.paragraphRef, class: "font-size-70" }, text);
  }
}

/*

to fix here:

just sent messages don't show the right mention names

*/

function MessageText({
  text,
  mentionIds,
  sentByLogged,
  DropdownNode,
  ParentNode,
}) {
  const matches = [...text.matchAll(regex)];

  let codePoints = 0;

  for (const match of matches) {
    const emoji = match[0];

    codePoints += emoji.length;
  }

  const isHeart = hearts.includes(text);

  const one = matches.length === 1 && text.length === codePoints;
  const two = matches.length === 2 && text.length === codePoints;
  const three = matches.length === 3 && text.length === codePoints;

  let emojiClass = null;

  if (one) {
    emojiClass = "font-size-28";
  } else if (two) {
    emojiClass = "font-size-24";
  } else if (three) {
    emojiClass = "font-size-20";
  }

  return $(
    "div",
    {
      class: `message relative max-w-70% ${
        sentByLogged ? "bg-color-primary-90" : "bg-color-secondary-20"
      } rounded-10`,
    },
    [
      ParentNode,
      $(
        "div",
        { class: "px-10 py-5" },
        isHeart
          ? $(Heart, { text })
          : emojiClass !== null
          ? $("p", { class: emojiClass }, text)
          : $(RichText, { linkStyle: "font-bold", text, mentionIds })
      ),
      DropdownNode,
    ]
  );
}

export default Wrapper.wrapContext(
  MessageText,
  DocumentContext,
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    const { text, mentions: mentionIds } = documents.Message[messageId];

    return { text, mentionIds };
  }
);
