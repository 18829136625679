import $, { Wrapper } from "untrue";

import DocumentContext from "../../../../context/DocumentContext";

function Image({ index, figuresCount, source, onNext, onPrev }) {
  return $("div", { class: "relative" }, [
    $("img", { class: "block w-100% pointer-events-none", src: source }),
    $(
      "div",
      { class: "absolute inset-0 flex justify-between align-center p-15" },
      [
        $(
          "div",
          {},
          index > 0
            ? $(
                "button",
                {
                  class: "color-white font-size-32 opacity-50%",
                  onclick: onPrev,
                },
                $("i", { class: "fas fa-chevron-circle-left" })
              )
            : null
        ),
        $(
          "div",
          {},
          index < figuresCount - 1
            ? $(
                "button",
                {
                  class: "color-white font-size-32 opacity-50%",
                  onclick: onNext,
                },
                $("i", { class: "fas fa-chevron-circle-right" })
              )
            : null
        ),
      ]
    ),
  ]);
}

export default Wrapper.wrapContext(Image, DocumentContext, ({ figureId }) => {
  const documents = DocumentContext.getDocuments();

  const { media: mediaId, tags: tagIds } = documents.PostFigure[figureId];

  const tagsCount = tagIds.length;

  const { source } = documents.Media[mediaId];

  return { source, tagIds, tagsCount };
});
