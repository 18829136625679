import $ from "untrue";

function Button({ icon, onClick }) {
  return $(
    "button",
    {
      class:
        "w-50 h-50 flex justify-center align-center rounded-100% font-size-22 color-white hover:bg-color-blank-100-15% transition-250ms",
      onclick: onClick,
    },
    $("i", { class: `fas ${icon}` })
  );
}

export default Button;
