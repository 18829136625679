import $, { Component, Ref, Wrapper } from "untrue";

import AuthContext from "../../context/AuthContext";
import DocumentContext from "../../context/DocumentContext";
import CreatePostModalContext from "../../context/CreatePostModalContext";
import TranslationContext from "../../context/TranslationContext";

import UserPicture from "../../components/UserPicture";

class CreatePost extends Component {
  constructor(props) {
    super(props);

    this.inputRef = new Ref();
  }

  onText = () => {
    CreatePostModalContext.toggleShown();

    CreatePostModalContext.autoFocus(true);
  };

  onMedia = () => {
    const input = this.inputRef.current;

    input.click();
  };

  onFile = () => {
    const input = this.inputRef.current;

    const file = input.files[0];

    CreatePostModalContext.addFigure(file);

    CreatePostModalContext.toggleShown();

    input.value = "";
  };

  render() {
    const { loggedId, text } = this.props;

    const tmpText = text.trim();

    return $(
      "div",
      { class: "bg-color-secondary-15 flex p-15 pr-5 rounded-12" },
      [
        $(UserPicture, { userId: loggedId, size: "w-45" }),
        $(
          "button",
          {
            class: `white-space-nowrap overflow-hidden text-overflow-ellipsis block ml-10 mr-5 flex-1 flex align-center px-20 rounded-32 text-left bg-color-secondary-20 ${
              tmpText.length === 0 ? "color-secondary-60" : ""
            } cursor-pointer transition-all-300ms hover:bg-color-secondary-25`,
            onclick: this.onText,
          },
          tmpText.length !== 0
            ? tmpText
            : TranslationContext.getData().placeholders.postText
        ),
        $(
          "button",
          {
            class:
              "w-45 h-45 rounded-100% font-size-22 color-secondary-60 transition-all-300ms hover:color-white hover:bg-color-secondary-25",
            onclick: this.onMedia,
          },
          $("i", { class: "fas fa-image" })
        ),
        $("input", {
          ref: this.inputRef,
          class: "hidden",
          type: "file",
          oninput: this.onFile,
        }),
      ]
    );
  }
}

export default Wrapper.wrapContext(
  CreatePost,
  [AuthContext, DocumentContext, CreatePostModalContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  () => {
    const { text } = CreatePostModalContext.getState();

    return { text };
  }
);
