import $, { Wrapper } from "untrue";

import AuthContext from "../../context/AuthContext";

import CreatePostModal from "../../modals/CreatePostModal/CreatePostModal";
import DropdownOverlay from "../../modals/DropdownOverlay/DropdownOverlay";
import ViewMediaModal from "../../modals/ViewMediaModal/ViewMediaModal";

import Subscriber from "./Subscriber/Subscriber";

import Friends from "./Friends/Friends";

import Chats from "./Chats/Chats";

function AppOverlay({ isAuth }) {
  if (!isAuth) {
    return null;
  }

  return [
    $(DropdownOverlay),
    $(Friends),
    $(Chats),
    $(CreatePostModal),
    $(ViewMediaModal),
    $(Subscriber),
  ];
}

export default Wrapper.wrapContext(AppOverlay, AuthContext, () => {
  const { isAuth } = AuthContext.getState();

  return { isAuth };
});
