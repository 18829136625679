import $ from "untrue";

import Helper from "../../helpers/Helper";

function FooterButton({ icon, count = null, iconColor = "", onClick }) {
  return $(
    "button",
    {
      class:
        "footer-button flex align-center color-secondary-60 cursor-pointer",
      onclick: onClick,
    },
    [
      $("div", { class: "relative" }, [
        $("div", {
          class: `absolute inset-0 bg-color-secondary-20 rounded-100% scale-0 [hover:footer-button]:scale-1 transition-all-300ms`,
        }),
        $(
          "span",
          {
            class: `relative w-40 h-40 flex justify-center align-center font-size-20 ${iconColor}`,
          },
          $("i", { class: `fas ${icon}` })
        ),
      ]),
      count !== null
        ? $(
            "span",
            { class: "ml-5 w-55 flex justify-start" },
            Helper.formatCount(count)
          )
        : null,
    ]
  );
}

export default FooterButton;
