import { Persistor } from "untrue";

import { Storage } from "@untrue/web";

import AuthContext from "./AuthContext";
import DocumentContext from "./DocumentContext";
import TranslationContext from "./TranslationContext";

import { initClient } from "../client";

const persistor = new Persistor(
  {
    auth: AuthContext,
    documents: DocumentContext,
    translation: TranslationContext,
  },
  Storage,
  {
    version: 1,
    migrations: {
      1: (value) => {
        const { translation, ...other } = value;

        return other;
      },
    },
  }
);

persistor.on("init", initClient);

export default persistor;
