import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import AuthContext from "../../../../../../context/AuthContext";
import DocumentContext from "../../../../../../context/DocumentContext";
import TranslationContext from "../../../../../../context/TranslationContext";

function MessageInfo({
  loggedId,
  action,
  participantIds,
  participantNames,
  participantPermalinks,
}) {
  let text = null;

  switch (participantIds.length) {
    case 0: {
      text = TranslationContext.getData().chats.messages[action];

      break;
    }

    case 1: {
      const firstId = participantIds[0];

      text =
        firstId === loggedId
          ? TranslationContext.getData().chats.messages[action].first
          : TranslationContext.getData().chats.messages[action].none;

      break;
    }

    case 2: {
      const firstId = participantIds[0];
      const secondId = participantIds[1];

      if (firstId === loggedId) {
        text = TranslationContext.getData().chats.messages[action].first;
      } else if (secondId === loggedId) {
        text = TranslationContext.getData().chats.messages[action].second;
      } else {
        text = TranslationContext.getData().chats.messages[action].none;
      }

      break;
    }
  }

  const textNodes = [];

  let string = "";

  for (const letter of text) {
    string += letter;

    if (string.endsWith("%username_0%")) {
      textNodes.push(
        string.substring(0, string.length - "%username_0%".length)
      );

      textNodes.push(
        $(
          "a",
          {
            class: "font-bold hover:color-primary-100",
            href: participantPermalinks[0],
            onclick: Router.onClick,
          },
          participantNames[0]
        )
      );

      string = "";
    } else if (string.endsWith("%username_1%")) {
      textNodes.push(
        string.substring(0, string.length - "%username_1%".length)
      );

      textNodes.push(
        $(
          "a",
          {
            class: "font-bold hover:color-primary-100",
            href: participantPermalinks[1],
            onclick: Router.onClick,
          },
          participantNames[1]
        )
      );

      string = "";
    }
  }

  if (string.length > 0) {
    textNodes.push(string);
  }

  return $(
    "p",
    {
      class:
        "bg-color-secondary-20 py-2 px-10 rounded-32 color-secondary-60 font-size-14 text-center",
    },
    textNodes
  );
}

export default Wrapper.wrapContext(
  MessageInfo,
  [AuthContext, DocumentContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    const { action, participants: participantIds } =
      documents.Message[messageId];

    const participantNames = [];
    const participantPermalinks = [];

    for (const participantId of participantIds) {
      const { name, permalink } = documents.User[participantId];

      participantNames.push(name);
      participantPermalinks.push(permalink);
    }

    return { action, participantIds, participantNames, participantPermalinks };
  }
);
