import $, { Component, Ref, Wrapper } from "untrue";

import { RequestWrapper } from "@superbia/untrue";

import RequestHelper from "../../../../helpers/RequestHelper";

import RequestContext from "../../../../context/RequestContext";
import ChatWindowContext from "../../../../context/ChatWindowContext";

import Header from "./Header";
import Content from "./Content";

import Footer from "./Footer/Footer";

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = { parentId: null };

    this.textareaRef = new Ref();

    this.hover = false;

    this.on("mount", this.handleRequestMount);

    this.on("mount", this.handleMountMouseUp);
    this.on("unmount", this.handleUnmountMouseUp);
  }

  handleRequestMount = () => {
    const { requestKey, chatId, userId, started } = this.props;

    if (started) {
      return;
    }

    RequestContext.request(requestKey, {
      chat: { chatId, userId },
      messages: { chatId, userId, limit: 20 },
    });
  };

  handleMountMouseUp = () => {
    window.addEventListener("mouseup", this.mouseUpListener);
  };

  handleUnmountMouseUp = () => {
    window.removeEventListener("mouseup", this.mouseUpListener);
  };

  mouseUpListener = (event) => {
    const { windowId } = this.props;

    if (event.target === document.documentElement) {
      return;
    }

    if (this.hover) {
      ChatWindowContext.focus(windowId);
    } else {
      ChatWindowContext.blur(windowId);
    }
  };

  onMouseEnter = () => {
    this.hover = true;
  };

  onMouseLeave = () => {
    this.hover = false;
  };

  onReply = (messageId) => {
    this.updateState({ parentId: messageId });
  };

  onDismissReply = () => {
    this.updateState({ parentId: null });
  };

  render() {
    const { requestKey, windowId, chatId, userId, focusId, focused } =
      this.props;

    const { parentId } = this.state;

    return $(
      "div",
      {
        class: "relative mx-5",
        onmouseenter: this.onMouseEnter,
        onmouseleave: this.onMouseLeave,
      },
      [
        $("div", {
          class:
            "absolute inset-0 rounded-t-12 bg-color-blank-0-75% backdrop-blur-15",
        }),
        $(
          "div",
          {
            class: `relative w-350 h-500 flex flex-column box-shadow-0-0-8 box-shadow-color-blank-0-25% pointer-events-auto rounded-t-12 ${
              !focused ? "opacity-65%" : ""
            } transition-300ms`,
          },
          [
            $(Header, { requestKey, windowId, chatId, userId }),
            $(
              "div",
              { class: "flex-1 flex flex-column bg-color-secondary-15" },
              [
                $(Content, {
                  requestKey,
                  focused,
                  windowId,
                  onReply: this.onReply,
                }),
                $(Footer, {
                  requestKey,
                  windowId,
                  focusId,
                  parentId,
                  onDismissReply: this.onDismissReply,
                }),
              ]
            ),
          ]
        ),
      ]
    );
  }
}

export default Wrapper.wrapContext(
  RequestWrapper.wrapRequester(
    Wrapper.wrapContext(Chat, RequestContext, ({ requestKey }) => {
      const requests = RequestContext.getRequests();

      const started = requestKey in requests;

      return { started };
    }),
    ({ chatId, userId }) => RequestHelper.getChatRequestKey({ chatId, userId })
  ),
  ChatWindowContext,
  ({ windowId }) => ChatWindowContext.get(windowId)
);
