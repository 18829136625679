import $, { Wrapper } from "untrue";

import RequestContext from "../../../context/RequestContext";

import Loading from "./Loading";
import Content from "./Content";

function Posts({ requestKey, loading, done }) {
  if (loading) {
    return $(Loading);
  }

  if (done) {
    return $(Content, { requestKey });
  }

  return null;
}

export default Wrapper.wrapContext(Posts, RequestContext, ({ requestKey }) => {
  const requests = RequestContext.getRequests();

  const {
    loading = false,
    done = false,
    error = null,
  } = requests?.[requestKey] ?? {};

  return { loading, done, error };
});
