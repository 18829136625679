import $, { Wrapper } from "untrue";

import DocumentContext from "../context/DocumentContext";

import RichText from "./RichText";

function StoryThumbnail({
  type,
  text,
  source,
  backgroundColor,
  mentionIds,
  fontSize,
}) {
  return $("div", { class: "aspect-9/14" }, [
    type === "text"
      ? $(
          "div",
          {
            class: `w-100% h-100% rounded-8 p-10 flex justify-center align-center text-center text-ellipsis overflow-hidden pointer-events-none ${fontSize}`,
            style: `background-color: ${backgroundColor}`,
          },
          $(RichText, { text, mentionIds })
        )
      : $("img", {
          class: "w-100% h-100% rounded-8 object-fit-cover",
          src: source,
        }),
  ]);
}

export default Wrapper.wrapContext(
  StoryThumbnail,
  DocumentContext,
  ({ storyId }) => {
    const documents = DocumentContext.getDocuments();

    const {
      type,
      text,
      media: mediaId,
      backgroundColor,
      mentions: mentionIds,
    } = documents.Story[storyId];

    let source = null;

    if (mediaId !== null) {
      const media = documents.Media[mediaId];

      const { poster: posterId } = media;

      let poster = null;

      if (posterId !== null) {
        poster = documents.Media[posterId];
      }

      const { small: smallId } = poster !== null ? poster : media;

      ({ source } = documents.MediaSize[smallId]);
    }

    return { type, text, source, backgroundColor, mentionIds };
  }
);
