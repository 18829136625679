import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import AuthContext from "../context/AuthContext";

import AuthTemplate from "./Template/AuthTemplate/AuthTemplate";
import NotAuthTemplate from "./Template/NotAuthTemplate/NotAuthTemplate";

import Auth from "../screens/Auth/Auth";
import Home from "../screens/Home/Home";
import Help from "../screens/Help/Help";
import Support from "../screens/Support/Support";
import DataPolicy from "../screens/DataPolicy/DataPolicy";
import PrivacyPolicy from "../screens/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../screens/TermsOfService/TermsOfService";
import Profile from "../screens/Profile/Profile";
import NotFound from "../screens/NotFound/NotFound";

const WrappedAppRouter = Router.wrapRouter();

function AppRouter({ isAuth }) {
  return $(WrappedAppRouter, {
    Template: isAuth ? AuthTemplate : NotAuthTemplate,
    routes: [
      isAuth
        ? { path: "/", Screen: Home }
        : { path: "/", Template: null, Screen: Auth },
      { path: "/help", Screen: Help },
      { path: "/help/support", Screen: Support },
      { path: "/app/data-policy", Screen: DataPolicy },
      { path: "/app/privacy-policy", Screen: PrivacyPolicy },
      { path: "/app/terms-of-service", Screen: TermsOfService },
      { path: "/{username}", Screen: Profile },
      { path: null, Screen: NotFound },
    ],
  });
}

export default Wrapper.wrapContext(AppRouter, AuthContext, () => {
  const { isAuth } = AuthContext.getState();

  return { isAuth };
});
