import $ from "untrue";

import ChatWindowContext from "../../../context/ChatWindowContext";

import UserPicture from "../../../components/UserPicture";
import UserName from "../../../components/UserName";

function User({ userId }) {
  const onClick = () => {
    ChatWindowContext.add({ userId });
  };

  return $(
    "div",
    {
      class:
        "flex align-center px-15 py-10 cursor-pointer transition-all-300ms hover:bg-color-secondary-20",
      onclick: onClick,
    },
    [
      $(UserPicture, { userId, size: "w-40" }),
      $("div", { class: "ml-15" }, $(UserName, { userId })),
    ]
  );
}

export default User;
