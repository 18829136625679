import $, { Wrapper } from "untrue";

import AuthContext from "../../../../../context/AuthContext";
import RequestContext from "../../../../../context/RequestContext";
import DocumentContext from "../../../../../context/DocumentContext";

import UserPicture from "../../../../../components/UserPicture";
import UserName from "../../../../../components/UserName";

import Message from "./Message/Message";

function Block({
  loggedId,
  blockMessageIds,
  senderId,
  group,
  username,
  selectedId,
  playingId,
  ...props
}) {
  const showUserInfo = group && senderId !== null && senderId !== loggedId;

  const onProfile = () => {
    // onPush("Profile", { username });
  };

  return [
    showUserInfo
      ? $("div", { class: "mx-15 mb-5 flex align-center" }, [
          $(UserPicture, { userId: senderId, size: "w-30" }),
          $(
            "div",
            { class: "ml-10" },
            $(UserName, { class: "font-size-14", userId: senderId })
          ),
        ])
      : null,
    $(
      "div",
      { class: "flex flex-column" },
      blockMessageIds.map((messageId) =>
        $(Message, {
          key: messageId,
          messageId,
          selected: messageId === selectedId,
          playing: messageId === playingId,
          ...props,
        })
      )
    ),
  ];
}

export default Wrapper.wrapContext(
  Block,
  [AuthContext, RequestContext, DocumentContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  ({ requestKey, date, blockIndex }) => {
    const requests = RequestContext.getRequests();
    const documents = DocumentContext.getDocuments();

    const {
      data: { nodes: messageIds },
    } = requests[requestKey].data.messages;

    const dateMessageIds = messageIds.filter((messageId) => {
      const { createdAt } = documents.Message[messageId];

      const tmpDate = new Date(createdAt);

      const string = `${tmpDate.getFullYear()}-${tmpDate.getMonth()}-${tmpDate.getDate()}`;

      return string === date;
    });

    const reverseMessageIds = [...dateMessageIds].reverse();

    const blocks = [];

    let prevMessageId = null;

    let currentBlockIndex = -1;

    for (const messageId of reverseMessageIds) {
      const { sender: senderId } = documents.Message[messageId];

      if (senderId !== null) {
        if (prevMessageId !== null) {
          const { sender: prevSenderId } = documents.Message[prevMessageId];

          if (senderId !== prevSenderId) {
            currentBlockIndex++;
          }
        } else {
          currentBlockIndex++;
        }
      } else {
        currentBlockIndex++;
      }

      const blockMessageIds =
        currentBlockIndex <= blocks.length - 1 ? blocks[currentBlockIndex] : [];

      blocks[currentBlockIndex] = [...blockMessageIds, messageId];

      prevMessageId = messageId;
    }

    const blockMessageIds = blocks[blockIndex];

    const { sender: senderId } = documents.Message[blockMessageIds[0]];

    return { blockMessageIds, senderId };
  },
  ({ chatId, senderId }) => {
    const documents = DocumentContext.getDocuments();

    const { group } = documents.Chat[chatId];

    let username = null;

    if (senderId !== null) {
      ({ username } = documents.User[senderId]);
    }

    return { group, username };
  }
);
