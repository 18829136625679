import $, { Component } from "untrue";

import CounterContext from "../../../../../../context/CounterContext";

class IconButton extends Component {
  constructor(props) {
    super(props);

    const { count } = props;

    this.state = { shown: count !== null && count > 0 };

    this.on("mount", this.onUpdateCount);
    this.on("update", this.onUpdateCount);
  }

  onUpdateCount = () => {
    const { counterKey, count } = this.props;

    this.updateState({ shown: count !== null && count > 0 });

    if (count !== null) {
      CounterContext.define(counterKey, count);
    }
  };

  onClick = () => {
    const { shown, name, onTab } = this.props;

    onTab(!shown ? name : null);
  };

  render() {
    const { icon, count } = this.props;

    const { shown } = this.state;

    return $(
      "button",
      { class: "header-button", onclick: this.onClick },
      $(
        "div",
        { class: "relative w-100% h-100% flex justify-center align-center" },
        [
          $(
            "div",
            { class: "color-secondary-60 font-size-20" },
            $("i", { class: `fas ${icon}` })
          ),
          count !== null
            ? $(
                "div",
                {
                  class: `absolute top-5 left-35 px-5 rounded-32 h-20 min-w-20 flex justify-center align-center bg-color-red-100 ${
                    shown ? "opacity-100% scale-1" : "opacity-0% scale-0"
                  } transition-all-300ms`,
                },
                $(
                  "span",
                  { class: "block font-bold font-size-12" },
                  count > 999 ? "999+" : count
                )
              )
            : null,
        ]
      )
    );
  }
}

export default IconButton;
