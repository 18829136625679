"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Target = void 0;
class Target {
    constructor(node, index = 0) {
        this.node = node;
        this.index = index;
    }
    insert(child) {
        const currentChild = this.index < this.node.childNodes.length
            ? this.node.childNodes[this.index]
            : null;
        if (currentChild !== null) {
            if (child !== currentChild) {
                this.node.insertBefore(child, currentChild);
            }
        }
        else {
            this.node.appendChild(child);
        }
        this.index++;
    }
    remove(child) {
        this.node.removeChild(child);
    }
}
exports.Target = Target;
