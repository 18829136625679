import $, { Wrapper } from "untrue";

import { Head } from "@untrue/web";

import Helper from "../../helpers/Helper";

import DocumentContext from "../../context/DocumentContext";
import RequestContext from "../../context/RequestContext";
import TranslationContext from "../../context/TranslationContext";

import Post from "../../components/Post/Post";

import Header from "./Header";
import TranslationContext from "../../context/TranslationContext";

function Content({
  requestKey,
  userId,
  name,
  username,
  postIds,
  hasNextPage,
  nextPageCursor,
}) {
  const onNext = () => {
    RequestContext.load(requestKey, {
      userPosts: { username, limit: 20, cursor: nextPageCursor },
    });
  };

  return [
    $(
      Head,
      $(
        "title",
        Helper.getTitle(
          TranslationContext.getData()
            .titles.profile.replace("%name%", name)
            .replace("%username%", username)
        )
      )
    ),
    $(
      "div",
      { class: "container mx-auto flex justify-center" },
      $("div", { class: "my-100 w-600 flex flex-column" }, [
        $("div", { class: "mb-30" }, $(Header, { userId })),
        postIds.map((postId) =>
          $("div", { key: postId, class: "my-10" }, $(Post, { postId }))
        ),
        hasNextPage
          ? $(
              "div",
              { class: "flex-1 flex justify-center mt-30" },
              $(
                "button",
                {
                  class:
                    "border-solid border-1 border-color-white px-15 py-10 rounded-10",
                  onclick: onNext,
                },
                TranslationContext.getData().buttons.loadMore
              )
            )
          : null,
      ])
    ),
  ];
}

export default Wrapper.wrapContext(
  Content,
  [DocumentContext, RequestContext],
  ({ userId }) => {
    const documents = DocumentContext.getDocuments();

    const { name, username } = documents.User[userId];

    return { name, username };
  },
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      nodes: postIds,
      hasNextPage,
      nextPageCursor,
    } = requests[requestKey].data.userPosts.data;

    return { postIds, hasNextPage, nextPageCursor };
  }
);
