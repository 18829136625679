import $, { Wrapper } from "untrue";

import { RequestWrapper } from "@superbia/untrue";

import DocumentContext from "../../../../../../../context/DocumentContext";
import RequestContext from "../../../../../../../context/RequestContext";

import Load from "./Load";
import Content from "./Content";
import Error from "./Error";

function Parent({ requestKey, parentId, exists, error, childSentByLogged }) {
  if (error !== null) {
    return $(Error, { childSentByLogged });
  }

  if (exists) {
    return $(Content, { parentId, childSentByLogged });
  }

  return $(Load, { requestKey, parentId, childSentByLogged });
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(
    Parent,
    [DocumentContext, RequestContext],
    ({ parentId }) => {
      const documents = DocumentContext.getDocuments();

      const exists = parentId in documents.Message;

      return { exists };
    },
    ({ requestKey }) => {
      const requests = RequestContext.getRequests();

      const { error = null } = requests?.[requestKey] ?? {};

      return { error };
    }
  )
);
