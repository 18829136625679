import { Component } from "untrue";

import ChatRecordingContext from "../../../context/ChatRecordingContext";

import { client } from "../../../client";

class ChatRecordingSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMount);
    this.on("unmount", this.handleUnmount);
  }

  handleMount = () => {
    this.subscription = client.subscribe({ chatRecording: null });

    this.subscription.on("data", (data) => {
      const { chatRecording } = data;

      ChatRecordingContext.insert(chatRecording);
    });
  };

  handleUnmount = () => {
    this.subscription.unsubscribe();
  };
}

export default ChatRecordingSubscriber;
