"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Comparer = void 0;
class Comparer {
    static compare(a, b) {
        if (a === null) {
            return b === null;
        }
        if (Array.isArray(a)) {
            if (!Array.isArray(b)) {
                return false;
            }
            if (a.length !== b.length) {
                return false;
            }
            return a.every((element, i) => this.compare(element, b[i]));
        }
        if (typeof a === "object") {
            if (b === null) {
                return false;
            }
            if (Array.isArray(b)) {
                return false;
            }
            if (typeof b !== "object") {
                return false;
            }
            const aKeys = Object.getOwnPropertyNames(a);
            const bKeys = Object.getOwnPropertyNames(b);
            if (aKeys.length !== bKeys.length) {
                return false;
            }
            return aKeys.every((key) => key in b && this.compare(a[key], b[key]));
        }
        return a === b;
    }
}
exports.Comparer = Comparer;
