import $ from "untrue";

import { Head } from "@untrue/web";

import Helper from "../../helpers/Helper";

import TranslationContext from "../../context/TranslationContext";

const sections = [
  {
    heading: "Home",
    items: [
      {
        title: "Following",
        text: "You'll see content created by your friends and the accounts you follow.",
      },
      {
        title: "For you",
        text: "You'll get recommendations based on your interactions and what you like.",
      },
    ],
  },
  {
    heading: "Posts",
    items: [
      {
        title: "Up to 10 pictures or videos",
        text: "You can create posts with pictures and videos from your gallery. You can also create text only posts.",
      },
      {
        title: "Tag people",
        text: "Tag your friends on your pictures.",
      },
      {
        title: "Hashtags",
        text: "Add hashtags to your posts so you can reach more people.",
      },
      {
        title: "Albums",
        text: "Organize posts in albums.",
      },
    ],
  },
  {
    heading: "Stories",
    items: [
      {
        title: "Camera",
        text: "Capture moments and share them with your friends and followers.",
      },
      {
        title: "Moments that last forever",
        text: "While stories will disappear from your Story Bubble after 24 hours, they will remain visible in your profile. This way nobody won't miss anything you posted.",
      },
      {
        title: "Likes",
        text: "You can like stories.",
      },
    ],
  },
  {
    heading: "Reels",
    items: [
      {
        title: "Short videos",
        text: "Never get bored again with short form videos.",
      },
      {
        title: "For you",
        text: "Get recommendations from videos you liked.",
      },
    ],
  },
  {
    heading: "Places",
    items: [
      {
        title: "Add location",
        text: "You can let people know where you're at. This feature is available for posts, stories and reels.",
      },
      { title: "Near places", text: "Get suggestions of places near you." },
    ],
  },
  {
    heading: "Chats",
    items: [
      {
        title: "Instant messaging",
        text: "Connect with your friends and your favorite accounts instantly.",
      },
      { title: "Groups", text: "Create chat groups with up to 600 members." },
      {
        title: "Voice & media",
        text: "Send voice messages, photos and videos instantly.",
      },
    ],
  },
  {
    heading: "Profiles",
    items: [
      {
        title: "Follows",
        text: "Follow users to see their content in your home screen.",
      },
      {
        title: "Friends",
        text: "When you and other user follow each other, you'll become friends..",
      },
    ],
  },
];

function Help() {
  return [
    $(
      Head,
      $("title", Helper.getTitle(TranslationContext.getData().titles.help))
    ),
    $("main", { class: "container mx-auto p-30" }, [
      $("h1", { class: "font-size-30 font-bold mb-30" }, ["Help"]),
      $(
        "p",
        { class: "mb-30" },
        "Welcome to Iconshot! Here are the basics to get you started."
      ),
      sections.map(({ heading, items }) => [
        $("h2", { class: "font-size-24 font-bold mb-20" }, heading),
        $(
          "ul",
          { class: "mb-30" },
          items.map(({ title, text }) =>
            $("li", [$("b", `${title}:`), ` ${text}`])
          )
        ),
      ]),
    ]),
  ];
}

export default Help;
