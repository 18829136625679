import $, { Component, Wrapper } from "untrue";

import { RequestWrapper } from "@superbia/untrue";

import RequestContext from "../../../../../context/RequestContext";
import TranslationContext from "../../../../../context/TranslationContext";

import Scrollable from "../../../../../components/Scrollable";

import Loading from "./Loading";
import Error from "./Error";
import Content from "./Content";

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.hover = false;

    this.on("update", this.handleUpdateRequest);

    this.on("mount", this.handleMountMouseUp);
    this.on("unmount", this.handleUnmountMouseUp);
  }

  handleUpdateRequest = () => {
    const { shown, started } = this.props;
    const { shown: prevShown, started: prevStarted } = this.prevProps;

    if ((shown !== prevShown || started !== prevStarted) && shown && !started) {
      this.onRequest();
    }
  };

  handleMountMouseUp = () => {
    window.addEventListener("mouseup", this.mouseUpListener);
  };

  handleUnmountMouseUp = () => {
    window.removeEventListener("mouseup", this.mouseUpListener);
  };

  mouseUpListener = (event) => {
    if (event.target === document.documentElement) {
      return;
    }

    const { shown, onTab } = this.props;

    if (this.hover) {
      return;
    }

    if (!shown) {
      return;
    }

    onTab(null);
  };

  onMouseEnter = () => {
    this.hover = true;
  };

  onMouseLeave = () => {
    this.hover = false;
  };

  onRequest = () => {
    const { requestKey } = this.props;

    RequestContext.request(requestKey, { notifications: { limit: 20 } });
  };

  onLoad = () => {
    const { requestKey, nextPageCursor } = this.props;

    RequestContext.load(requestKey, {
      notifications: { cursor: nextPageCursor, limit: 20 },
    });
  };

  render() {
    const { requestKey, shown, loading, done, error, hasNextPage, onTab } =
      this.props;

    return $(
      "div",
      {
        class: `absolute top-50 right-0 ${
          shown
            ? "opacity-100% translate-y-0 z-1"
            : "opacity-0% translate-y-[-50px] visibility-hidden pointer-events-none z-0"
        } transition-all-300ms`,
        onmouseenter: this.onMouseEnter,
        onmouseleave: this.onMouseLeave,
      },
      $(
        Scrollable,
        {
          className:
            "w-400 rounded-12 bg-color-secondary-15 box-shadow-0-0-8 box-shadow-color-blank-0-50% max-h-75vh flex flex-column py-20 gap-20 overflow-y-auto scrollable",
          onEndReached: done && hasNextPage ? this.onLoad : null,
        },
        [
          $(
            "h3",
            { class: "color-white font-size-20 font-bold px-20" },
            TranslationContext.getData().template.auth.header.tabs.notifications
          ),
          loading ? $(Loading) : null,
          error !== null ? $(Error, { onRetry: this.onRequest }) : null,
          done ? $(Content, { requestKey, onTab }) : null,
        ]
      )
    );
  }
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(Notifications, RequestContext, ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const started = requestKey in requests;

    const {
      loading = true,
      done = false,
      error = null,
      data = null,
    } = requests?.[requestKey] ?? {};

    let hasNextPage = false;
    let nextPageCursor = null;

    if (data !== null) {
      ({ hasNextPage, nextPageCursor } = data.notifications.data);
    }

    return {
      started,
      loading,
      done,
      error,
      hasNextPage,
      nextPageCursor,
    };
  })
);
