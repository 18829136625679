import $, { Component, Ref, Wrapper } from "untrue";

import RequestContext from "../../context/RequestContext";
import TranslationContext from "../../context/TranslationContext";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.nameRef = new Ref();
    this.emailRef = new Ref();
    this.passwordRef = new Ref();

    this.on("mount", this.handleMountFocus);
    this.on("update", this.handleUpdateFocus);
  }

  handleMountFocus = () => {
    this.onFocus();
  };

  handleUpdateFocus = () => {
    const { shown } = this.props;
    const { shown: prevShown } = this.prevProps;

    if (shown !== prevShown) {
      this.onFocus();
    }
  };

  onFocus = () => {
    const { shown } = this.props;

    if (!shown) {
      return;
    }

    const name = this.nameRef.current;

    name.focus();
  };

  onSubmit = () => {
    const { requestKey, onReset } = this.props;

    const { value: name } = this.nameRef.current;
    const { value: email } = this.emailRef.current;
    const { value: password } = this.passwordRef.current;

    onReset();

    RequestContext.request(requestKey, { register: { name, email, password } });

    return false;
  };

  render() {
    const { loading } = this.props;

    return $(
      "form",
      {
        class: "p-20 bg-color-secondary-15 rounded-12",
        onsubmit: this.onSubmit,
      },
      [
        $("div", { class: "mb-15 flex flex-column gap-10" }, [
          $("input", {
            ref: this.nameRef,
            class:
              "w-100% rounded-12 px-15 py-10 bg-color-secondary-20 focus:bg-color-secondary-25",
            type: "text",
            placeholder: TranslationContext.getData().placeholders.name,
          }),
          $("input", {
            ref: this.emailRef,
            class:
              "w-100% rounded-12 px-15 py-10 bg-color-secondary-20 focus:bg-color-secondary-25",
            type: "email",
            placeholder: TranslationContext.getData().placeholders.email,
          }),
          $("input", {
            ref: this.passwordRef,
            class:
              "w-100% rounded-12 px-15 py-10 bg-color-secondary-20 focus:bg-color-secondary-25",
            type: "password",
            placeholder: TranslationContext.getData().placeholders.password,
          }),
        ]),
        $("div", { class: "relative" }, [
          $(
            "button",
            {
              class: `w-100% h-45 rounded-12 px-15 flex justify-center align-center bg-color-primary-100 font-bold hover:bg-color-[primary-light]-100 ${
                loading ? "opacity-50%" : ""
              } transition-all-300ms`,
              type: "submit",
              onsubmit: this.onSubmit,
            },
            !loading ? TranslationContext.getData().buttons.signUp : null
          ),
          loading
            ? $(
                "div",
                {
                  class: "absolute inset-0 flex justify-center align-center",
                },
                [
                  $("i", {
                    class: "fas fa-circle-notch fa-spin",
                  }),
                ]
              )
            : null,
        ]),
      ]
    );
  }
}

export default Wrapper.wrapContext(SignIn, RequestContext, ({ requestKey }) => {
  const requests = RequestContext.getRequests();

  const {
    loading = false,
    done = false,
    error = null,
  } = requests?.[requestKey] ?? {};

  return { loading, done, error };
});
