import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import AuthContext from "../../../../../context/AuthContext";
import DocumentContext from "../../../../../context/DocumentContext";

import UserPictureBorder from "../../../../../components/UserPictureBorder";

function PictureButton({ loggedId, permalink }) {
  return $(
    "a",
    { class: "header-button", href: permalink, onclick: Router.onClick },
    $(UserPictureBorder, { userId: loggedId, size: "w-35" })
  );
}

export default Wrapper.wrapContext(
  PictureButton,
  [AuthContext, DocumentContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  ({ loggedId }) => {
    const documents = DocumentContext.getDocuments();

    const { permalink } = documents.User[loggedId];

    return { permalink };
  }
);
