import $ from "untrue";

import { Head } from "@untrue/web";

import Helper from "../../helpers/Helper";

import TranslationContext from "../../context/TranslationContext";

import PlayStoreImage from "../../../images/store_buttons/playstore.png";
import AppStoreImage from "../../../images/store_buttons/appstore.png";

import config from "../../config";

function Support() {
  return [
    $(
      Head,
      $("title", Helper.getTitle(TranslationContext.getData().titles.support))
    ),
    $("main", { class: "container mx-auto p-30" }, [
      $("h1", { class: "font-size-30 font-bold mb-30" }, "Support"),
      $("div", { class: "flex mb-20" }, [
        $(
          "div",
          { class: "mr-10" },
          $(
            "a",
            {
              href: `https://play.google.com/store/apps/details?id=${config.playStorePackageName}`,
            },
            $("img", { class: "block w-200", src: PlayStoreImage })
          )
        ),
        $(
          "a",
          { href: `https://apps.apple.com/us/app/${config.appStoreAppId}` },
          $("img", { class: "block w-200", src: AppStoreImage })
        ),
      ]),
      $(
        "p",
        { class: "mb-20" },
        "You can download the app or write to support service by e-mail."
      ),
      $(
        "a",
        { href: "mailto:support@iconshot.com" },
        $("b", "support@iconshot.com")
      ),
    ]),
  ];
}

export default Support;
