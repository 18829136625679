import $, { Component, Ref } from "untrue";

import CreatePostModalContext from "../../../context/CreatePostModalContext";

import ContrastButton from "../../../components/ContrastButton";

import Image from "./Image";
import Video from "./Video";

class Figure extends Component {
  constructor(props) {
    super(props);

    this.elementRef = new Ref();

    this.url = null;

    this.on("mount", this.handleMountUrl);
    this.on("unmount", this.handleUnmountUrl);
  }

  handleMountUrl = () => {
    const {
      figure: {
        media: { file },
      },
    } = this.props;

    const element = this.elementRef.current;

    this.url = URL.createObjectURL(file);

    element.src = this.url;
  };

  handleUnmountUrl = () => {
    URL.revokeObjectURL(this.url);
  };

  onDelete = () => {
    const { index } = this.props;

    CreatePostModalContext.deleteFigure(index);
  };

  render() {
    const {
      figure: {
        media: { type },
      },
    } = this.props;

    return $("div", { class: "relative" }, [
      type === "image"
        ? $(Image, { elementRef: this.elementRef })
        : $(Video, { elementRef: this.elementRef }),
      $(
        "button",
        {
          class:
            "absolute right-5 top-5 w-30 h-30 bg-color-black color-white font-size-18 flex justify-center align-center rounded-100%",
          onclick: this.onDelete,
        },
        $("i", { class: "fas fa-xmark" })
      ),
      $(
        "div",
        { class: "absolute left-5 bottom-5" },
        $(ContrastButton, { icon: "fa-user", text: "Tag people" })
      ),
    ]);
  }
}

export default Figure;
