import $, { Wrapper } from "untrue";

import DocumentContext from "../../../context/DocumentContext";

import Image from "./Image/Image";
import Video from "./Video/Video";

function Figure({
  figureId,
  index,
  active,
  figuresCount,
  type,
  width,
  height,
  onNext,
  onPrev,
}) {
  return type === "image"
    ? $(Image, { figureId, index, figuresCount, onNext, onPrev })
    : $(Video, { figureId, index, figuresCount, onNext, onPrev });
}

export default Wrapper.wrapContext(Figure, DocumentContext, ({ figureId }) => {
  const documents = DocumentContext.getDocuments();

  const figure = documents.PostFigure[figureId];

  const { media: mediaId } = figure;

  const media = documents.Media[mediaId];

  const { type, width, height } = media;

  return { type, width, height };
});
