import $, { Wrapper } from "untrue";

import RequestContext from "../../../../../context/RequestContext";

import Chat from "./Chat/Chat";

function Content({ loading, chatIds, onTab }) {
  return [
    $(
      "div",
      chatIds.map((chatId) => $(Chat, { chatId, onTab }))
    ),
    loading
      ? $(
          "div",
          { class: "flex justify-center font-size-20 color-secondary-60" },
          $("i", { class: "fas fa-circle-notch fa-spin" })
        )
      : null,
  ];
}

export default Wrapper.wrapContext(
  Content,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      loading,
      data: { nodes: chatIds },
    } = requests[requestKey].data.chats;

    return { loading, chatIds };
  }
);
