import $ from "untrue";

function Loading() {
  return [0, 1].map(() =>
    $(
      "div",
      {
        class:
          "rounded-12 bg-color-secondary-15 aspect-4/3 flex flex-column animate-pulse",
      },
      [
        $("div", { class: "p-20" }, [
          $("div", { class: "flex align-center mb-20" }, [
            $("div", {
              class: "w-45 h-45 rounded-100% bg-color-secondary-20",
            }),
            $("div", {
              class: "ml-15 w-200 h-25 bg-color-secondary-20",
            }),
          ]),
          $("div", {
            class: "w-400 h-25 bg-color-secondary-20 mb-10",
          }),
          $("div", { class: "w-300 h-25 bg-color-secondary-20" }),
        ]),
        $("div", { class: "flex-1 bg-color-secondary-20" }),
        $(
          "div",
          { class: "flex p-20" },
          [0, 1, 2].map(() =>
            $("div", { class: "flex align-center mr-15" }, [
              $("div", {
                class: "w-25 h-25 rounded-100% bg-color-secondary-20",
              }),
              $("div", {
                class: "ml-10 w-50 h-20 bg-color-secondary-20",
              }),
            ])
          )
        ),
      ]
    )
  );
}

export default Loading;
