"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scroller = void 0;
const untrue_1 = require("untrue");
class Scroller extends untrue_1.Component {
    init() {
        this.on("mount", () => {
            window.scrollTo(0, 0);
        });
    }
    render() {
        const { children } = this.props;
        return children;
    }
}
exports.Scroller = Scroller;
