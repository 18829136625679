import { Context } from "untrue";

import UploadHelper from "../helpers/UploadHelper";

import AuthContext from "./AuthContext";
import DocumentContext from "./DocumentContext";
import RequestContext from "./RequestContext";

import { client } from "../client";

class SendMessageContext extends Context {
  constructor() {
    super();

    this.data = {};
  }

  getData() {
    return this.data;
  }

  insert(chatId, text = null, media = null, voice = false, parentId = null) {
    const itemId = Date.now().toString();

    const item = {
      params: { text, media, voice, parentId },
      loading: false,
      done: false,
      error: null,
      messageId: null,
    };

    if (!(chatId in this.data)) {
      this.data[chatId] = {};
    }

    const items = this.data[chatId];

    items[itemId] = item;

    this.run(chatId);

    const tmpMessage = this.createTmpMessage({
      itemId,
      chatId,
      text,
      media,
      voice,
      parentId,
    });

    DocumentContext.data({ tmpMessage });

    DocumentContext.addTmpMessage(tmpMessage);
    RequestContext.addTmpMessage(tmpMessage);
  }

  async run(chatId) {
    const items = this.data[chatId];

    const itemIds = Object.keys(items);

    const loading = itemIds.some((itemId) => items[itemId].loading);

    if (loading) {
      return;
    }

    const index = itemIds.findIndex((itemId) => {
      const item = items[itemId];

      return !item.loading && !item.done && item.error === null;
    });

    if (index === -1) {
      return;
    }

    const itemId = itemIds[index];

    await this.sendMessage(chatId, itemId);

    this.run(chatId);
  }

  async sendMessage(chatId, itemId) {
    const item = this.data[chatId][itemId];

    item.loading = true;

    this.update();

    try {
      const {
        params: { text, media, voice, parentId },
      } = item;

      const message =
        media === null
          ? await this.subscribe(itemId, chatId, text, parentId)
          : await this.request(itemId, chatId, text, media, voice, parentId);

      const messageId = message.id.toString();

      item.done = true;
      item.messageId = messageId;
    } catch (error) {
      item.error = error;
    } finally {
      item.loading = false;

      this.update();
    }
  }

  subscribe(eventId, chatId, text, parentId) {
    return new Promise((resolve, reject) => {
      const subscription = client.subscribe({
        sendMessage: { eventId, chatId, text, parentId },
      });

      subscription.on("data", (data) => {
        const { sendMessage: message } = data;

        subscription.unsubscribe();

        resolve(message);
      });

      subscription.on("error", (error) => {
        subscription.unsubscribe();

        reject(error);
      });
    });
  }

  async request(eventId, chatId, text, media, voice, parentId) {
    const upload = UploadHelper.createUpload(media);

    const response = await client.request({
      sendMessage: { eventId, chatId, text, upload, voice, parentId },
    });

    const data = response.data();

    const { sendMessage: message } = data;

    return message;
  }

  createTmpMessage({ itemId, chatId, text, media, voice, parentId }) {
    const { loggedId } = AuthContext.getState();

    const textTrim = text !== null ? text.trim() : null;

    const type = voice ? "voice" : media !== null ? media.type : "text";

    const messageText =
      textTrim !== null && textTrim.length > 0 ? textTrim : null;

    return {
      _typename: "Message",
      id: itemId,
      action: "sent",
      type,
      text: messageText,
      chat: chatId,
      sender: loggedId,
      media:
        media !== null
          ? {
              _typename: "Media",
              id: Helper.randomId(),
              type: media.type,
              source: media.webUri,
              width: media.width,
              height: media.height,
              duration: media.duration,
              sizes: [],
              small:
                media.type !== "audio"
                  ? {
                      _typename: "MediaSize",
                      id: Helper.randomId(),
                      source: media.webUri,
                      width: media.width,
                      height: media.height,
                    }
                  : null,
              poster:
                media.type === "video"
                  ? {
                      _typename: "Media",
                      id: Helper.randomId(),
                      type: "image",
                      source: media.webUri,
                      width: media.width,
                      height: media.height,
                      duration: null,
                      sizes: [],
                      small: {
                        id: Helper.randomId(),
                        source: media.webUri,
                        width: media.width,
                        height: media.height,
                      },
                    }
                  : null,
            }
          : null,
      participants: [],
      mentions: [],
      users: [],
      receivedBy: [loggedId],
      received: true,
      readBy: [loggedId],
      read: true,
      readByAll: false,
      parent: parentId,
      createdAt: new Date().toISOString(),
    };
  }
}

export default new SendMessageContext();
