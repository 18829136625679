import $ from "untrue";

function PictureBorder({ source, size }) {
  return $("div", { class: "relative" }, [
    $("img", {
      class: `block ${size} aspect-1/1 rounded-100%`,
      src: source,
    }),
    $("div", {
      class:
        "absolute inset-0 border-1 border-solid border-color-white opacity-10% rounded-100%",
    }),
  ]);
}

export default PictureBorder;
