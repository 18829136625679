import $, { Component } from "untrue";

import { Router } from "@untrue/web";

import Logo from "../../../../../images/logo.png";

import ChatButton from "./Button/IconButton/ChatButton";
import NotificationButton from "./Button/IconButton/NotificationButton";
import TransactionButton from "./Button/IconButton/TransactionButton";

import PictureButton from "./Button/PictureButton";

import Search from "./Search/Search";

import Chats from "./Chats/Chats";
import Notifications from "./Notifications/Notifications";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { tab: null };
  }

  onTab = (tab) => {
    this.updateState({ tab });
  };

  render() {
    const { tab } = this.state;

    return $(
      "div",
      {
        class:
          "fixed top-0 right-0 left-0 z-10 h-60 bg-color-secondary-20 box-shadow-1-2-3 box-shadow-color-blank-0-10%",
      },
      [
        $(
          "div",
          {
            class: "container mx-auto h-100% flex justify-between align-center",
          },
          [
            $("div", { class: "flex align-center" }, [
              $("a", { href: "/", onclick: Router.onClick }, [
                $(
                  "div",
                  { class: "relative" },
                  $("img", {
                    src: Logo,
                    class: "block w-130 pointer-events-none",
                  })
                ),
              ]),
              $("div", { class: "ml-20" }, $(Search)),
            ]),
            $("div", { class: "relative" }, [
              $("div", { class: "flex" }, [
                $(ChatButton, {
                  shown: tab === "chats",
                  onTab: this.onTab,
                }),
                $(NotificationButton, {
                  shown: tab === "notifications",
                  onTab: this.onTab,
                }),
                $(TransactionButton, {
                  shown: tab === "transactions",
                  onTab: this.onTab,
                }),
                $(PictureButton),
              ]),
              $(Chats, { shown: tab === "chats", onTab: this.onTab }),
              $(Notifications, {
                shown: tab === "notifications",
                onTab: this.onTab,
              }),
            ]),
          ]
        ),
      ]
    );
  }
}

export default Header;
