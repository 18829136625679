import $, { Component, Wrapper } from "untrue";

import AuthContext from "../../../../../../context/AuthContext";
import DocumentContext from "../../../../../../context/DocumentContext";
import SendMessageContext from "../../../../../../context/SendMessageContext";
import DropdownOverlayContext from "../../../../../../context/DropdownOverlayContext";
import ChatWindowContext from "../../../../../../context/ChatWindowContext";
import TranslationContext from "../../../../../../context/TranslationContext";

import MessageInfo from "./MessageInfo";
import MessageText from "./MessageText";
import MessageVoice from "./MessageVoice";
import MessageAudio from "./MessageAudio";
import MessageMedia from "./MessageMedia";
import MessageUnsent from "./MessageUnsent";

import Time from "./Time";

import Parent from "./Parent/Parent";

class Message extends Component {
  constructor(props) {
    super(props);
  }

  onHandlePlay = () => {
    const { messageId, onPlay } = this.props;

    onPlay(messageId);
  };

  onHandleReply = () => {
    const { messageId, windowId, onReply } = this.props;

    onReply(messageId);

    ChatWindowContext.forceFocus(windowId);
  };

  onDropdownButtonClick = (event) => {
    DropdownOverlayContext.show(event.target, [
      {
        label: TranslationContext.getData().dropdown.chats.reply,
        onClick: this.onHandleReply,
      },
    ]);
  };

  render() {
    const {
      messageId,
      loading,
      loggedId,
      action,
      type,
      senderId,
      parentId,
      playing,
      onPlayEnd,
    } = this.props;

    const sentByLogged = senderId === loggedId;

    const isSentOrUnsent = ["sent", "unsent"].includes(action);

    let arrangeClass = "";

    let Content = null;

    switch (action) {
      case "sent": {
        switch (type) {
          case "text": {
            Content = MessageText;

            break;
          }

          case "image":
          case "video": {
            Content = MessageMedia;

            break;
          }

          case "audio": {
            Content = MessageAudio;

            break;
          }

          case "voice": {
            Content = MessageVoice;

            break;
          }
        }

        break;
      }

      case "unsent": {
        Content = MessageUnsent;

        break;
      }

      default: {
        Content = MessageInfo;

        arrangeClass = "justify-center";

        break;
      }
    }

    if (isSentOrUnsent && sentByLogged) {
      arrangeClass = "flex-row-reverse";
    }

    return $("div", { class: `my-1 px-15 flex align-end ${arrangeClass}` }, [
      $(Content, {
        messageId,
        loading,
        sentByLogged,
        playing,
        DropdownNode: $(
          "button",
          {
            class: `message-dropdown-button absolute top-0 right-0 z-10 w-25 h-25 rounded-100% font-size-12 bg-gradient-radial ${
              sentByLogged
                ? "bg-gradient-from-color-primary-90"
                : "bg-gradient-from-color-secondary-20"
            } bg-gradient-to-color-transparent hidden [hover:message]:block flex justify-center items-center`,
            onclick: this.onDropdownButtonClick,
          },
          [
            $("div", {
              class:
                "absolute inset-0 m-2 bg-color-white rounded-100% opacity-0% [hover:message-dropdown-button]:opacity-25% transition-300ms",
            }),
            $("i", { class: "fas fa-chevron-down" }),
          ]
        ),
        ParentNode:
          parentId !== null
            ? $(
                "div",
                { class: "p-5 pb-0" },
                $(Parent, {
                  parentId,
                  childSentByLogged: sentByLogged,
                })
              )
            : null,
        onPlay: this.onHandlePlay,
        onPlayEnd,
      }),
      isSentOrUnsent
        ? $("div", { class: "mx-5" }, $(Time, { messageId }))
        : null,
    ]);
  }
}

export default Wrapper.wrapContext(
  Message,
  [AuthContext, DocumentContext, SendMessageContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  ({ messageId: tmpId }) => {
    const documents = DocumentContext.getDocuments();

    const data = SendMessageContext.getData();

    let messageId = tmpId;

    const { chat: chatId } = documents.Message[messageId];

    let loading = false;
    let done = false;

    if (chatId in data && tmpId in data[chatId]) {
      const item = data[chatId][tmpId];

      ({ loading, done } = item);

      if (done) {
        messageId = item.messageId;
      }
    }

    return { loading, messageId };
  },
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    const {
      action,
      type,
      sender: senderId,
      parent: parentId,
    } = documents.Message[messageId];

    return { action, type, senderId, parentId };
  }
);
