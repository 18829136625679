"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EverEmitter = void 0;
class EverEmitter {
    constructor(options) {
        var _a;
        this.events = new Map();
        this.ignoreErrors = (_a = options === null || options === void 0 ? void 0 : options.ignoreErrors) !== null && _a !== void 0 ? _a : false;
        this.onError = options === null || options === void 0 ? void 0 : options.onError;
    }
    on(name, closure) {
        let listeners = this.events.get(name);
        if (listeners === undefined) {
            listeners = [];
            this.events.set(name, listeners);
        }
        const listener = { closure, once: false };
        listeners.push(listener);
    }
    once(name, closure) {
        let listeners = this.events.get(name);
        if (listeners === undefined) {
            listeners = [];
            this.events.set(name, listeners);
        }
        const listener = { closure, once: true };
        listeners.push(listener);
    }
    off(name, closure) {
        if (name === undefined) {
            this.events.clear();
            return;
        }
        let listeners = this.events.get(name);
        if (listeners === undefined) {
            return;
        }
        const tmpListeners = closure !== undefined
            ? listeners.filter((listener) => listener.closure !== closure)
            : [];
        this.updateListeners(name, tmpListeners);
    }
    emit(name, ...args) {
        const listeners = this.events.get(name);
        if (listeners === undefined) {
            return;
        }
        const tmpListeners = [...listeners];
        this.runListeners(name, tmpListeners, ...args);
    }
    emitReversed(name, ...args) {
        const listeners = this.events.get(name);
        if (listeners === undefined) {
            return;
        }
        const tmpListeners = [...listeners].reverse();
        this.runListeners(name, tmpListeners, ...args);
    }
    removeListenerIfOnce(name, listener) {
        if (!listener.once) {
            return;
        }
        let listeners = this.events.get(name);
        if (listeners === undefined) {
            return;
        }
        const tmpListeners = listeners.filter((tmpListener) => tmpListener !== listener);
        this.updateListeners(name, tmpListeners);
    }
    updateListeners(name, listeners) {
        if (listeners.length === 0) {
            this.events.delete(name);
        }
        else {
            this.events.set(name, listeners);
        }
    }
    runListeners(name, listeners, ...args) {
        if (this.ignoreErrors) {
            for (const listener of listeners) {
                this.removeListenerIfOnce(name, listener);
                try {
                    const bool = listener.closure(...args);
                    if (bool === false) {
                        return;
                    }
                }
                catch (error) { }
            }
            return;
        }
        if (this.onError !== undefined) {
            for (const listener of listeners) {
                this.removeListenerIfOnce(name, listener);
                try {
                    const bool = listener.closure(...args);
                    if (bool === false) {
                        return;
                    }
                }
                catch (error) {
                    const bool = this.onError(error, name, ...args);
                    if (bool === false) {
                        return;
                    }
                }
            }
            return;
        }
        for (const listener of listeners) {
            this.removeListenerIfOnce(name, listener);
            const bool = listener.closure(...args);
            if (bool === false) {
                return;
            }
        }
    }
}
exports.EverEmitter = EverEmitter;
