import { Component } from "untrue";

import { client } from "../../../client";

class MessageUnsentSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMount);
    this.on("unmount", this.handleUnmount);
  }

  handleMount = () => {
    this.subscription = client.subscribe({ messageUnsent: null });
  };

  handleUnmount = () => {
    this.subscription.unsubscribe();
  };
}

export default MessageUnsentSubscriber;
