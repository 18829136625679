import $, { Animation, Component, Ref } from "untrue";

import Helper from "../../helpers/Helper";

import Button from "./Button";

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = { paused: false, ended: false, elapsed: 0, muted: false };

    this.videoRef = new Ref();
    this.barRef = new Ref();
    this.pointerRef = new Ref();

    this.frame = null;

    this.on("mount", this.handleMountPlay);

    this.on("mount", this.handleMountFrame);
    this.on("unmount", this.handleUnmountFrame);
  }

  handleMountPlay = () => {
    const video = this.videoRef.current;

    video.play();
  };

  handleMountFrame = () => {
    const callback = () => {
      const { duration } = this.props;

      const { elapsed } = this.state;

      const video = this.videoRef.current;
      const bar = this.barRef.current;
      const pointer = this.pointerRef.current;

      if (video === null) {
        return;
      }

      if (bar === null) {
        return;
      }

      if (pointer === null) {
        return;
      }

      const tmpElapsed = Math.ceil(video.currentTime) * 1000;

      if (tmpElapsed !== elapsed) {
        this.updateState({ elapsed: tmpElapsed });
      }

      const value = (video.currentTime * 1000) / duration;

      const animation = new Animation(value);

      bar.style.width = animation.interpolate([0, 1], ["0%", "100%"]);

      pointer.style.transform = `translateX(${animation.interpolate(
        [0, 1],
        ["0%", "100%"]
      )})`;

      this.frame = requestAnimationFrame(callback);
    };

    this.frame = requestAnimationFrame(callback);
  };

  handleUnmountFrame = () => {
    cancelAnimationFrame(this.frame);
  };

  onToggleMuted = () => {
    const { muted } = this.state;

    const video = this.videoRef.current;

    this.updateState({ muted: !muted });

    video.muted = !muted;
  };

  onTogglePaused = () => {
    const { paused } = this.state;

    const video = this.videoRef.current;

    if (paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  onPause = () => {
    this.updateState({ paused: true });
  };

  onPlay = () => {
    this.updateState({ paused: false });
  };

  onRepeat = () => {
    const video = this.videoRef.current;

    this.updateState({ ended: false });

    video.currentTime = 0;

    video.play();
  };

  onEnded = () => {
    const video = this.videoRef.current;

    video.pause();

    this.updateState({ ended: true });
  };

  onBar = (event) => {
    const { duration } = this.props;

    const { ended } = this.state;

    const video = this.videoRef.current;

    const {
      target: { offsetWidth },
      offsetX,
    } = event;

    const time = (offsetX * duration) / offsetWidth / 1000;

    video.currentTime = time;

    if (ended) {
      this.updateState({ ended: false });

      video.play();
    }
  };

  render() {
    const { source, duration } = this.props;

    const { elapsed, paused, ended, muted } = this.state;

    return $("div", { class: "w-100% h-100%" }, [
      $("video", {
        ref: this.videoRef,
        class: "w-100% h-100% object-fit-contain",
        src: source,
        onpause: this.onPause,
        onplay: this.onPlay,
        onended: this.onEnded,
      }),
      $("div", { class: "absolute bottom-0 left-0 right-0" }, [
        $("div", {
          class:
            "absolute inset-0 flex bg-gradient-linear bg-gradient-from-color-transparent bg-gradient-to-color-blank-0-50%",
        }),
        $(
          "div",
          {
            class:
              "relative z-0 flex justify-center align-center pt-50 px-15 pb-15 gap-15",
          },
          [
            $(Button, {
              icon: ended ? "fa-redo-alt" : paused ? "fa-play" : "fa-pause",
              onClick: ended ? this.onRepeat : this.onTogglePaused,
            }),
            $(
              "div",
              { class: "relative z-[-1] w-600 h-15 flex align-center" },
              [
                $(
                  "div",
                  {
                    class:
                      "relative flex-1 rounded-4 overflow-hidden bg-color-blank-100-50%",
                  },
                  [
                    $("div", { class: "h-3 bg-blank-100 opacity-50" }),
                    $("div", {
                      ref: this.barRef,
                      class: "absolute top-0 bottom-0 bg-color-primary-100",
                    }),
                  ]
                ),
                $(
                  "div",
                  { class: "absolute inset-0 flex items-center" },
                  $(
                    "div",
                    { ref: this.pointerRef, class: "flex-1" },
                    $("div", {
                      class: "w-15 h-15 rounded-100% bg-color-primary-100",
                      style: "transform: translateX(-50%)",
                    })
                  )
                ),
                $("div", {
                  class: "absolute inset-0 cursor-pointer",
                  onclick: this.onBar,
                }),
              ]
            ),
            $(
              "span",
              { class: "block font-size-14" },
              `${Helper.formatTime(elapsed)} / ${Helper.formatTime(duration)}`
            ),
            $(Button, {
              icon: muted ? "fa-volume-xmark" : "fa-volume-high",
              onClick: this.onToggleMuted,
            }),
          ]
        ),
      ]),
    ]);
  }
}

export default Video;
