import $ from "untrue";

import { Tree } from "@untrue/web";

import App from "./App";

const root = document.getElementById("root");

const tree = new Tree(root);

tree.mount($(App));
