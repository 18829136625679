import { Client } from "@superbia/client";

import CookieHelper from "./helpers/CookieHelper";

import AuthContext from "./context/AuthContext";
import TranslationContext from "./context/TranslationContext";

import config from "./config";

export const client = new Client({
  url: config.apiUrl,
  wsUrl: config.apiWsUrl,
  fetch,
  FormData,
  WebSocket,
});

export const getHeaders = () => {
  const headers = {};

  const { isAuth } = AuthContext.getState();

  if (isAuth) {
    const token = CookieHelper.getCookie("token");

    if (token !== null) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  }

  if (TranslationContext.isDone()) {
    headers["Accept-Language"] = TranslationContext.getLanguage();
  }

  return headers;
};

export const initClient = () => {
  const headers = getHeaders();

  client.update(headers);

  client.init();
};
