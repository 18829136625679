import $, { Component, Wrapper } from "untrue";

import TranslationContext from "../context/TranslationContext";

import Logo from "../../images/logo.png";

class AppTranslator extends Component {
  constructor(props) {
    super(props);

    this.on("mount", this.handleMountLoad);
  }

  handleMountLoad = () => {
    TranslationContext.load();
  };

  render() {
    const { done, children } = this.props;

    if (!done) {
      return $(
        "div",
        { class: "h-100% flex justify-center align-center animate-pulse" },
        $("img", { class: "block w-200", src: Logo })
      );
    }

    return children;
  }
}

export default Wrapper.wrapContext(AppTranslator, TranslationContext, () => {
  const done = TranslationContext.isDone();

  return { done };
});
