import $, { Component, Ref } from "untrue";

import TranslationContext from "../../../../../context/TranslationContext";

import List from "./List";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = { shown: false, query: "" };

    this.hover = false;
    this.shouldRender = false;

    this.inputRef = new Ref();

    this.on("update", this.handleUpdateShown);

    this.on("mount", this.handleMountMouseUp);
    this.on("unmount", this.handleUnmountMouseUp);
  }

  handleMountMouseUp = () => {
    window.addEventListener("mouseup", this.mouseUpListener);
  };

  handleUnmountMouseUp = () => {
    window.removeEventListener("mouseup", this.mouseUpListener);
  };

  mouseUpListener = (event) => {
    if (event.target === document.documentElement) {
      return;
    }

    const input = this.inputRef.current;

    if (this.hover) {
      return;
    }

    this.updateState({ shown: false });

    input.blur();
  };

  handleUpdateShown = () => {
    const { shown } = this.state;

    if (shown) {
      this.shouldRender = true;
    }
  };

  onFocus = () => {
    this.updateState({ shown: true });
  };

  onForceFocus = () => {
    const input = this.inputRef.current;

    input.focus();
  };

  onHide = () => {
    this.updateState({ shown: false });
  };

  onMouseEnter = () => {
    this.hover = true;
  };

  onMouseLeave = () => {
    this.hover = false;
  };

  onInput = () => {
    const input = this.inputRef.current;

    const { value: query } = input;

    this.updateState({ query });
  };

  onClear = () => {
    const input = this.inputRef.current;

    input.value = "";

    this.updateState({ query: "" });
  };

  render() {
    const { shown, query } = this.state;

    return $(
      "div",
      {
        class: "relative",
        onmouseenter: this.onMouseEnter,
        onmouseleave: this.onMouseLeave,
      },
      [
        $("div", { class: "relative" }, [
          $("input", {
            ref: this.inputRef,
            class: "px-20 pl-50 h-45 w-450 rounded-32 bg-color-secondary-25",
            placeholder: TranslationContext.getData().placeholders.search,
            onfocus: this.onFocus,
            oninput: this.onInput,
          }),
          $(
            "button",
            {
              class:
                "absolute left-0 top-0 bottom-0 w-50 color-secondary-45 flex align-center justify-center hover:color-secondary-60",
              onclick: query !== "" ? this.onClear : this.onForceFocus,
            },
            query !== ""
              ? $("i", { class: "fas fa-xmark" })
              : $("i", { class: "fa fa-search" })
          ),
        ]),
        $(
          "div",
          {
            class: `absolute top-50 right-0 left-0 z-[-1] ${
              !shown
                ? "opacity-0% translate-y-[-50px] visibility-hidden pointer-events-none"
                : ""
            } transition-all-300ms`,
          },
          shown || this.shouldRender
            ? $(List, { key: query, query, onHide: this.onHide })
            : null
        ),
      ]
    );
  }
}

export default Search;
