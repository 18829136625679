import $ from "untrue";

function ContrastButton({ icon, text, rightNode = null, onClick }) {
  return $(
    "div",
    {
      class:
        "px-10 py-5 bg-color-black rounded-32 flex align-center cursor-pointer",
      onclick: onClick,
    },
    [
      $("span", { class: "font-size-14" }, $("i", { class: `fas ${icon}` })),
      $("span", { class: "ml-10 color-white white-space-nowrap" }, text),
      rightNode !== null ? $("div", { class: "ml-10" }, rightNode) : null,
    ]
  );
}

export default ContrastButton;
