import $ from "untrue";

function Loading() {
  return $("div", {
    class:
      "w-120 aspect-9/14 flex-shrink-0 rounded-10 bg-color-secondary-20 animate-pulse",
  });
}

export default Loading;
