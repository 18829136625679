import $ from "untrue";

import NewMessageSubscriber from "./NewMessageSubscriber";
import NewNotificationSubscriber from "./NewNotificationSubscriber";
import ChatUpdateSubscriber from "./ChatUpdateSubscriber";
import ChatsReceivedSubscriber from "./ChatsReceivedSubscriber";
import ChatReceivedSubscriber from "./ChatReceivedSubscriber";
import ChatReadSubscriber from "./ChatReadSubscriber";
import ChatRecordingSubscriber from "./ChatRecordingSubscriber";
import ChatTypingSubscriber from "./ChatTypingSubscriber";
import MessageUnsentSubscriber from "./MessageUnsentSubscriber";
import OnlineSubscriber from "./OnlineSubscriber";

function Subscriber() {
  return [
    $(NewMessageSubscriber),
    $(NewNotificationSubscriber),
    $(ChatUpdateSubscriber),
    $(ChatsReceivedSubscriber),
    $(ChatReceivedSubscriber),
    $(ChatReadSubscriber),
    $(ChatRecordingSubscriber),
    $(ChatTypingSubscriber),
    $(MessageUnsentSubscriber),
    $(OnlineSubscriber),
  ];
}

export default Subscriber;
