import $ from "untrue";

import TranslationContext from "../../../../../../context/TranslationContext";

function MessageUnsent({ sentByLogged }) {
  return $(
    "div",
    {
      class: `max-w-75% ${
        sentByLogged ? "bg-color-primary-90" : "bg-color-secondary-20"
      } rounded-10`,
    },
    $("div", { class: "px-10 py-5 flex align-center color-white" }, [
      $("span", { class: "font-size-14" }, $("i", { class: "fas fa-ban" })),
      $(
        "span",
        { class: "ml-5 font-size-16" },
        TranslationContext.getData().chats.messages.unsent
      ),
    ])
  );
}

export default MessageUnsent;
