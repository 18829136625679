import $, { Component, Ref, Wrapper } from "untrue";

import AuthContext from "../../../context/AuthContext";
import DocumentContext from "../../../context/DocumentContext";
import DropdownOverlayContext from "../../../context/DropdownOverlayContext";
import TranslationContext from "../../../context/TranslationContext";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = { boxHover: false, menuHover: false };

    this.buttonRef = new Ref();
  }

  onBoxMouseEnter = () => {
    this.updateState({ boxHover: true });
  };

  onBoxMouseLeave = () => {
    this.updateState({ boxHover: false });
  };

  onMenuMouseEnter = () => {
    this.updateState({ menuHover: true });
  };

  onMenuMouseLeave = () => {
    this.updateState({ menuHover: false });
  };

  onMenuClick = (event) => {
    const button = this.buttonRef.current;

    DropdownOverlayContext.show(button, [
      { label: "Photo / Video", onClick: () => {} },
    ]);

    event.stopPropagation();
  };

  render() {
    const { picture } = this.props;

    const { boxHover, menuHover } = this.state;

    return $(
      "div",
      {
        class:
          "w-120 aspect-9/14 flex-shrink-0 rounded-10 bg-color-secondary-10 flex flex-column cursor-pointer",
        onmouseenter: this.onBoxMouseEnter,
        onmouseleave: this.onBoxMouseLeave,
      },
      [
        $("div", { class: "relative flex-1" }, [
          $("img", {
            class: "w-100% h-100% rounded-t-10 object-fit-cover",
            src: picture,
          }),
          $(
            "div",
            {
              class:
                "absolute top-0 right-0 left-0 rounded-t-10 bg-gradient-linear bg-gradient-from-color-blank-0-25% bg-gradient-to-color-transparent flex justify-end p-5 pb-40",
            },
            $(
              "button",
              {
                ref: this.buttonRef,
                class:
                  "relative w-30 h-30 flex justify-center align-center rounded-100% font-size-16 hover:bg-color-blank-0-50% transition-all-300ms",
                onmouseenter: this.onMenuMouseEnter,
                onmouseleave: this.onMenuMouseLeave,
                onclick: this.onMenuClick,
              },
              $("i", { class: "fas fa-ellipsis-v" })
            )
          ),
        ]),
        $("div", { class: "relative p-15 pt-25 text-center" }, [
          $(
            "span",
            { class: "font-bold font-size-12" },
            TranslationContext.getData().buttons.createStory
          ),
          $(
            "div",
            { class: "absolute right-0 left-0 top-[-20] flex justify-center" },
            $(
              "div",
              {
                class: `w-35 h-35 flex justify-center align-center bg-color-primary-100 rounded-100% font-size-18 ${
                  boxHover && !menuHover ? "scale-1.2" : ""
                } transition-all-300ms`,
              },
              $("i", { class: "fas fa-plus" })
            )
          ),
        ]),
      ]
    );
  }
}

export default Wrapper.wrapContext(
  Create,
  [AuthContext, DocumentContext, DropdownOverlayContext],
  () => {
    const { loggedId } = AuthContext.getState();

    const documents = DocumentContext.getDocuments();

    const { picture: pictureId } = documents.User[loggedId];

    const { small: smallId } = documents.Media[pictureId];

    const { source: picture } = documents.MediaSize[smallId];

    return { picture };
  }
);
