import $ from "untrue";

function Loading() {
  return $(
    "div",
    { class: "animate-pulse" },
    [0, 1, 2, 3, 4, 5, 6, 7, 8].map(() =>
      $("div", { class: "flex align-center px-20 py-10" }, [
        $("div", {
          class: "w-45 h-45 rounded-100% bg-color-secondary-20 mr-20",
        }),
        $("div", { class: "w-200 h-25 bg-color-secondary-20" }),
      ])
    )
  );
}

export default Loading;
