import $ from "untrue";

import { Head } from "@untrue/web";

import Helper from "../../helpers/Helper";

import TranslationContext from "../../context/TranslationContext";

function PrivacyPolicy() {
  return [
    $(
      Head,
      $(
        "title",
        Helper.getTitle(TranslationContext.getData().titles.privacyPolicy)
      )
    ),
    $("main", { class: "container mx-auto p-30" }, [
      $("h1", { class: "font-size-30 font-bold mb-30" }, "Privacy Policy"),
      $(
        "p",
        "The app doesn't share any personal information with any other entity or third parties."
      ),
      $(
        "p",
        "We allow third-party companies to serve ads and collect certain anonymous information when you visit our app. These companies may use anonymous information such as your Google Advertising ID, your device type and version, browsing activity, location and other technical data relating to your device, in order to provide advertisements."
      ),
    ]),
  ];
}

export default PrivacyPolicy;
