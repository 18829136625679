import { Context } from "untrue";

import mimetypes from "mime-types";

class CreatePostModalContext extends Context {
  constructor() {
    super();

    this.state = { shown: false, autoFocus: false, text: "", figures: [] };
  }

  toggleShown() {
    const { shown } = this.state;

    this.updateState({ shown: !shown });
  }

  autoFocus(autoFocus) {
    this.updateState({ autoFocus });
  }

  changeText(text) {
    this.updateState({ text });
  }

  async addFigure(file) {
    const { figures } = this.state;

    const tmpFigures = [...figures];

    const type = mimetypes.lookup(file.name);

    const isImage = type.startsWith("image");
    const isVideo = type.startsWith("video");

    if (!isImage && !isVideo) {
      throw new Error("Add images or videos.");
    }

    const media = { file, type: isImage ? "image" : "video" };

    const figure = { media, tags: [] };

    tmpFigures.push(figure);

    this.updateState({ figures: tmpFigures });
  }

  deleteFigure(index) {
    const { figures } = this.state;

    const tmpFigures = [...figures];

    tmpFigures.splice(index, 1);

    this.updateState({ figures: tmpFigures });
  }

  deleteFigures() {
    this.updateState({ figures: [] });
  }
}

export default new CreatePostModalContext();
