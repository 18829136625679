"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorHandler = void 0;
const untrue_1 = require("untrue");
class ErrorHandler {
    static handle(error) {
        queueMicrotask(() => {
            throw error;
        });
    }
}
exports.ErrorHandler = ErrorHandler;
untrue_1.Emitter.onError = (error) => {
    ErrorHandler.handle(error);
};
