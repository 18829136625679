import $, { Wrapper } from "untrue";

import DocumentContext from "../context/DocumentContext";

import PictureBorder from "./PictureBorder";

function UserPictureBorder({ picture, size }) {
  return $(PictureBorder, { source: picture, size });
}

export default Wrapper.wrapContext(
  UserPictureBorder,
  DocumentContext,
  ({ userId }) => {
    const documents = DocumentContext.getDocuments();

    const { picture: pictureId } = documents.User[userId];

    const { small: smallId } = documents.Media[pictureId];

    const { source: picture } = documents.MediaSize[smallId];

    return { picture };
  }
);
