import $, { Component, Wrapper } from "untrue";

import { RequestWrapper } from "@superbia/untrue";

import DocumentContext from "../../context/DocumentContext";
import RequestContext from "../../context/RequestContext";

import NotFound from "../NotFound/NotFound";

import Loading from "./Loading";
import Content from "./Content";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.on("mount", this.handleMountRequest);
  }

  handleMountRequest = () => {
    const { requestKey, username } = this.props;

    RequestContext.request(requestKey, {
      user: { username },
      userPosts: { username, limit: 20 },
    });
  };

  render() {
    const { requestKey, loading, done, error, userId } = this.props;

    if (loading) {
      return $(Loading);
    }

    if (done) {
      return $(Content, { requestKey, userId });
    }

    if (error) {
      return $(NotFound);
    }

    return null;
  }
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(
    Profile,
    [DocumentContext, RequestContext],
    ({
      requestKey,
      route: {
        params: { username: routeUsername },
      },
    }) => {
      const documents = DocumentContext.getDocuments();
      const requests = RequestContext.getRequests();

      let username = routeUsername;

      const {
        loading = false,
        done = false,
        error = null,
        data = null,
      } = requests?.[requestKey] ?? {};

      const { user: userId = null } = data !== null ? data : {};

      if ("User" in documents && userId in documents.User) {
        ({ username } = documents.User[userId]);
      }

      return { loading, done, error, userId, username };
    }
  )
);
