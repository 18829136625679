import $, { Component, Wrapper } from "untrue";

import { RequestWrapper } from "@superbia/untrue";

import RequestContext from "../../../../../context/RequestContext";

import Content from "./Content";
import Error from "./Error";

class List extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.on("mount", this.handleMountRequest);
    this.on("unmount", this.handleUnmountRequest);
  }

  handleMountRequest = () => {
    this.timeout = setTimeout(this.onRequest, 500);
  };

  handleUnmountRequest = () => {
    clearTimeout(this.timeout);
  };

  onRequest = () => {
    const { requestKey, query } = this.props;

    RequestContext.request(requestKey, { searchUsers: { query, limit: 20 } });
  };

  render() {
    const { requestKey, loading, done, error, onHide } = this.props;

    return $(
      "div",
      {
        class:
          "flex flex-column rounded-12 bg-color-secondary-15 box-shadow-0-0-8 box-shadow-color-blank-0-50% max-h-450 overflow-y-auto scrollable",
      },
      [
        loading
          ? $(
              "div",
              {
                class:
                  "flex-1 flex justify-center py-20 font-size-20 color-secondary-60",
              },
              $("i", { class: "fas fa-circle-notch fa-spin" })
            )
          : null,
        done ? $(Content, { requestKey, onHide }) : null,
        error !== null ? $(Error, { onRetry: this.onRequest }) : null,
      ]
    );
  }
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(List, RequestContext, ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      loading = true,
      done = false,
      error = null,
      data = null,
    } = requests?.[requestKey] ?? {};

    return { loading, done, error, data };
  })
);
