import $, { Wrapper } from "untrue";

import RequestContext from "../../../context/RequestContext";

import Create from "./Create";
import Loading from "./Loading";

function Stories({ loading, done, error }) {
  return $(
    "div",
    {
      class:
        "p-15 bg-color-secondary-15 rounded-10 flex gap-15 overflow-x-auto scrollable",
    },
    [
      $(Create),
      loading
        ? [$(Loading), $(Loading), $(Loading), $(Loading), $(Loading)]
        : null,
    ]
  );
}

export default Wrapper.wrapContext(
  Stories,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      loading = false,
      done = false,
      error = null,
    } = requests?.[requestKey] ?? {};

    return { loading, done, error };
  }
);
