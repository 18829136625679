import $, { Wrapper } from "untrue";

import Helper from "../../../../helpers/Helper";

import RequestContext from "../../../../context/RequestContext";
import DocumentContext from "../../../../context/DocumentContext";
import ChatWindowContext from "../../../../context/ChatWindowContext";
import ChatTypingContext from "../../../../context/ChatTypingContext";
import ChatRecordingContext from "../../../../context/ChatRecordingContext";
import TranslationContext from "../../../../context/TranslationContext";

import UserPicture from "../../../../components/UserPicture";
import UserName from "../../../../components/UserName";
import PictureBorder from "../../../../components/PictureBorder";

import Online from "./Online";

function Header({
  windowId,
  userId,
  title,
  picture,
  group,
  member,
  online,
  onlineAt,
  typingName,
  recordingName,
}) {
  const onClose = () => {
    ChatWindowContext.delete(windowId);
  };

  let labelText = null;

  if (typingName !== null) {
    labelText = group
      ? TranslationContext.getData().chats.typing.group.replace(
          "%name%",
          typingName
        )
      : TranslationContext.getData().chats.typing.user;
  } else if (recordingName !== null) {
    labelText = group
      ? TranslationContext.getData().chats.recording.group.replace(
          "%name%",
          recordingName
        )
      : TranslationContext.getData().chats.recording.user;
  } else {
    if (group) {
      labelText = TranslationContext.getData().chats.tap.group;
    } else {
      if (online !== null && online) {
        labelText = TranslationContext.getData().chats.online;
      } else if (onlineAt !== null) {
        const dateObj = new Date(onlineAt);

        const now = new Date();

        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );

        const yesterday = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );

        yesterday.setTime(yesterday.getTime() - 1000 * 60 * 60 * 24);

        const daysAgo = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );

        daysAgo.setTime(daysAgo.getTime() - 1000 * 60 * 60 * 24 * 6);

        const isTimeSingular = [1, 13].includes(dateObj.getHours());

        const isToday =
          dateObj.getDate() === today.getDate() &&
          dateObj.getMonth() === today.getMonth() &&
          dateObj.getFullYear() === today.getFullYear();

        const isYesterday =
          dateObj.getDate() === yesterday.getDate() &&
          dateObj.getMonth() === yesterday.getMonth() &&
          dateObj.getFullYear() === yesterday.getFullYear();

        const isDaysAgo = dateObj.getTime() >= daysAgo.getTime();

        let date = "";

        const time = Helper.formatDateTime(onlineAt);

        if (isToday) {
          date = TranslationContext.getData().dates.today;
        } else if (isYesterday) {
          date = TranslationContext.getData().dates.yesterday;
        } else if (isDaysAgo) {
          date = `${TranslationContext.getData().dates.days[dateObj.getDay()]}`;
        } else {
          date = `${
            TranslationContext.getData().dates.monthsLess[dateObj.getMonth()]
          } ${dateObj.getDate()}`;

          if (dateObj.getFullYear() !== now.getFullYear()) {
            date += `, ${dateObj.getFullYear()}`;
          }
        }

        date = date.toLocaleLowerCase();

        labelText = isTimeSingular
          ? TranslationContext.getData()
              .chats.lastSeenAt.singular.replace("%date%", date)
              .replace("%time%", time)
          : TranslationContext.getData()
              .chats.lastSeenAt.plural.replace("%date%", date)
              .replace("%time%", time);
      }
    }
  }

  return [
    $(
      "div",
      {
        class:
          "pl-15 pr-10 py-10 rounded-t-12 bg-color-secondary-20 flex align-center hover:bg-color-secondary-25 cursor-pointer transition-250ms",
      },
      [
        picture !== null
          ? $(PictureBorder, { source: picture, size: "w-35" })
          : $(UserPicture, { userId, size: "w-35" }),
        $("div", { class: "mx-15 flex-1 flex flex-column" }, [
          $(
            "div",
            { class: "font-size-14 mb-1" },
            title !== null
              ? $("span", { class: "font-bold color-white" }, title)
              : $(UserName, { userId })
          ),
          labelText !== null
            ? $(
                "span",
                {
                  class:
                    "block overflow-hidden whitespace-nowrap text-ellipsis font-size-12 color-secondary-60",
                },
                labelText
              )
            : null,
        ]),
        $("div", { class: "flex gap-5" }, [
          // $(
          //   "button",
          //   {
          //     class:
          //       "w-25 h-25 rounded-4 flex justify-center align-center color-secondary-60 hover:bg-color-secondary-25 transition-all-300ms",
          //     onclick: this.onMinimize,
          //   },
          //   $("i", { class: "fas fa-minus" })
          // ),
          $(
            "button",
            {
              class:
                "w-25 h-25 rounded-4 flex justify-center align-center color-secondary-60 hover:bg-color-secondary-20 transition-all-300ms",
              onclick: onClose,
            },
            $("i", { class: "fas fa-xmark" })
          ),
        ]),
      ]
    ),
    userId !== null ? $(Online, { userId }) : null,
  ];
}

export default Wrapper.wrapContext(
  Header,
  [RequestContext, DocumentContext, ChatTypingContext, ChatRecordingContext],
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    let title = null;
    let picture = null;

    if (chatId !== null) {
      const { title: chatTitle, picture: pictureId } = documents.Chat[chatId];

      title = chatTitle;

      const { small: smallId } = documents.Media[pictureId];

      ({ source: picture } = documents.MediaSize[smallId]);
    }

    return { title, picture };
  },
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    let group = null;

    if (chatId !== null) {
      ({ group } = documents.Chat[chatId]);
    }

    return { group };
  },
  ({ requestKey, chatId }) => {
    const requests = RequestContext.getRequests();

    const { data = null } = requests?.[requestKey] ?? {};

    if (data !== null) {
      chatId = data.chat;
    }

    return { chatId };
  },
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    const typingData = ChatTypingContext.getData();
    const recordingData = ChatRecordingContext.getData();

    let typing = null;
    let recording = null;

    let typingDate = null;
    let recordingDate = null;

    let typingId = null;
    let recordingId = null;

    let typingName = null;
    let recordingName = null;

    if (chatId in typingData) {
      typing = typingData[chatId];
    }

    if (chatId in recordingData) {
      const items = recordingData[chatId];

      if (items.length > 0) {
        recording = items[items.length - 1];
      }
    }

    if (typing !== null) {
      typingDate = new Date(typing.startedAt);
    }

    if (recording !== null) {
      recordingDate = new Date(recording.startedAt);
    }

    if (typing !== null) {
      typingId = typing.user;
    }

    if (recording !== null && (typing === null || recordingDate > typingDate)) {
      typingId = null;
      recordingId = recording.user;
    }

    if (typingId !== null) {
      typingName = documents.User[typingId].name;
    }

    if (recordingId !== null) {
      recordingName = documents.User[recordingId].name;
    }

    return { typingName, recordingName };
  },
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    const chats = documents?.Chat ?? {};

    let member = false;

    if (chatId in chats) {
      ({ member } = chats[chatId]);
    }

    return { member };
  },
  ({ userId }) => {
    const documents = DocumentContext.getDocuments();

    const users = documents?.User ?? {};

    let online = null;
    let onlineAt = null;

    if (userId in users) {
      ({ online, onlineAt } = users[userId]);
    }

    return { online, onlineAt };
  }
);
