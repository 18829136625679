import { Context } from "untrue";

class ChatWindowContext extends Context {
  constructor() {
    super();

    this.windows = [];

    this.focusId = 0;
  }

  getWindows() {
    return this.windows;
  }

  getId({ chatId = null, userId = null }) {
    return JSON.stringify({ chatId, userId });
  }

  get(id) {
    return this.windows.find((window) => window.id === id) ?? null;
  }

  add({ chatId = null, userId = null, focus = true }) {
    const id = this.getId({ chatId, userId });

    const index = this.windows.findIndex((window) => window.id === id);

    if (index === -1) {
      const window = { id, chatId, userId, focusId: null, focused: false };

      this.windows.unshift(window);
    }

    this.update();

    if (focus) {
      this.forceFocus(id);
    }
  }

  delete(id) {
    this.windows = this.windows.filter((window) => window.id !== id);

    this.update();
  }

  focus(id) {
    const window = this.get(id);

    window.focused = true;

    this.update();
  }

  blur(id) {
    const window = this.get(id);

    window.focused = false;

    this.update();
  }

  forceFocus(id) {
    const window = this.get(id);

    window.focusId = this.focusId++;

    this.update();

    for (const window of this.windows) {
      if (window.id === id) {
        this.focus(window.id);
      } else {
        this.blur(window.id);
      }
    }
  }
}

export default new ChatWindowContext();
