import { Component } from "untrue";

import DocumentContext from "../../../../context/DocumentContext";

import { client } from "../../../../client";

class Online extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMount);
    this.on("unmount", this.handleUnmount);
  }

  handleMount = () => {
    const { userId } = this.props;

    this.subscription = client.subscribe({ onlineUpdate: { userId } });

    this.subscription.on("data", (data) => {
      const { onlineUpdate } = data;

      const { user: userId, online, onlineAt } = onlineUpdate;

      DocumentContext.applyOnline(userId, online, onlineAt);
    });
  };

  handleUnmount = () => {
    this.subscription.unsubscribe();
  };
}

export default Online;
