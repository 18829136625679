import $ from "untrue";

import { Router } from "@untrue/web";

import Logo from "../../../../images/logo.png";

function NotAuthTemplate({ children }) {
  return [
    $(
      "div",
      { class: "p-20 flex justify-center" },
      $(
        "a",
        { href: "/", onclick: Router.onClick },
        $("img", { class: "block w-150 pointer-events-none", src: Logo })
      )
    ),
    children,
  ];
}

export default NotAuthTemplate;
