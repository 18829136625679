import { Context } from "untrue";

class ChatTypingContext extends Context {
  constructor() {
    super();

    this.data = {};

    this.timeouts = new Map();
  }

  getData() {
    return this.data;
  }

  insert(chatTyping) {
    const {
      id: chatTypingId,
      chat: chatId,
      user: { id: userId },
      startedAt,
    } = chatTyping;

    const newItem = { id: chatTypingId, user: userId, startedAt };

    this.data[chatId] = newItem;

    this.update();

    const timeout = this.timeouts.get(chatId);

    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    this.timeouts.set(
      chatId,
      setTimeout(() => {
        this.clear(chatId);
      }, 1500)
    );
  }

  clear(chatId) {
    delete this.data[chatId];

    this.update();
  }
}

export default new ChatTypingContext();
