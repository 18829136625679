import { Upload } from "@superbia/client";

import { client } from "../client";

class UploadHelper {
  static createUpload(media) {
    const { file } = media;

    const { name } = file;

    return new Upload(file, name);
  }

  static async createVideo(media) {
    const { file } = media;

    const response = await client.request({
      createVideo: { filename: file.name },
    });

    const { createVideo: videoId } = response.data();

    const chunks = [];
    const size = 10000000;

    for (let i = 0; i < file.size; i += size) {
      const chunk = file.slice(i, i + size);

      chunks.push(chunk);
    }

    for (const chunk of chunks) {
      await client.request({
        uploadVideo: { videoId, upload: new Upload(chunk) },
      });
    }

    await client.request({ finishVideo: { videoId } });

    return videoId;
  }
}

export default UploadHelper;
