import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import Helper from "../../../../../../helpers/Helper";

import AuthContext from "../../../../../../context/AuthContext";
import DocumentContext from "../../../../../../context/DocumentContext";
import RequestContext from "../../../../../../context/RequestContext";
import TranslationContext from "../../../../../../context/TranslationContext";

import UserPicture from "../../../../../../components/UserPicture";

import Badge from "./Badge";

import Album from "./Right/Album";
import Post from "./Right/Post";
import Story from "./Right/Story";
import Reel from "./Right/Reel";

function Notification({
  notificationId,
  loggedUsername,
  type,
  sendersCount,
  createdAt,
  senderId,
  username,
  userName,
  albumId,
  postId,
  storyId,
  reelId,
  commentId,
  seen,
  onTab,
}) {
  let text = null;

  let leftNode = null;
  let rightNode = null;

  switch (type) {
    case "verified":
    case "unverified": {
      text = TranslationContext.getData().notifications[type];

      break;
    }

    default: {
      text = TranslationContext.getData().notifications[type][
        sendersCount === 1 ? "singular" : "plural"
      ].replace("%number%", sendersCount - 1);

      break;
    }
  }

  const textNodes = text.split("%name%");

  textNodes.splice(1, 0, $("b", userName));

  switch (type) {
    case "albumLike":
      rightNode = $(Album, { albumId });

      break;

    case "postShare":
    case "postTag":
    case "postMention":
    case "postLike":
    case "postComment":
    case "postCommentMention":
    case "postCommentLike":
    case "postCommentReply":
    case "postCommentReplyMention":
    case "postCommentReplyLike": {
      rightNode = $(Post, { postId });

      break;
    }

    case "storyTag":
    case "storyMention":
    case "storyLike":
    case "storyComment":
    case "storyCommentMention":
    case "storyCommentLike":
    case "storyCommentReply":
    case "storyCommentReplyMention":
    case "storyCommentReplyLike": {
      rightNode = $(Story, { storyId });

      break;
    }

    case "reelTag":
    case "reelMention":
    case "reelLike":
    case "reelComment":
    case "reelCommentMention":
    case "reelCommentLike":
    case "reelCommentReply":
    case "reelCommentReplyMention":
    case "reelCommentReplyLike": {
      rightNode = $(Reel, { reelId });

      break;
    }
  }

  switch (type) {
    case "verified": {
      leftNode = $(Badge, {
        backgroundColor: "bg-color-primary-cerulean-100",
        borderColor: "bg-color-primary-cerulean-90",
        icon: "fas fa-check",
      });

      break;
    }

    case "unverified": {
      leftNode = $(Badge, {
        backgroundColor: "bg-color-primary-cerulean-100",
        borderColor: "border-color-primary-cerulean-90",
        icon: "fas fa-xmark",
      });

      break;
    }

    case "follow":
    case "followRequestConfirmed":
    case "albumLike":
    case "postShare":
    case "postTag":
    case "postMention":
    case "postLike":
    case "postComment":
    case "postCommentMention":
    case "postCommentLike":
    case "postCommentReply":
    case "postCommentReplyMention":
    case "postCommentReplyLike":
    case "storyTag":
    case "storyMention":
    case "storyLike":
    case "storyComment":
    case "storyCommentMention":
    case "storyCommentLike":
    case "storyCommentReply":
    case "storyCommentReplyMention":
    case "storyCommentReplyLike":
    case "reelTag":
    case "reelMention":
    case "reelLike":
    case "reelComment":
    case "reelCommentMention":
    case "reelCommentLike":
    case "reelCommentReply":
    case "reelCommentReplyMention":
    case "reelCommentReplyLike": {
      leftNode = $(UserPicture, { userId: senderId, size: "w-45" });

      break;
    }
  }

  let href = null;

  switch (type) {
    case "verified":
    case "unverified": {
      href = `/${loggedUsername}`;

      break;
    }

    case "follow":
    case "followRequestConfirmed": {
      href = `/${username}`;

      break;
    }

    case "albumLike": {
      href = `/album/${albumId}`;

      break;
    }

    case "postShare":
    case "postTag":
    case "postMention":
    case "postLike": {
      href = `/post/${postId}`;

      break;
    }

    case "postComment":
    case "postCommentMention":
    case "postCommentLike":
    case "postCommentReply":
    case "postCommentReplyMention":
    case "postCommentReplyLike": {
      href = `/post/${postId}`;

      break;
    }

    case "storyTag":
    case "storyMention":
    case "storyLike": {
      href = `/story/${storyId}`;

      break;
    }

    case "storyComment":
    case "storyCommentMention":
    case "storyCommentLike":
    case "storyCommentReply":
    case "storyCommentReplyMention":
    case "storyCommentReplyLike": {
      href = `/story/${storyId}`;

      break;
    }

    case "reelTag":
    case "reelMention":
    case "reelLike": {
      href = `/reel/${reelId}`;

      break;
    }

    case "reelComment":
    case "reelCommentMention":
    case "reelCommentLike":
    case "reelCommentReply":
    case "reelCommentReplyMention":
    case "reelCommentReplyLike": {
      href = `/reel/${reelId}`;

      break;
    }
  }

  const onClick = (...args) => {
    RequestContext.request(null, {
      markNotificationAsSeen: { notificationId },
    });

    onTab(null);

    return Router.onClick(...args);
  };

  const dateObj = new Date(createdAt);

  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  yesterday.setTime(yesterday.getTime() - 1000 * 60 * 60 * 24);

  const daysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  daysAgo.setTime(daysAgo.getTime() - 1000 * 60 * 60 * 24 * 6);

  const isTimeSingular = [1, 13].includes(dateObj.getHours());

  const isToday =
    dateObj.getDate() === today.getDate() &&
    dateObj.getMonth() === today.getMonth() &&
    dateObj.getFullYear() === today.getFullYear();

  const isYesterday =
    dateObj.getDate() === yesterday.getDate() &&
    dateObj.getMonth() === yesterday.getMonth() &&
    dateObj.getFullYear() === yesterday.getFullYear();

  const isDaysAgo = dateObj.getTime() >= daysAgo.getTime();

  let date = "";

  const time = Helper.formatDateTime(createdAt);

  if (isToday) {
    date = TranslationContext.getData().dates.today;
  } else if (isYesterday) {
    date = TranslationContext.getData().dates.yesterday;
  } else if (isDaysAgo) {
    date = `${TranslationContext.getData().dates.days[dateObj.getDay()]}`;
  } else {
    date = `${
      TranslationContext.getData().dates.months[dateObj.getMonth()]
    } ${dateObj.getDate()}`;

    if (dateObj.getFullYear() !== now.getFullYear()) {
      date += `, ${dateObj.getFullYear()}`;
    }
  }

  const dateText = isTimeSingular
    ? TranslationContext.getData()
        .dates.parsed.singular.replace("%date%", date)
        .replace("%time%", time)
    : TranslationContext.getData()
        .dates.parsed.plural.replace("%date%", date)
        .replace("%time%", time);

  return $(
    "a",
    {
      class: `${
        !seen
          ? "bg-color-[primary-light]-100-75% hover:bg-color-[primary-light]-100-85%"
          : "hover:bg-color-secondary-20"
      } px-20 py-10 relative flex flex-column cursor-pointer transition-all-300ms`,
      href,
      onclick: onClick,
    },
    [
      $("div", { class: "flex align-center mb-10" }, [
        leftNode,
        $("span", { class: "flex-1 px-15" }, textNodes),
        rightNode,
      ]),
      $(
        "span",
        {
          class: `font-size-12 ${
            !seen ? "color-white opacity-75%" : "color-secondary-60"
          }`,
        },
        dateText
      ),
    ]
  );
}

export default Wrapper.wrapContext(
  Notification,
  [AuthContext, DocumentContext, RequestContext],
  () => {
    const { loggedId } = AuthContext.getState();

    const documents = DocumentContext.getDocuments();

    const { username: loggedUsername } = documents.User[loggedId];

    return { loggedUsername };
  },
  ({ notificationId }) => {
    const documents = DocumentContext.getDocuments();

    const {
      type,
      sender: senderId,
      post: postId,
      story: storyId,
      reel: reelId,
      comment: commentId,
      album: albumId,
      sendersCount,
      createdAt,
      seen,
    } = documents.Notification[notificationId];

    let userName = null;
    let username = null;

    if (senderId !== null) {
      ({ username, name: userName } = documents.User[senderId]);
    }

    return {
      type,
      sendersCount,
      createdAt,
      senderId,
      username,
      userName,
      albumId,
      postId,
      storyId,
      reelId,
      commentId,
      seen,
    };
  }
);
