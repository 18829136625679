import $, { Component, Wrapper } from "untrue";

import { Head } from "@untrue/web";

import { RequestWrapper } from "@superbia/untrue";

import Helper from "../../helpers/Helper";

import CounterContext from "../../context/CounterContext";
import RequestContext from "../../context/RequestContext";
import CreatePostContext from "../../context/CreatePostContext";
import TranslationContext from "../../context/TranslationContext";

import CreatePost from "./CreatePost";

import Posts from "./Posts/Posts";
import Stories from "./Stories/Stories";

class Home extends Component {
  constructor(props) {
    super(props);

    this.on("mount", this.handleMountRequest);
  }

  handleMountRequest = () => {
    const { requestKey, loading, done, error } = this.props;

    if (!loading && !done && error === null) {
      RequestContext.request(requestKey, {
        me: null,
        followingPosts: { limit: 20 },
        followingStories: { limit: 20 },
        followingReels: { limit: 20 },
      });
    }
  };

  render() {
    const { requestKey, totalCount, creating } = this.props;

    return [
      $(
        Head,
        $(
          "title",
          Helper.getTitle(
            totalCount !== null && totalCount > 0
              ? `(${totalCount > 999 ? "999+" : totalCount}) ${
                  TranslationContext.getData().titles.home
                }`
              : TranslationContext.getData().titles.home
          )
        )
      ),
      $("div", { class: "w-700 mx-auto flex flex-column py-15 gap-15" }, [
        $(CreatePost),
        $(Stories, { requestKey }),
        creating
          ? $(
              "div",
              {
                class:
                  "p-20 bg-color-secondary-15 color-secondary-60 rounded-12 flex align-center",
              },
              [
                $(
                  "span",
                  { class: "font-size-18 mr-15" },
                  $("i", { class: "fas fa-circle-notch fa-spin" })
                ),
                TranslationContext.getData().screens.home.create.post,
              ]
            )
          : null,
        $(Posts, { requestKey }),
      ]),
    ];
  }
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(
    Home,
    [RequestContext, CounterContext, CreatePostContext],
    ({ requestKey }) => {
      const requests = RequestContext.getRequests();

      const {
        loading = false,
        done = false,
        error = null,
      } = requests?.[requestKey] ?? {};

      return { loading, done, error };
    },
    () => {
      const { totalCount } = CounterContext.getState();

      return { totalCount };
    },
    () => {
      const data = CreatePostContext.getData();

      const creating = Object.keys(data).some((key) => data[key].loading);

      return { creating };
    }
  ),
  "home"
);
