import $, { Wrapper } from "untrue";

import DocumentContext from "../../../../../../context/DocumentContext";
import TranslationContext from "../../../../../../context/TranslationContext";
import ChatWindowContext from "../../../../../../context/ChatWindowContext";

import Helper from "../../../../../../helpers/Helper";

import PictureBorder from "../../../../../../components/PictureBorder";
import UserPicture from "../../../../../../components/UserPicture";
import UserName from "../../../../../../components/UserName";

import Message from "./Message";

function Chat({
  chatId,
  title,
  picture,
  userId,
  shownAt,
  notReadMessagesCount,
  onTab,
}) {
  const onClick = () => {
    if (userId !== null) {
      ChatWindowContext.add({ userId });
    } else {
      ChatWindowContext.add({ chatId });
    }

    onTab(null);
  };

  const highlight = notReadMessagesCount !== 0;

  const dateObj = new Date(shownAt);

  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  yesterday.setTime(yesterday.getTime() - 1000 * 60 * 60 * 24);

  const isToday =
    dateObj.getDate() === today.getDate() &&
    dateObj.getMonth() === today.getMonth() &&
    dateObj.getFullYear() === today.getFullYear();

  const isYesterday =
    dateObj.getDate() === yesterday.getDate() &&
    dateObj.getMonth() === yesterday.getMonth() &&
    dateObj.getFullYear() === yesterday.getFullYear();

  let time = "";

  if (isToday) {
    time = Helper.formatDateTime(shownAt);
  } else if (isYesterday) {
    time = TranslationContext.getData().dates.yesterday;
  } else {
    time = `${
      TranslationContext.getData().dates.monthsLess[dateObj.getMonth()]
    } ${dateObj.getDate()}`;

    if (dateObj.getFullYear() !== now.getFullYear()) {
      time += `, ${dateObj.getFullYear()}`;
    }
  }

  return $(
    "div",
    {
      class:
        "hover:bg-color-secondary-20 px-20 py-10 flex align-center cursor-pointer transition-all-300ms",
      onclick: onClick,
    },
    [
      picture !== null
        ? $(PictureBorder, { source: picture, size: "w-50" })
        : $(UserPicture, { userId, size: "w-50" }),
      $("div", { class: "flex flex-1 flex-column ml-15 min-w-0" }, [
        $("div", { class: "flex flex-1 align-center justify-between" }, [
          $(
            "div",
            { class: "flex-1 mr-30 font-size-14" },
            title !== null
              ? $("h3", { class: "color-white font-bold" }, title)
              : $(UserName, { userId })
          ),
          $(
            "span",
            {
              class: `font-size-12 ${
                highlight ? "color-primary-100" : "color-secondary-60"
              }`,
            },
            time
          ),
        ]),
        $("div", { class: "flex align-center" }, [
          $(Message, { chatId }),
          highlight
            ? $(
                "span",
                {
                  class: `h-25 bg-color-primary-100 flex align-center ml-20 font-size-12 color-white ${
                    notReadMessagesCount < 100
                      ? "aspect-1/1 justify-center rounded-100%"
                      : "px-5 rounded-32"
                  }`,
                },
                notReadMessagesCount
              )
            : null,
        ]),
      ]),
    ]
  );
}

export default Wrapper.wrapContext(Chat, DocumentContext, ({ chatId }) => {
  const documents = DocumentContext.getDocuments();

  const {
    title: tmpTitle,
    picture: pictureId,
    user: userId,
    shownAt,
    notReadMessagesCount,
    group,
  } = documents.Chat[chatId];

  let title = null;
  let picture = null;

  if (group) {
    title = tmpTitle;

    const { small: smallId } = documents.Media[pictureId];

    ({ source: picture } = documents.MediaSize[smallId]);
  }

  return { title, picture, userId, shownAt, notReadMessagesCount };
});
