import $ from "untrue";

import StoryThumbnail from "../../../../../../../components/StoryThumbnail";

function Story({ storyId }) {
  return $(
    "div",
    { class: "w-50" },
    $(StoryThumbnail, { storyId, fontSize: "font-size-8" })
  );
}

export default Story;
