import $ from "untrue";

function Badge({ backgroundColor, borderColor, icon }) {
  return $(
    "div",
    {
      class: `w-45 h-45 flex justify-center align-center rounded-100% font-size-18 border-4 border-solid ${backgroundColor} ${borderColor}`,
    },
    $("i", { class: icon })
  );
}

export default Badge;
