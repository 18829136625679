import $, { Wrapper } from "untrue";

import Helper from "../../../../../../helpers/Helper";

import AuthContext from "../../../../../../context/AuthContext";
import DocumentContext from "../../../../../../context/DocumentContext";
import SendMessageContext from "../../../../../../context/SendMessageContext";

function Time({
  sent,
  loading,
  done,
  error,
  loggedId,
  senderId,
  receivedByAll,
  readByAll,
  createdAt,
}) {
  const sentByLogged = senderId === loggedId;

  const hasError = error !== null;

  const showLoading = loading || (sent && !done && !hasError);

  let icon = null;
  let iconColor = "";

  if (sentByLogged) {
    if (showLoading) {
      icon = "fas fa-circle-notch fa-spin";
    } else {
      if (hasError) {
        icon = "fas fa-xmark";
        iconColor = "color-red-100";
      } else {
        icon = receivedByAll ? "fas fa-check-circle" : "far fa-check-circle";

        iconColor = readByAll ? "color-[cornflower-blue]-100" : "";
      }
    }
  }

  return $("div", { class: "flex align-center color-secondary-50" }, [
    $("span", { class: "font-size-12" }, Helper.formatDateTime(createdAt)),
    icon !== null
      ? $(
          "div",
          { class: `w-20 h-20 flex justify-end align-center ${iconColor}` },
          $("i", { class: icon })
        )
      : null,
  ]);
}

export default Wrapper.wrapContext(
  Time,
  [AuthContext, DocumentContext, SendMessageContext],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  ({ messageId: tmpId }) => {
    const documents = DocumentContext.getDocuments();

    const data = SendMessageContext.getData();

    let messageId = tmpId;

    const { chat: chatId } = documents.Message[messageId];

    let loading = false;
    let done = false;
    let error = null;

    let sent = false;

    if (chatId in data && tmpId in data[chatId]) {
      const item = data[chatId][tmpId];

      ({ loading, done, error } = item);

      if (done) {
        messageId = item.messageId;
      }

      sent = true;
    }

    return { sent, loading, done, error, messageId };
  },
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    const {
      sender: senderId,
      receivedByAll,
      readByAll,
      createdAt,
    } = documents.Message[messageId];

    return { senderId, receivedByAll, readByAll, createdAt };
  }
);
