import $, { Wrapper } from "untrue";

import DocumentContext from "../context/DocumentContext";

function UserName({
  outerClass = "",
  badgeSize = "w-20",
  class: className = "font-bold color-white",
  name,
  verified,
}) {
  return $("div", { class: `flex align-center ${outerClass}` }, [
    $("span", { class: className }, name),
    verified !== null && verified
      ? $(
          "div",
          {
            class: `${badgeSize} aspect-1 ml-10 bg-color-cerulean-100 flex justify-center align-center rounded-100% color-white font-size-0.75em`,
          },
          $("i", { class: "fas fa-check" })
        )
      : null,
  ]);
}

export default Wrapper.wrapContext(UserName, DocumentContext, ({ userId }) => {
  const documents = DocumentContext.getDocuments();

  const { name, verified } = documents.User[userId];

  return { name, verified };
});
