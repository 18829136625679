import { Component } from "untrue";

import { client } from "../../../client";

class OnlineSubscriber extends Component {
  constructor(props) {
    super(props);

    this.listener = null;

    this.subscription = null;

    this.on("mount", this.handleMountToggle);

    this.on("mount", this.handleMountListener);
    this.on("unmount", this.handleUnmountListener);

    this.on("unmount", this.handleUnmountSubscription);
  }

  handleMountToggle = () => {
    this.onToggle();
  };

  handleUnmountSubscription = () => {
    this.onUnsubscribe();
  };

  handleMountListener = () => {
    document.addEventListener("visibilitychange", this.onToggle);
  };

  handleUnmountListener = () => {
    document.removeEventListener("visibilitychange", this.onToggle);
  };

  onToggle = () => {
    if (!document.hidden) {
      this.onSubscribe();
    } else {
      this.onUnsubscribe();
    }
  };

  onSubscribe = () => {
    this.subscription = client.subscribe({ markUserAsOnline: null });
  };

  onUnsubscribe = () => {
    this.subscription.unsubscribe();
  };
}

export default OnlineSubscriber;
