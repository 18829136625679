import $, { Wrapper } from "untrue";

import DocumentContext from "../../../../../../../context/DocumentContext";

function Post({ source }) {
  return source !== null
    ? $("img", {
        class: "w-50 aspect-1/1 object-fit-cover",
        src: source,
      })
    : null;
}

export default Wrapper.wrapContext(Post, DocumentContext, ({ postId }) => {
  const documents = DocumentContext.getDocuments();

  const { figures: postFigureIds, original: originalId } =
    documents.Post[postId];

  let originalFigureIds = null;

  if (originalId !== null) {
    ({ figures: originalFigureIds } = documents.Post[originalId]);
  }

  const figureIds =
    originalFigureIds !== null ? originalFigureIds : postFigureIds;

  let source = null;

  if (figureIds.length > 0) {
    const { media: mediaId } = documents.PostFigure[figureIds[0]];

    const media = documents.Media[mediaId];

    const { poster: posterId } = media;

    const poster = posterId !== null ? documents.Media[posterId] : null;

    const { small: smallId } = poster !== null ? poster : media;

    ({ source } = documents.MediaSize[smallId]);
  }

  return { source };
});
