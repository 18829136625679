import { RequestContext } from "@superbia/untrue";

import RequestHelper from "../helpers/RequestHelper";

import AuthContext from "./AuthContext";
import DocumentContext from "./DocumentContext";

import { client } from "../client";

class AppRequestContext extends RequestContext {
  intercept() {
    return {
      register: {
        data: (key, endpoints, payload, data) => {
          const { register: authResponse } = data;

          AuthContext.login(authResponse);
        },
      },
      login: {
        data: (key, endpoints, payload, data) => {
          const { login: authResponse } = data;

          AuthContext.login(authResponse);
        },
      },
      like: {
        load: (key, endpoints, payload) => {
          const { objectId } = endpoints.like;

          DocumentContext.like(objectId);
        },
      },
      unlike: {
        load: (key, endpoints, payload) => {
          const { objectId } = endpoints.unlike;

          DocumentContext.unlike(objectId);
        },
      },
      markNotificationAsSeen: {
        load: (key, endpoints, payload) => {
          const {
            markNotificationAsSeen: { notificationId },
          } = endpoints;

          DocumentContext.markNotificationAsSeen(notificationId);
        },
      },
    };
  }

  onCreatePost(postId) {
    for (const key in this.requests) {
      const { data } = this.requests[key];

      if (data === null) {
        continue;
      }

      if ("followingPosts" in data) {
        data.followingPosts.data.nodes = [
          postId,
          ...data.followingPosts.data.nodes,
        ];

        this.update();
      }
    }
  }

  onNewMessage(newMessage) {
    const {
      id: eventId,
      message: { id: messageId },
      chat: { id: chatId, user },
    } = newMessage;

    let userId = null;

    if (user !== null) {
      userId = user.id;
    }

    const requestKey = RequestHelper.getChatRequestKey({ chatId, userId });

    for (const key in this.requests) {
      const { data } = this.requests[key];

      if ("chats" in data) {
        data.chats.data.nodes = [
          chatId,
          ...data.chats.data.nodes.filter((nodeId) => nodeId !== chatId),
        ];

        this.update();
      }

      if (
        "messages" in data &&
        key === requestKey &&
        !data.messages.data.nodes.includes(eventId)
      ) {
        data.messages.data.nodes = [
          messageId,
          ...data.messages.data.nodes.filter((nodeId) => nodeId !== messageId),
        ];

        this.update();
      }
    }
  }

  onNewNotification(notification) {
    const { id: notificationId } = notification;

    for (const key in this.requests) {
      const { data } = this.requests[key];

      if ("notifications" in data) {
        data.notifications.data.nodes = [
          notificationId,
          ...data.notifications.data.nodes.filter(
            (nodeId) => nodeId !== notificationId
          ),
        ];

        this.update();
      }
    }
  }

  addTmpMessage(tmpMessage) {
    const { id: tmpId, chat: chatId } = tmpMessage;

    const documents = DocumentContext.getDocuments();

    const chat = documents.Chat[chatId];

    const { user: userId } = chat;

    const key = RequestHelper.getChatRequestKey({ chatId, userId });

    const data = this.requests[key].data.messages.data;

    data.nodes = [tmpId, ...data.nodes];

    this.update();
  }
}

export default new AppRequestContext(client);
