import { Context } from "untrue";

class DropdownOverlayContext extends Context {
  constructor() {
    super();

    this.state = { dropdown: null };
  }

  show(button, items) {
    const rect = button.getBoundingClientRect();

    const top = document.documentElement.scrollTop + rect.bottom;

    const left = rect.right;

    this.updateState({ dropdown: { top, left, items } });
  }

  hide() {
    this.updateState({ dropdown: null });
  }
}

export default new DropdownOverlayContext();
