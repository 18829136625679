"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackItem = void 0;
class StackItem {
    constructor(edge, node) {
        this.edge = edge;
        this.node = node;
    }
}
exports.StackItem = StackItem;
