"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transition = void 0;
const Emitter_1 = require("../Emitter");
const AnimationFrame_1 = require("./AnimationFrame");
class Transition extends Emitter_1.Emitter {
    constructor(animation, finalValue, finalTime, easing) {
        super();
        this.animation = animation;
        this.finalValue = finalValue;
        this.finalTime = finalTime;
        this.easing = easing;
        this.canceled = false;
        this.currentTime = 0;
        this.initialValue = animation.getValue();
    }
    start() {
        if (this.canceled) {
            return;
        }
        const initialCurrentTime = this.currentTime;
        let startTime = null;
        const callback = (rafTime) => {
            if (startTime === null) {
                startTime = rafTime;
            }
            this.currentTime = initialCurrentTime + rafTime - startTime;
            this.update();
            if (this.isTimeUp()) {
                this.end();
                return;
            }
            this.frame = AnimationFrame_1.AnimationFrame.request(callback);
        };
        this.frame = AnimationFrame_1.AnimationFrame.request(callback);
        this.emit("start");
    }
    pause() {
        if (this.canceled) {
            return;
        }
        AnimationFrame_1.AnimationFrame.cancel(this.frame);
        this.emit("pause");
    }
    cancel() {
        if (this.canceled) {
            return;
        }
        this.canceled = true;
        AnimationFrame_1.AnimationFrame.cancel(this.frame);
        this.emit("cancel");
    }
    end() {
        if (this.canceled) {
            return;
        }
        this.currentTime = 0;
        this.emit("end");
    }
    update() {
        let currentValue = 0;
        if (this.currentTime === 0) {
            currentValue = this.initialValue;
        }
        else if (this.isTimeUp()) {
            currentValue = this.finalValue;
        }
        else {
            const totalOffset = this.finalValue - this.initialValue;
            let currentOffset = (this.currentTime * totalOffset) / this.finalTime;
            if (this.easing !== null) {
                const linearRatio = currentOffset / totalOffset;
                const easingRatio = this.easing(linearRatio);
                currentOffset = totalOffset * easingRatio;
            }
            currentValue = this.initialValue + currentOffset;
        }
        this.animation.updateValue(currentValue);
    }
    isTimeUp() {
        return this.currentTime >= this.finalTime;
    }
}
exports.Transition = Transition;
exports.default = Transition;
