import $ from "untrue";

function Loading() {
  return $(
    "div",
    { class: "flex flex-column animate-pulse" },
    [
      "w-120",
      "w-140",
      "w-120",
      "w-150",
      "w-140",
      "w-130",
      "w-170",
      "w-120",
      "w-140",
      "w-180",
      "w-150",
      "w-140",
      "w-130",
      "w-160",
      "w-120",
      "w-170",
      "w-160",
      "w-140",
      "w-120",
      "w-180",
    ].map((size) =>
      $("div", { class: "px-15 py-10 flex align-center" }, [
        $("div", {
          class: "w-40 h-40 rounded-100% bg-color-secondary-20",
        }),
        $("div", {
          class: `ml-15 ${size} h-25 bg-color-secondary-20`,
        }),
      ])
    )
  );
}

export default Loading;
