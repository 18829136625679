class AudioHelper {
  static playAudio(id) {
    const audio = document.getElementById(id);

    audio.pause();

    audio.currentTime = 0;

    audio.play();
  }

  static playMessageCurrentWindow() {
    this.playAudio("message-current-window-audio");
  }

  static playMessageNewWindow() {
    this.playAudio("message-new-window-audio");
  }
}

export default AudioHelper;
