import $, { Component, Wrapper } from "untrue";

import { RequestWrapper } from "@superbia/untrue";

import RequestContext from "../../../context/RequestContext";

import Loading from "./Loading";
import Content from "./Content";
import Error from "./Error";

class Friends extends Component {
  constructor(props) {
    super(props);

    this.on("mount", this.handleMountRequest);
  }

  handleMountRequest = () => {
    this.onRequest();
  };

  onRequest = () => {
    const { requestKey } = this.props;

    RequestContext.request(requestKey, { friends: { limit: 20 } });
  };

  render() {
    const { requestKey, loading, done, error } = this.props;

    return $(
      "div",
      {
        class:
          "fixed w-330 top-0 bottom-0 right-0 bg-color-secondary-15 pt-65 box-shadow-[-2]-[-1]-3 box-shadow-color-blank-0-10%",
      },
      $(
        "div",
        { class: "h-100% flex flex-column py-10 overflow-y-auto scrollable" },
        [
          loading ? $(Loading) : null,
          done ? $(Content, { requestKey }) : null,
          error !== null ? $(Error, { onRetry: this.onRequest }) : null,
        ]
      )
    );
  }
}

export default RequestWrapper.wrapRequester(
  Wrapper.wrapContext(Friends, RequestContext, ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      loading = false,
      done = false,
      error = null,
    } = requests?.[requestKey] ?? {};

    return { loading, done, error };
  })
);
