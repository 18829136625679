import { DocumentContext } from "@superbia/untrue";

import AuthContext from "./AuthContext";

import { client } from "../client";

class AppDocumentContext extends DocumentContext {
  hydrate(documents) {
    this.documents = documents;
  }

  persist() {
    const documents = { User: {}, Media: {}, MediaSize: {} };

    const { isAuth, loggedId } = AuthContext.getState();

    if (isAuth) {
      const user = this.documents.User[loggedId];

      documents.User[loggedId] = user;

      const { picture: pictureId } = user;

      const picture = this.documents.Media[pictureId];

      documents.Media[pictureId] = picture;

      const { small: smallId } = picture;

      const small = this.documents.MediaSize[smallId];

      documents.MediaSize[smallId] = small;
    }

    return documents;
  }

  like(objectId) {
    const posts = this.documents?.Post ?? {};

    if (objectId in posts) {
      const post = posts[objectId];

      post.liked = true;
      post.likesCount++;

      this.update();
    }

    const stories = this.documents?.Story ?? {};

    if (objectId in stories) {
      const story = stories[objectId];

      story.liked = true;
      story.likesCount++;

      this.update();
    }

    const reels = this.documents?.Reel ?? {};

    if (objectId in reels) {
      const reel = reels[objectId];

      reel.liked = true;
      reel.likesCount++;

      this.update();
    }

    const comments = this.documents?.Comment ?? {};

    if (objectId in comments) {
      const comment = comments[objectId];

      comment.liked = true;
      comment.likesCount++;

      this.update();
    }
  }

  unlike(objectId) {
    const posts = this.documents?.Post ?? {};

    if (objectId in posts) {
      const post = posts[objectId];

      post.liked = false;
      post.likesCount--;

      this.update();
    }

    const stories = this.documents?.Story ?? {};

    if (objectId in stories) {
      const story = stories[objectId];

      story.liked = false;
      story.likesCount--;

      this.update();
    }

    const reels = this.documents?.Reel ?? {};

    if (objectId in reels) {
      const reel = reels[objectId];

      reel.liked = false;
      reel.likesCount--;

      this.update();
    }

    const comments = this.documents?.Comment ?? {};

    if (objectId in comments) {
      const comment = comments[objectId];

      comment.liked = false;
      comment.likesCount--;

      this.update();
    }
  }

  markNotificationAsSeen(notificationId) {
    const notification = this.documents.Notification[notificationId];

    notification.seen = true;

    this.update();
  }

  addTmpMessage(tmpMessage) {
    const { id: tmpId, chat: chatId, createdAt } = tmpMessage;

    const chat = this.documents.Chat[chatId];

    chat.latestMessage = tmpId;
    chat.latestMessageAt = createdAt;
  }

  markChatAsReceived(chatId, userId) {
    const { loggedId } = AuthContext.getState();

    const messages = this.documents?.Message ?? {};

    for (const messageId in messages) {
      const message = messages[messageId];

      if (message.chat !== chatId) {
        continue;
      }

      if (!message.users.includes(userId)) {
        continue;
      }

      message.receivedBy = [
        ...message.receivedBy.filter((tmpId) => tmpId !== userId),
        userId,
      ];

      message.received = loggedId === userId;

      message.receivedByAll = message.users.every((tmpId) =>
        message.receivedBy.includes(tmpId)
      );

      this.update();
    }
  }

  markChatAsRead(chatId, userId) {
    const { loggedId } = AuthContext.getState();

    const messages = this.documents?.Message ?? {};
    const chats = this.documents?.Chat ?? {};

    if (chatId in chats && userId === loggedId) {
      const chat = chats[chatId];

      chat.notReadMessagesCount = 0;

      this.update();
    }

    for (const messageId in messages) {
      const message = messages[messageId];

      if (message.chat !== chatId) {
        continue;
      }

      if (!message.users.includes(userId)) {
        continue;
      }

      message.receivedBy = [
        ...message.receivedBy.filter((tmpId) => tmpId !== userId),
        userId,
      ];

      message.received = loggedId === userId;

      message.receivedByAll = message.users.every((tmpId) =>
        message.receivedBy.includes(tmpId)
      );

      message.readBy = [
        ...message.readBy.filter((tmpId) => tmpId !== userId),
        userId,
      ];

      message.read = loggedId === userId;

      message.readByAll = message.users.every((tmpId) =>
        message.readBy.includes(tmpId)
      );

      this.update();
    }
  }

  applyOnline(userId, online, onlineAt) {
    const users = this.documents?.User ?? {};

    if (!(userId in users)) {
      return;
    }

    const user = users[userId];

    user.online = online;
    user.onlineAt = onlineAt;

    this.update();
  }
}

export default new AppDocumentContext(client);
