import { Component } from "untrue";

import DocumentContext from "../../../context/DocumentContext";

import { client } from "../../../client";

class ChatReceivedSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMount);
    this.on("unmount", this.handleUnmount);
  }

  handleMount = () => {
    this.subscription = client.subscribe({ chatReceived: null });

    this.subscription.on("data", (data) => {
      const { chatReceived } = data;

      const { chat: chatId, user: userId } = chatReceived;

      DocumentContext.markChatAsReceived(chatId, userId);
    });
  };

  handleUnmount = () => {
    this.subscription.unsubscribe();
  };
}

export default ChatReceivedSubscriber;
