import $, { Component, Wrapper } from "untrue";

import RequestContext from "../../../../../context/RequestContext";
import DocumentContext from "../../../../../context/DocumentContext";
import SendMessageContext from "../../../../../context/SendMessageContext";

import Scrollable from "../../../../../components/Touch/Scrollable";

import DateWrapper from "./DateWrapper";

import { client } from "../../../../../client";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedId: null, playingId: null };

    this.on("mount", this.handleMountRequest);

    this.on("mount", this.handleMountVisibility);
    this.on("unmount", this.handleUnmountVisibility);

    this.on("mount", this.handleMountMarkAs);

    this.on("update", this.handleUpdateRead);

    this.on("update", this.handleUpdateFocused);
  }

  handleMountRequest = () => {
    const { requestKey, started, chatId } = this.props;

    if (!started) {
      RequestContext.request(requestKey, { messages: { chatId, limit: 30 } });
    }
  };

  handleMountVisibility = () => {
    document.addEventListener("visibilitychange", this.onMarkChatAsRead);
  };

  handleUnmountVisibility = () => {
    document.removeEventListener("visibilitychange", this.onMarkChatAsRead);
  };

  handleMountMarkAs = () => {
    this.onMarkChatAsReceived();
    this.onMarkChatAsRead();
  };

  handleUpdateRead = () => {
    const { focused, latestId, latestReceived, latestSending } = this.props;

    const {
      focused: prevFocused,
      latestId: prevLatestId,
      latestReceived: prevLatestReceived,
      latestSending: prevLatestSending,
    } = this.prevProps;

    if (
      focused !== prevFocused ||
      latestId !== prevLatestId ||
      latestReceived !== prevLatestReceived ||
      latestSending !== prevLatestSending
    ) {
      this.onMarkChatAsRead();
    }
  };

  handleUpdateFocused = () => {
    const { focused } = this.props;
    const { focused: prevFocused } = this.prevProps;

    if (focused !== prevFocused && focused) {
      this.updateState({ selectedId: null });
    }
  };

  onMarkChatAsReceived = () => {
    const { chatId } = this.props;

    const subscription = client.subscribe({ markChatAsReceived: { chatId } });

    subscription.unsubscribe();
  };

  onMarkChatAsRead = () => {
    const { focused, latestReceived, latestSending, chatId } = this.props;

    if (focused && !document.hidden && latestReceived && !latestSending) {
      const subscription = client.subscribe({ markChatAsRead: { chatId } });

      subscription.unsubscribe();
    }
  };

  onSelect = (messageId) => {
    this.updateState({ selectedId: messageId });
  };

  onPlay = (messageId) => {
    this.updateState({ playingId: messageId });
  };

  onPlayEnd = () => {
    const { messageIds, types, senderIds } = this.props;

    const { playingId } = this.state;

    const index = messageIds.indexOf(playingId);

    if (index === 0) {
      this.updateState({ playingId: null });

      return;
    }

    const prevIndex = index - 1;

    const prevMessageId = messageIds[prevIndex];

    const prevType = types[prevIndex];
    const prevSenderId = senderIds[prevIndex];

    const type = types[index];
    const senderId = senderIds[index];

    const newPlayingId =
      prevType === type && prevSenderId === senderId ? prevMessageId : null;

    this.updateState({ playingId: newPlayingId });
  };

  onNext = () => {
    const { requestKey, chatId, nextPageCursor } = this.props;

    RequestContext.load(requestKey, {
      messages: { chatId, limit: 20, cursor: nextPageCursor },
    });
  };

  render() {
    const {
      requestKey,
      chatId,
      loading,
      hasNextPage,
      dates,
      windowId,
      onReply,
    } = this.props;

    const { selectedId, playingId } = this.state;

    return $(
      Scrollable,
      {
        class: "flex-1-1-0 flex flex-column-reverse overflow-y-auto scrollable",
        inverted: true,
        onEndReached: hasNextPage ? this.onNext : undefined,
      },
      [
        dates.map((date) =>
          $(DateWrapper, {
            requestKey,
            chatId,
            date,
            selectedId,
            playingId,
            windowId,
            onSelect: this.onSelect,
            onPlay: this.onPlay,
            onPlayEnd: this.onPlayEnd,
            onReply,
          })
        ),
        loading
          ? $(
              "div",
              {
                class:
                  "flex justify-center color-secondary-40 font-size-20 m-15",
              },
              $("i", { class: "fas fa-circle-notch fa-spin" })
            )
          : null,
      ]
    );
  }
}

export default Wrapper.wrapContext(
  List,
  [RequestContext, DocumentContext, SendMessageContext],
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();
    const documents = DocumentContext.getDocuments();

    const started = requestKey in requests;

    let { data } = requests[requestKey];

    const {
      chat: chatId,
      messages: {
        loading = false,
        data: {
          nodes: messageIds = [],
          hasNextPage = false,
          nextPageCursor = null,
        },
      },
    } = data;

    const dates = messageIds
      .map((messageId) => {
        const { createdAt } = documents.Message[messageId];

        const date = new Date(createdAt);

        const string = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

        return string;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    const messages = messageIds.map(
      (messageId) => documents.Message[messageId]
    );

    const types = messages.map((message) => message.type);
    const senderIds = messages.map((message) => message.sender);

    return {
      started,
      loading,
      chatId,
      messageIds,
      hasNextPage,
      nextPageCursor,
      dates,
      types,
      senderIds,
    };
  },
  ({ chatId, messageIds }) => {
    const documents = DocumentContext.getDocuments();

    const data = SendMessageContext.getData();

    const latestId = messageIds.length > 0 ? messageIds[0] : null;

    const latestReceived =
      latestId !== null ? documents.Message[latestId].received : false;

    let latestSending = false;

    if (chatId in data && latestId in data[chatId]) {
      const { loading, done, error } = data[chatId][latestId];

      latestSending = loading || (!done && error === null);
    }

    return { latestId, latestReceived, latestSending };
  }
);
