import $ from "untrue";

const widths = [`w-2/3`, `w-5/6`, `w-3/4`, `w-100%`];

function Bubble({ justify, width }) {
  return $(
    "div",
    { class: `flex ${justify}` },
    $(
      "div",
      { class: `w-3/4 flex ${justify}` },
      $("div", {
        class: `h-35 ${width} bg-color-secondary-20 animate-pulse`,
      })
    )
  );
}

function Loading() {
  return $(
    "div",
    { class: "flex-1-1-0 flex flex-column-reverse overflow-y-auto scrollable" },
    $("div", { class: "flex flex-column gap-10 p-10" }, [
      $(Bubble, { justify: "justify-start", width: widths[0] }),
      $(Bubble, { justify: "justify-end", width: widths[1] }),
      $(Bubble, { justify: "justify-end", width: widths[1] }),
      $(Bubble, { justify: "justify-start", width: widths[0] }),
      $(Bubble, { justify: "justify-start", width: widths[3] }),
      $(Bubble, { justify: "justify-end", width: widths[2] }),
      $(Bubble, { justify: "justify-start", width: widths[2] }),
      $(Bubble, { justify: "justify-start", width: widths[3] }),
      $(Bubble, { justify: "justify-start", width: widths[1] }),
      $(Bubble, { justify: "justify-end", width: widths[2] }),
      $(Bubble, { justify: "justify-end", width: widths[0] }),
      $(Bubble, { justify: "justify-start", width: widths[3] }),
      $(Bubble, { justify: "justify-start", width: widths[2] }),
      $(Bubble, { justify: "justify-end", width: widths[1] }),
    ])
  );
}

export default Loading;
