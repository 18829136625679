import $, { Component, Ref } from "untrue";

/* 

we have a bug for inverted vertical scrollables: see messages

*/

/*
we need to call event.preventDefault() when we detect a scroll start

so any ancestor's ontouchmove won't get called

in our very specific use case

we have a Scrollable inside a Swipeable



<swipeable>
  <div>
    <scrollable></scrollable>
  </div>

  <div></div>
</swipeable>

so basically what we need to add is that the ontouchmove should check if the scrollable is scrolling, if it is then we stop the propagation.

before that, we want to know the order of the events

what we need to do is that we check if it's scrolling in the desired axis on touchmove

if we are scrolling we call event.preventDefault() and event.stopPropagation() so no ancestors will be fired

if we were already scrolling we also call event.preventDefault() and event.stopPropagation()

but there's a catch

we want to know if an ancestor swipeable or scrollable has catched the touch event

once an ancestor starts scrolling/swiping it's because we don't want the child scrollable/swipeable to scroll/swipe anymore

that's the desired behavior for our very specific use case where we have a vertical scrollable inside an horizontal swipeable

how do we know when an ancestor swipeable has claimed the touch?

to get there, we're comparing first touch to the second touch, which propery changes? clientX or clientY?

that will give us the intention of the user

*/

class Scrollable extends Component {
  constructor(props) {
    super(props);

    this.scrollableRef = new Ref();

    this.waiting = false;
  }

  onScroll = () => {
    const {
      threshold = 400,
      horizontal = false,
      inverted = false,
      onEndReached = null,
    } = this.props;

    const scrollable = this.scrollableRef.current;

    const {
      offsetWidth,
      offsetHeight,
      scrollTop,
      scrollLeft,
      scrollWidth,
      scrollHeight,
    } = scrollable;

    let boolean = false;

    if (horizontal) {
      boolean = inverted
        ? scrollLeft <= (scrollWidth - offsetWidth) * -1 + threshold
        : scrollLeft >= scrollWidth - offsetWidth - threshold;
    } else {
      boolean = inverted
        ? scrollTop <= (scrollHeight - offsetHeight) * -1 + threshold
        : scrollTop >= scrollHeight - offsetHeight - threshold;
    }

    if (boolean) {
      if (!this.waiting) {
        this.waiting = true;

        if (onEndReached !== null) {
          onEndReached();
        }
      }
    } else {
      this.waiting = false;
    }
  };

  render() {
    const { class: className, children } = this.props;

    return $(
      "div",
      { ref: this.scrollableRef, class: className, onscroll: this.onScroll },
      children
    );
  }
}

export default Scrollable;
