import $, { Wrapper } from "untrue";

import RequestContext from "../../../context/RequestContext";
import TranslationContext from "../../../context/TranslationContext";

import Post from "../../../components/Post/Post";

function Content({ requestKey, postIds, hasNextPage, nextPageCursor }) {
  const onNext = () => {
    RequestContext.load(requestKey, {
      followingPosts: { limit: 20, cursor: nextPageCursor },
    });
  };

  return $("div", { class: "flex flex-column gap-20" }, [
    postIds.map((postId) => $(Post, { postId })),
    hasNextPage
      ? $(
          "div",
          { class: "flex-1 flex justify-center mt-30" },
          $(
            "button",
            {
              class:
                "border-solid border-1 border-color-white px-15 py-10 rounded-10",
              onclick: onNext,
            },
            TranslationContext.getData().buttons.loadMore
          )
        )
      : null,
  ]);
}

export default Wrapper.wrapContext(
  Content,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      nodes: postIds,
      hasNextPage,
      nextPageCursor,
    } = requests[requestKey].data.followingPosts.data;

    return { postIds, hasNextPage, nextPageCursor };
  }
);
