import $ from "untrue";

import { Head } from "@untrue/web";

import AppPersistor from "../context/AppPersistor";

import AppTranslator from "./AppTranslator";
import AppRouter from "./AppRouter";

import AppOverlay from "./AppOverlay/AppOverlay";

function App() {
  return [
    $(Head, $("title", "Iconshot")),
    $(AppPersistor.Provider, $(AppTranslator, [$(AppRouter), $(AppOverlay)])),
  ];
}

export default App;
