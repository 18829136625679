import $, { Animation, Component, Ref, Wrapper } from "untrue";

import Helper from "../../../../helpers/Helper";

import DocumentContext from "../../../../context/DocumentContext";

import Button from "./Button";

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
      paused: true,
      hover: true,
      fullScreen: false,
      ended: false,
      elapsed: 0,
    };

    this.containerRef = new Ref();
    this.videoRef = new Ref();
    this.barRef = new Ref();
    this.pointerRef = new Ref();

    this.frame = null;

    this.on("mount", this.handleMountFrame);
    this.on("unmount", this.handleUnmountFrame);
  }

  handleMountFrame = () => {
    const callback = () => {
      const { duration } = this.props;

      const video = this.videoRef.current;
      const bar = this.barRef.current;
      const pointer = this.pointerRef.current;

      if (video === null || bar === null || pointer === null) {
        return;
      }

      const tmpElapsed = Math.ceil(video.currentTime) * 1000;

      this.updateState({ elapsed: tmpElapsed });

      const value = (video.currentTime * 1000) / duration;

      const animation = new Animation(value);

      bar.style.width = animation.interpolate([0, 1], ["0%", "100%"]);

      pointer.style.transform = `translateX(${animation.interpolate(
        [0, 1],
        ["0%", "100%"]
      )})`;

      this.frame = requestAnimationFrame(callback);
    };

    this.frame = requestAnimationFrame(callback);
  };

  handleUnmountFrame = () => {
    cancelAnimationFrame(this.frame);
  };

  onMouseEnter = () => {
    this.updateState({ hover: true });
  };

  onMouseLeave = () => {
    this.updateState({ hover: false });
  };

  onPlay = () => {
    const video = this.videoRef.current;

    this.updateState({ shown: true, paused: false });

    video.play();
  };

  onPause = () => {
    const video = this.videoRef.current;

    this.updateState({ paused: true });

    video.pause();
  };

  onRepeat = () => {
    const video = this.videoRef.current;

    video.currentTime = 0;

    this.updateState({ ended: false });

    this.onPause();

    setTimeout(() => {
      this.onPlay();
    }, 0);

    this.updateState({ hover: false });
  };

  onFullScreenEnter = () => {
    const container = this.containerRef.current;

    this.updateState({ fullScreen: true });

    container.requestFullscreen();
  };

  onFullScreenLeave = () => {
    this.updateState({ fullScreen: false });

    document.exitFullscreen();
  };

  onEnded = () => {
    this.updateState({ hover: true, ended: true });
  };

  onBarClick = (event) => {
    const { duration } = this.props;

    const video = this.videoRef.current;

    const {
      target: { offsetWidth },
      offsetX,
    } = event;

    const time = (offsetX * duration) / offsetWidth / 1000;

    video.currentTime = time;

    return false;
  };

  render() {
    const { paused, hover, fullScreen, ended, shown, elapsed } = this.state;

    const { figureId, duration, source, poster, tagIds, tagsCount } =
      this.props;

    return $(
      "div",
      {
        ref: this.containerRef,
        class: "relative",
        onmouseenter: this.onMouseEnter,
        onmouseleave: this.onMouseLeave,
      },
      [
        $(
          "div",
          {
            class: "relative h-100%",
            onclick: ended
              ? this.onRepeat
              : paused
              ? this.onPlay
              : this.onPause,
            ondblclick: fullScreen
              ? this.onFullScreenLeave
              : this.onFullScreenEnter,
          },
          [
            $(
              "div",
              { class: "flex justify-center" },
              $("img", {
                class: "block max-w-100% object-fit-cover pointer-events-none",
                src: poster,
              })
            ),
            $(
              "div",
              { class: "absolute inset-0 flex justify-center" },
              $(
                "video",
                {
                  ref: this.videoRef,
                  class:
                    "block max-w-100% object-fit-cover pointer-events-none",
                  poster,
                  onended: this.onEnded,
                },
                shown ? $("source", { src: source }) : null
              )
            ),
          ]
        ),
        $(
          "div",
          {
            class: `absolute right-0 left-0 bottom-0 z-10 pt-20 transition-all-300ms ${
              !hover ? "opacity-0% pointer-events-none" : ""
            }`,
          },
          [
            $("div", {
              class:
                "absolute inset-0 z-[-10] bg-gradient-linear bg-gradient-from-color-transparent bg-gradient-to-color-black opacity-30%",
            }),
            $("div", { class: "relative h-10 mx-10 flex align-center" }, [
              $("div", { class: "relative flex-1" }, [
                $("div", { class: "h-3 bg-color-blank-100-50%" }),
                $("div", {
                  ref: this.barRef,
                  class: "absolute top-0 bottom-0 bg-color-primary-100",
                }),
              ]),
              $("div", { class: "absolute inset-0 flex align-center" }, [
                $(
                  "div",
                  { ref: this.pointerRef, class: "flex-1" },
                  $("div", {
                    class: "w-15 h-15 rounded-100% bg-color-primary-100",
                    style: `transform: translateX(-50%)`,
                  })
                ),
                $("div", {
                  class: "absolute inset-0 cursor-pointer",
                  onclick: this.onBarClick,
                }),
              ]),
            ]),
            $("div", { class: "flex justify-between" }, [
              $("div", { class: "flex align-center" }, [
                $(Button, {
                  icon: ended
                    ? "fas fa-redo-alt"
                    : paused
                    ? "fas fa-play"
                    : "fas fa-pause",
                  onClick: ended
                    ? this.onRepeat
                    : paused
                    ? this.onPlay
                    : this.onPause,
                }),
                $(
                  "span",
                  { class: "block font-size-12" },
                  `${Helper.formatTime(elapsed)} /
              ${Helper.formatTime(duration)}`
                ),
              ]),
              $(
                "div",
                {},
                $(Button, {
                  icon: fullScreen ? "fas fa-compress" : "fas fa-expand",
                  onClick: fullScreen
                    ? this.onFullScreenLeave
                    : this.onFullScreenEnter,
                })
              ),
            ]),
          ]
        ),
      ]
    );
  }
}

export default Wrapper.wrapContext(Video, DocumentContext, ({ figureId }) => {
  const documents = DocumentContext.getDocuments();

  const { media: mediaId, tags: tagIds } = documents.PostFigure[figureId];

  const tagsCount = tagIds.length;

  const { duration, source, poster: posterId } = documents.Media[mediaId];

  const { source: poster } = documents.Media[posterId];

  return { duration, source, poster, tagIds, tagsCount };
});
