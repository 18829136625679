import $ from "untrue";

import TranslationContext from "../../../../../../../context/TranslationContext";

function Error({ childSentByLogged }) {
  return $(
    "div",
    {
      class: `rounded-10 ${
        childSentByLogged ? "bg-color-primary-80" : "bg-color-secondary-15"
      }`,
    },
    $(
      "div",
      { class: "px-10 py-5" },
      $(
        "span",
        {
          class: `font-size-14 ${
            childSentByLogged ? "color-white opacity-75%" : "color-secondary-60"
          }`,
        },
        TranslationContext.getData().chats.errors.message.notAvailable
      )
    )
  );
}

export default Error;
