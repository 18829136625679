import $ from "untrue";

import { Head } from "@untrue/web";

import Helper from "../../helpers/Helper";

import TranslationContext from "../../context/TranslationContext";

function DataPolicy() {
  return [
    $(
      Head,
      $(
        "title",
        Helper.getTitle(TranslationContext.getData().titles.dataPolicy)
      )
    ),
    $("main", { class: "container mx-auto p-30" }, [
      $("h1", { class: "font-size-30 font-bold mb-30" }, "Data Policy"),
      $(
        "p",
        "We collect the content, communications and other information you provide when you use our Products, including when you sign up for an account, create or share content, and message or communicate with others. This can include information in or about the content you provide (like metadata), such as the location of a photo or the date a file was created. It can also include what you see through features we provide, such as our camera, so we can do things like suggest masks and filters that you might like, or give you tips on using camera formats. Our systems automatically process content and communications you and others provide to analyze context and what's in them for the purposes described below. Learn more about how you can control who can see the things you share."
      ),
    ]),
  ];
}

export default DataPolicy;
