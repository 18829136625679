import $, { Wrapper } from "untrue";

import RequestContext from "../../../../context/RequestContext";

import Loading from "./Loading";

import List from "./List/List";

function Content({ requestKey, loading, done, focused, windowId, onReply }) {
  if (loading) {
    return $(Loading);
  }

  if (done) {
    return $(List, { requestKey, focused, windowId, onReply });
  }

  return null;
}

export default Wrapper.wrapContext(
  Content,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const { loading = true, done = false } = requests?.[requestKey] ?? {};

    return { loading, done };
  }
);
