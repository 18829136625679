import $, { Component } from "untrue";

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = { hover: false, paused: true, ended: false };
  }

  onMouseEnter = () => {
    this.updateState({ hover: true });
  };

  onMouseLeave = () => {
    this.updateState({ hover: false });
  };

  onPlay = () => {
    const { elementRef } = this.props;

    const element = elementRef.current;

    element.play();

    this.updateState({ paused: false });
  };

  onPause = () => {
    const { elementRef } = this.props;

    const element = elementRef.current;

    element.pause();

    this.updateState({ paused: true });
  };

  onRepeat = () => {
    const { elementRef } = this.props;

    const element = elementRef.current;

    element.currentTime = 0;

    element.play();

    this.updateState({ paused: false, ended: false });
  };

  onEnded = () => {
    this.updateState({ ended: true });
  };

  render() {
    const { elementRef } = this.props;

    const { hover, paused, ended } = this.state;

    return $(
      "div",
      {
        class: "relative",
        onmouseenter: this.onMouseEnter,
        onmouseleave: this.onMouseLeave,
      },
      [
        $("video", {
          ref: elementRef,
          class: "block w-100%",
          onended: this.onEnded,
        }),
        $(
          "div",
          {
            class: `absolute inset-0 flex justify-center align-center ${
              hover ? "opacity-100%" : "opacity-0%"
            } transition-all-300ms`,
          },
          $(
            "button",
            {
              class:
                "w-45 h-45 flex justify-center align-center bg-color-blank-0-75% rounded-100% font-size-20 color-white",
              onclick: ended
                ? this.onRepeat
                : paused
                ? this.onPlay
                : this.onPause,
            },
            $("i", {
              class: ended
                ? "fas fa-undo-alt"
                : paused
                ? "fas fa-play"
                : "fas fa-pause",
            })
          )
        ),
      ]
    );
  }
}

export default Video;
