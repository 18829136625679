import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import DocumentContext from "../context/DocumentContext";

function UserPicture({ permalink, picture, size }) {
  return $(
    "a",
    { href: permalink, onclick: Router.onClick },
    $("div", { class: "relative" }, [
      $("img", {
        class: `block ${size} aspect-1/1 rounded-100% pointer-events-none`,
        src: picture,
      }),
      $("div", {
        class:
          "absolute inset-0 border-1 border-solid border-color-white opacity-10% rounded-100%",
      }),
    ])
  );
}

export default Wrapper.wrapContext(
  UserPicture,
  DocumentContext,
  ({ userId }) => {
    const documents = DocumentContext.getDocuments();

    const { permalink, picture: pictureId } = documents.User[userId];

    const { small: smallId } = documents.Media[pictureId];

    const { source: picture } = documents.MediaSize[smallId];

    return { permalink, picture };
  }
);
