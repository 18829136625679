import $, { Component, Wrapper } from "untrue";

import DropdownOverlayContext from "../../context/DropdownOverlayContext";

class DropdownOverlay extends Component {
  constructor(props) {
    super(props);

    this.on("mount", this.handleMountClick);
    this.on("unmount", this.handleUnmountClick);
  }

  handleMountClick = () => {
    window.addEventListener("click", this.clickListener);
  };

  handleUnmountClick = () => {
    window.removeEventListener("click", this.clickListener);
  };

  clickListener = () => {
    DropdownOverlayContext.hide();
  };

  render() {
    const { dropdown } = this.props;

    const { top, left, items } = dropdown;

    return $(
      "ul",
      {
        class: "dropdown",
        style: `top: ${top}px; left: ${left}px; transform: translateX(-100%)`,
      },
      items.map(({ label, link = null, onClick = null }) =>
        link !== null
          ? $(
              "li",
              $(
                "a",
                {
                  href: link,
                  class: "dropdown-item",
                  onclick: onClick,
                },
                label
              )
            )
          : $("li", { class: "dropdown-item", onclick: onClick }, label)
      )
    );
  }
}

function DropdownOverlayContainer({ dropdown }) {
  if (dropdown === null) {
    return null;
  }

  return $(DropdownOverlay, { dropdown });
}

export default Wrapper.wrapContext(
  DropdownOverlayContainer,
  DropdownOverlayContext,
  () => {
    const { dropdown } = DropdownOverlayContext.getState();

    return { dropdown };
  }
);
