import { Component } from "untrue";

import RequestContext from "../../../context/RequestContext";

import { client } from "../../../client";

class NewNotificationSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMountSubscribe);
    this.on("unmount", this.handleUnmountSubscribe);
  }

  handleMountSubscribe = () => {
    this.subscription = client.subscribe({ newNotification: null });

    this.subscription.on("data", (data) => {
      const { newNotification: notification } = data;

      RequestContext.onNewNotification(notification);
    });
  };

  handleUnmountSubscribe = () => {
    this.subscription.unsubscribe();
  };
}

export default NewNotificationSubscriber;
