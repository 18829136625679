class CookieHelper {
  static fillCookies() {
    if (this.cookies !== null) {
      return;
    }

    const cookies = {};

    const split = document.cookie.split("; ");

    for (const item of split) {
      const [name, value] = item.split("=");

      cookies[name] = value;
    }

    this.cookies = cookies;
  }

  static setCookie(name, value) {
    this.fillCookies();

    document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;

    this.cookies[name] = value;
  }

  static deleteCookie(name) {
    this.fillCookies();

    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    delete this.cookies[name];
  }

  static getCookie(name) {
    this.fillCookies();

    return name in this.cookies ? this.cookies[name] : null;
  }
}

CookieHelper.cookies = null;

export default CookieHelper;
