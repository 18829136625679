import $, { Wrapper } from "untrue";

import { Router } from "@untrue/web";

import DocumentContext from "../../../../../context/DocumentContext";

import UserPicture from "../../../../../components/UserPicture";
import UserName from "../../../../../components/UserName";

function User({ userId, username, onHide }) {
  const onClick = (event) => {
    onHide();

    return Router.onClick(event);
  };

  return $(
    "a",
    {
      class:
        "flex align-center px-15 py-10 transition-all-300ms hover:bg-color-secondary-20",
      href: `/${username}`,
      onclick: onClick,
    },
    [
      $(UserPicture, { userId, size: "w-45" }),
      $("div", { class: "flex-1 ml-15" }, [
        $(UserName, { userId }),
        $("span", { class: "color-secondary-60 font-size-14" }, `@${username}`),
      ]),
    ]
  );
}

export default Wrapper.wrapContext(User, DocumentContext, ({ userId }) => {
  const documents = DocumentContext.getDocuments();

  const { username } = documents.User[userId];

  return { username };
});
