import $ from "untrue";

function Image({ source }) {
  return $("img", {
    src: source,
    class: "block w-100% h-100% object-fit-contain",
  });
}

export default Image;
