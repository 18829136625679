import $, { Component } from "untrue";

class Error extends Component {
  constructor(props) {
    super(props);

    const { error } = props;

    this.state = { error };

    this.on("update", this.handleUpdateError);
  }

  handleUpdateError = () => {
    const { error } = this.props;
    const { error: prevError } = this.prevProps;

    if (error !== prevError && error !== null) {
      this.updateState({ error });
    }
  };

  render() {
    const { shown } = this.props;

    const { error } = this.state;

    return $(
      "div",
      {
        class:
          "absolute bottom-0 right-0 left-0 p-15 flex justify-center overflow-hidden",
      },
      $(
        "div",
        {
          class: `bg-color-red-90 color-white px-20 py-10 rounded-32 ${
            !shown ? "translate-y-200%" : ""
          } transition-all-300ms`,
        },
        error !== null ? error.message : ""
      )
    );
  }
}

export default Error;
