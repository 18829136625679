const testingProduction = false;

const isDev = !testingProduction && process.env.NODE_ENV !== "production";

const devDomain = "192.168.1.14";
const domain = "www.iconshot.com";

export default {
  testingProduction,
  apiUrl: isDev ? `http://${devDomain}:50001` : `https://${domain}/api`,
  apiWsUrl: isDev ? `ws://${devDomain}:50001` : `wss://${domain}/api`,
  webUrl: isDev ? `http://${devDomain}:50000` : `https://${domain}`,
  playStorePackageName: "com.iconshot",
  appStoreAppId: "iconshot/id1645138695",
};
