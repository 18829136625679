import { Context } from "untrue";

class ChatRecordingContext extends Context {
  constructor() {
    super();

    this.data = {};
  }

  getData() {
    return this.data;
  }

  insert(chatRecording) {
    const {
      id: chatRecordingId,
      chat: chatId,
      user: { id: userId },
      recording,
      startedAt,
    } = chatRecording;

    const items = chatId in this.data ? this.data[chatId] : [];

    const itemIds = items.map((item) => item.id);

    const newItems = [];

    if (recording) {
      if (itemIds.includes(chatRecordingId)) {
        return;
      }

      const newItem = { id: chatRecordingId, user: userId, startedAt };

      newItems.push(...items, newItem);
    } else {
      newItems.push(...items.filter((item) => item.id !== chatRecordingId));
    }

    const sortItems = newItems.sort((a, b) => {
      const dateA = new Date(a.startedAt);
      const dateB = new Date(b.startedAt);

      return dateA - dateB;
    });

    this.data[chatId] = sortItems;

    this.update();
  }

  clear(chatId) {
    delete this.data[chatId];

    this.update();
  }
}

export default new ChatRecordingContext();
