import { Component } from "untrue";

import { client } from "../../../client";

class ChatUpdateSubscriber extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;

    this.on("mount", this.handleMount);
    this.on("unmount", this.handleUnmount);
  }

  handleMount = () => {
    this.subscription = client.subscribe({ chatUpdate: null });
  };

  handleUnmount = () => {
    this.subscription.unsubscribe();
  };
}

export default ChatUpdateSubscriber;
