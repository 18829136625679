import $, { Wrapper } from "untrue";

import RequestContext from "../../../../../context/RequestContext";

import Notification from "./Notification/Notification";

function Content({ loading, notificationIds, onTab }) {
  return [
    $(
      "div",
      notificationIds.map((notificationId) =>
        $(Notification, { notificationId, onTab })
      )
    ),
    loading
      ? $(
          "div",
          { class: "flex justify-center font-size-20 color-secondary-60" },
          $("i", { class: "fas fa-circle-notch fa-spin" })
        )
      : null,
  ];
}

export default Wrapper.wrapContext(
  Content,
  RequestContext,
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    const {
      loading,
      data: { nodes: notificationIds },
    } = requests[requestKey].data.notifications;

    return { loading, notificationIds };
  }
);
