import $, { Wrapper } from "untrue";

import Helper from "../../../../../../helpers/Helper";

import DocumentContext from "../../../../../../context/DocumentContext";
import ViewMediaModalContext from "../../../../../../context/ViewMediaModalContext";

import RichText from "../../../../../../components/RichText";

function MessageMedia({
  type,
  text,
  source,
  smallSource,
  smallPoster,
  width,
  height,
  duration,
  mentionIds,
  sentByLogged,
  ParentNode,
  DropdownNode,
}) {
  const onClick = () => {
    ViewMediaModalContext.view({ source, type, duration });
  };

  const ratio = width / height;

  return $(
    "div",
    {
      class: `message relative w-70% ${
        sentByLogged ? "bg-color-primary-90" : "bg-color-secondary-20"
      } rounded-10`,
    },
    [
      ParentNode,
      $("div", { class: "p-5" }, [
        text !== null
          ? $(RichText, {
              style: "px-5 pb-5",
              linkStyle: "font-bold",
              text,
              mentionIds,
            })
          : null,
        $(
          "div",
          {
            class: "message-media relative hover:cursor-pointer",
            style: `aspect-ratio: ${Math.min(4 / 3, Math.max(ratio, 3 / 4))}`,
            onclick: onClick,
          },
          [
            $("img", {
              class: "block w-100% h-100% object-fit-cover rounded-10",
              src: type === "image" ? smallSource : smallPoster,
            }),
            type === "video"
              ? [
                  $(
                    "div",
                    {
                      class:
                        "absolute inset-0 flex justify-center align-center",
                    },
                    $("div", { class: "relative" }, [
                      $("div", {
                        class:
                          "absolute inset-0 rounded-100% bg-color-black opacity-50% [hover:message-media]:bg-color-primary-100 [hover:message-media]:opacity-100 [hover:message-media]:scale-1.1 transition-300ms",
                      }),
                      $(
                        "div",
                        {
                          class:
                            "relative w-50 h-50 flex justify-center align-center font-size-24 [hover:message-media]:scale-0.9 transition-300ms",
                        },
                        $("i", { class: "fas fa-play" })
                      ),
                    ])
                  ),
                  $("div", { class: "absolute bottom-0 left-0 right-0" }, [
                    $("div", {
                      class:
                        "absolute inset-0 bg-gradient-linear bg-gradient-from-color-transparent bg-gradient-to-color-black opacity-50% rounded-b-10",
                    }),
                    $(
                      "div",
                      {
                        class:
                          "relative px-10 pt-50 pb-5 flex align-center color-white font-size-12",
                      },
                      [
                        $("i", { class: "fas fa-video" }),
                        $(
                          "span",
                          { class: "font-bold ml-5" },
                          Helper.formatTime(duration)
                        ),
                      ]
                    ),
                  ]),
                ]
              : null,
          ]
        ),
      ]),
      DropdownNode,
    ]
  );
}

export default Wrapper.wrapContext(
  MessageMedia,
  DocumentContext,
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    const {
      type,
      text,
      media: mediaId,
      mentions: mentionIds,
    } = documents.Message[messageId];

    const {
      source,
      duration,
      width,
      height,
      small: smallId,
      poster: posterId,
    } = documents.Media[mediaId];

    const { source: smallSource } = documents.MediaSize[smallId];

    let poster = null;
    let smallPoster = null;

    if (posterId !== null) {
      const { source, small: smallId } = documents.Media[posterId];

      poster = source;

      ({ source: smallPoster } = documents.MediaSize[smallId]);
    }

    return {
      type,
      text,
      source,
      poster,
      smallSource,
      smallPoster,
      width,
      height,
      duration,
      mentionIds,
    };
  }
);
