import $, { Wrapper } from "untrue";

import Helper from "../../../../../../../helpers/Helper";

import TranslationContext from "../../../../../../../context/TranslationContext";
import DocumentContext from "../../../../../../../context/DocumentContext";

import UserPictureBorder from "../../../../../../../components/UserPictureBorder";
import UserName from "../../../../../../../components/UserName";

function Content({
  childSentByLogged,
  action,
  type,
  text,
  source,
  duration,
  senderId,
  mentionIds,
  mentionNames,
}) {
  const isUnsent = action === "unsent";

  const isImageOrVideo = ["image", "video"].includes(type);

  let icon = null;

  let contentText = null;

  if (isUnsent) {
    icon = "fa-ban";
  } else {
    switch (type) {
      case "image":
        icon = "fa-image";
        break;

      case "video":
        icon = "fa-video";
        break;

      case "voice":
        icon = "fa-microphone";
        break;

      case "audio":
        icon = "fa-music";
        break;
    }
  }

  if (isUnsent) {
    contentText = TranslationContext.getData().chats.messages.unsent;
  } else {
    if (text !== null) {
      contentText = mentionIds.reduce((value, mentionId, index) => {
        return value.replace(`@${mentionId}`, `@${mentionNames[index]}`);
      }, text);
    } else {
      switch (type) {
        case "image": {
          contentText = TranslationContext.getData().chats.photo;

          break;
        }

        case "video": {
          contentText = `${
            TranslationContext.getData().chats.video
          } (${Helper.formatTime(duration)})`;

          break;
        }

        case "voice": {
          contentText = Helper.formatTime(duration);

          break;
        }

        case "audio": {
          contentText = TranslationContext.getData().chats.audio;

          break;
        }
      }
    }
  }

  return $(
    "div",
    {
      class: `rounded-10 flex ${
        childSentByLogged ? "bg-color-primary-80" : "bg-color-secondary-15"
      }`,
    },
    [
      $("div", { class: "px-10 py-5 flex-1 min-w-0" }, [
        $("div", { class: "flex align-center" }, [
          $(UserPictureBorder, { userId: senderId, size: "w-20" }),
          $(
            "div",
            { class: "ml-10" },
            $(UserName, {
              userId: senderId,
              class: `font-size-14 font-bold text-truncate ${
                childSentByLogged
                  ? "color-white opacity-75%"
                  : "color-secondary-60"
              }`,
            })
          ),
        ]),
        $(
          "div",
          { class: "mt-5" },
          icon === null
            ? $(
                "p",
                {
                  class: `font-size-14 ${
                    childSentByLogged
                      ? "color-white opacity-75%"
                      : "color-secondary-60"
                  } message-footer-parent-content`,
                },
                contentText
              )
            : $("div", { class: "flex align-center" }, [
                $(
                  "div",
                  {
                    class: `font-size-12 ${
                      childSentByLogged
                        ? "color-white opacity-75%"
                        : "color-secondary-60"
                    }`,
                  },
                  $("i", { class: `fas ${icon}` })
                ),
                $(
                  "span",
                  {
                    class: `ml-5 text-truncate font-size-14 ${
                      childSentByLogged
                        ? "color-white opacity-75%"
                        : "color-secondary-60"
                    }`,
                  },
                  contentText
                ),
              ])
        ),
      ]),
      isImageOrVideo
        ? $(
            "div",
            { class: "w-65 h-65" },
            $("img", {
              class: "w-100% h-100% object-fit-cover rounded-r-10",
              src: source,
            })
          )
        : null,
    ]
  );
}

export default Wrapper.wrapContext(Content, DocumentContext, ({ parentId }) => {
  const documents = DocumentContext.getDocuments();

  const {
    action,
    type,
    text,
    media: mediaId,
    mentions: mentionIds,
    sender: senderId,
  } = documents.Message[parentId];

  let source = null;
  let duration = null;

  if (mediaId !== null) {
    const media = documents.Media[mediaId];

    const { poster: posterId } = media;

    const poster = posterId !== null ? documents.Media[posterId] : null;

    const { small: smallId } = poster !== null ? poster : media;

    if (smallId !== null) {
      ({ source } = documents.MediaSize[smallId]);
    }

    ({ duration } = media);
  }

  const mentionNames = mentionIds.map(
    (mentionId) => documents.User[mentionId].name
  );

  return {
    action,
    type,
    text,
    source,
    duration,
    senderId,
    mentionIds,
    mentionNames,
  };
});
