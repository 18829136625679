import $, { Wrapper } from "untrue";

import DocumentContext from "../../context/DocumentContext";

import UserPicture from "../../components/UserPicture";
import UserName from "../../components/UserName";

function Header({ userId, username }) {
  return $("div", { class: "flex flex-column align-center" }, [
    $("div", { class: "mb-20" }, $(UserPicture, { userId, size: "w-120" })),
    $(UserName, { userId }),
    $("span", { class: "block font-size-14 color-secondary-60" }, username),
  ]);
}

export default Wrapper.wrapContext(Header, DocumentContext, ({ userId }) => {
  const documents = DocumentContext.getDocuments();

  const { username } = documents.User[userId];

  return { username };
});
