import $, { Animation, Component, Ref, Wrapper } from "untrue";

import Helper from "../../../../../../helpers/Helper";

import DocumentContext from "../../../../../../context/DocumentContext";

import UserPictureBorder from "../../../../../../components/UserPictureBorder";

import { client } from "../../../../../../client";

class MessageVoice extends Component {
  constructor(props) {
    super(props);

    this.state = { paused: false, elapsed: 0 };

    this.audioRef = new Ref();
    this.barRef = new Ref();
    this.pointerRef = new Ref();

    this.frame = null;

    this.on("mount", this.handleMountFrame);
    this.on("unmount", this.handleUnmountFrame);

    this.on("update", this.handleUpdateAudio);
    this.on("update", this.handleUpdateReset);
    this.on("update", this.handleUpdatePlayed);
  }

  handleMountFrame = () => {
    const callback = () => {
      const { duration } = this.props;

      const { elapsed } = this.state;

      const audio = this.audioRef.current;
      const bar = this.barRef.current;
      const pointer = this.pointerRef.current;

      if (audio === null || bar === null || pointer === null) {
        return;
      }

      const tmpElapsed = Math.ceil(audio.currentTime) * 1000;

      if (tmpElapsed !== elapsed) {
        this.updateState({ elapsed: tmpElapsed });
      }

      const value = (audio.currentTime * 1000) / duration;

      const animation = new Animation(value);

      bar.style.width = animation.interpolate([0, 1], ["0%", "100%"]);

      pointer.style.transform = `translateX(${animation.interpolate(
        [0, 1],
        ["0%", "100%"]
      )})`;

      this.frame = requestAnimationFrame(callback);
    };

    this.frame = requestAnimationFrame(callback);
  };

  handleUnmountFrame = () => {
    cancelAnimationFrame(this.frmae);
  };

  handleUpdateAudio = () => {
    const { playing } = this.props;

    const { playing: prevPlaying } = this.prevProps;

    const { paused } = this.state;

    const { paused: prevPaused } = this.prevState;

    const audio = this.audioRef.current;

    if (playing !== prevPlaying || paused !== prevPaused) {
      if (this.isPlaying()) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  };

  handleUpdateReset = () => {
    const { playing } = this.props;

    const { playing: prevPlaying } = this.prevProps;

    if (playing !== prevPlaying && !playing) {
      this.onReset();
    }
  };

  handleUpdatePlayed = () => {
    const { playing, messageId } = this.props;

    const { playing: prevPlaying } = this.prevProps;

    if (playing !== prevPlaying && playing) {
      const subscription = client.subscribe({
        markMessageAsPlayed: { messageId },
      });

      subscription.unsubscribe();
    }
  };

  isPlaying() {
    const { playing } = this.props;

    const { paused } = this.state;

    return playing && !paused;
  }

  onToggle = () => {
    const { playing, onPlay } = this.props;

    const { paused } = this.state;

    if (!playing) {
      onPlay();
    } else {
      this.updateState({ paused: !paused });
    }
  };

  onEnded = () => {
    const { onPlayEnd } = this.props;

    this.onReset();

    onPlayEnd();
  };

  onReset() {
    const audio = this.audioRef.current;

    audio.currentTime = 0;
  }

  render() {
    const {
      senderId,
      playing,
      loading,
      sentByLogged,
      source,
      duration,
      ParentNode,
      DropdownNode,
    } = this.props;

    const { elapsed } = this.state;

    const isPlaying = this.isPlaying();

    return $(
      "div",
      {
        class: `message relative w-75% ${
          sentByLogged ? "bg-color-primary-90" : "bg-color-secondary-20"
        } rounded-10`,
      },
      [
        ParentNode,
        $("audio", {
          ref: this.audioRef,
          src: source,
          onended: this.onEnded,
        }),
        $("div", { class: "p-5 pr-10 flex overflow-hidden" }, [
          $("div", { class: "relative" }, [
            $(UserPictureBorder, { userId: senderId, size: "w-55" }),
            $(
              "div",
              {
                class: `absolute bottom-0 right-[-10] w-30 h-30 rounded-100% flex justify-center align-center text-white text-14 ${
                  sentByLogged ? "bg-color-primary-90" : "bg-color-secondary-20"
                }`,
              },
              $("i", { class: "fas fa-microphone" })
            ),
          ]),
          $(
            "div",
            { class: "flex align-center" },
            $(
              "button",
              {
                class:
                  "w-45 h-45 flex justify-center align-center font-size-18",
                onclick: this.onToggle,
              },
              loading
                ? $("i", { class: "fas fa-circle-notch fa-spin" })
                : $("i", {
                    class: `fas ${isPlaying ? "fa-pause" : "fa-play"}`,
                  })
            )
          ),
          $("div", { class: "relative flex-1 flex align-center" }, [
            $("div", { class: "relative flex-1" }, [
              $("div", {
                class: "flex-1 h-2 bg-color-white opacity-25 rounded-4",
              }),
              $("div", {
                ref: this.barRef,
                class: "absolute top-0 bottom-0 bg-color-white rounded-4",
              }),
            ]),
            $(
              "div",
              { class: "absolute inset-0 flex align-center" },
              $(
                "div",
                { ref: this.pointerRef, class: "w-100%" },
                $("div", {
                  class: "w-15 h-15 rounded-100% bg-color-white",
                  style: "transform: translateX(-50%)",
                })
              )
            ),
            !loading
              ? $(
                  "div",
                  { class: "absolute bottom-0 left-0 mb-[-5]" },
                  $(
                    "span",
                    { class: "font-size-14 color-white" },
                    Helper.formatTime(playing ? elapsed : duration)
                  )
                )
              : null,
          ]),
        ]),
        DropdownNode,
      ]
    );
  }
}

export default Wrapper.wrapContext(
  MessageVoice,
  DocumentContext,
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    const { sender: senderId, media: mediaId } = documents.Message[messageId];

    const { source, duration } = documents.Media[mediaId];

    return { senderId, source, duration };
  }
);
