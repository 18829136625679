import $ from "untrue";

import ReelThumbnail from "../../../../../../../components/ReelThumbnail";

function Reel({ reelId }) {
  return $(
    "div",
    { class: "w-50" },
    $(ReelThumbnail, { reelId, showViews: false })
  );
}

export default Reel;
