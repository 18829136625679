import { Context } from "untrue";

import { initClient } from "../client";

const languages = ["en", "es"];

class TranslationContext extends Context {
  constructor() {
    super();

    this.loading = false;
    this.done = false;
    this.error = null;

    this.language = (navigator.language || navigator.userLanguage) ?? null;

    this.data = null;
  }

  hydrate(language) {
    this.language = language;
  }

  persist() {
    return this.language;
  }

  getLanguage() {
    return this.language;
  }

  isDone() {
    return this.done;
  }

  getData() {
    return this.data;
  }

  async load() {
    if (typeof this.language === "string") {
      const substr = this.language.substring(0, 2);

      if (languages.includes(substr)) {
        this.language = substr;
      } else {
        this.language = null;
      }
    } else {
      this.language = null;
    }

    if (this.language === null) {
      this.language = "en";
    }

    this.loading = true;

    this.update();

    try {
      const response = await fetch(`/json/translation/${this.language}.json`);

      const data = await response.json();

      this.data = data;

      this.done = true;

      initClient();
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;

      this.update();
    }
  }
}

export default new TranslationContext();
