import $, { Wrapper } from "untrue";

import AuthContext from "../../../../../../context/AuthContext";
import DocumentContext from "../../../../../../context/DocumentContext";
import ChatTypingContext from "../../../../../../context/ChatTypingContext";
import ChatRecordingContext from "../../../../../../context/ChatRecordingContext";
import SendMessageContext from "../../../../../../context/SendMessageContext";
import TranslationContext from "../../../../../../context/TranslationContext";

import Helper from "../../../../../../helpers/Helper";

function Message({
  sent,
  loading,
  done,
  error,
  loggedId,
  action,
  type,
  text,
  duration,
  receivedByAll,
  readByAll,
  senderId,
  senderName,
  participantIds,
  participantNames,
  mentionIds,
  mentionNames,
  group,
  typingName,
  recordingName,
}) {
  const sentByLogged = loggedId === senderId;

  const hasError = error !== null;

  const showLoading = loading || (sent && !done && !hasError);

  const isSentOrUnsent = ["sent", "unsent"].includes(action);

  let italicText = null;
  let contentText = null;
  let suffixText = null;
  let icon = null;

  if (typingName !== null) {
    italicText = group
      ? TranslationContext.getData().chats.typing.group.replace(
          "%name%",
          typingName
        )
      : TranslationContext.getData().chats.typing.user;
  } else if (recordingName !== null) {
    italicText = group
      ? TranslationContext.getData().chats.recording.group.replace(
          "%name%",
          recordingName
        )
      : TranslationContext.getData().chats.recording.user;
  } else {
    switch (action) {
      case null: {
        contentText = "";

        break;
      }

      case "unsent": {
        icon = "fa-ban";

        contentText = TranslationContext.getData().chats.messages.unsent;

        break;
      }

      case "sent": {
        if (text !== null) {
          contentText = mentionIds.reduce((value, mentionId, index) => {
            return value.replaceAll(`@${mentionId}`, `${mentionNames[index]}`);
          }, text);
        } else {
          switch (type) {
            case "image":
              contentText = TranslationContext.getData().chats.photo;
              break;

            case "video":
              contentText = TranslationContext.getData().chats.video;
              break;

            case "voice":
              contentText = Helper.formatTime(duration);
              break;

            case "audio":
              contentText = TranslationContext.getData().chats.audio;
              break;
          }
        }

        switch (type) {
          case "image":
            icon = "fa-image";
            break;

          case "video":
            icon = "fa-video";
            break;

          case "voice":
            icon = "fa-microphone";
            break;

          case "audio":
            icon = "fa-music";
            break;
        }

        break;
      }

      default: {
        switch (participantIds.length) {
          case 0: {
            contentText = TranslationContext.getData().chats.messages[action];

            break;
          }

          case 1: {
            const firstId = participantIds[0];

            contentText =
              firstId === loggedId
                ? TranslationContext.getData().chats.messages[action].first
                : TranslationContext.getData().chats.messages[
                    action
                  ].none.replace("%username_0%", `@${participantIds[0]}`);

            break;
          }

          case 2: {
            const firstId = participantIds[0];
            const secondId = participantIds[1];

            if (firstId === loggedId) {
              contentText = TranslationContext.getData().chats.messages[
                action
              ].first.replace("%username_1%", `@${participantIds[1]}`);
            } else if (secondId === loggedId) {
              contentText = TranslationContext.getData().chats.messages[
                action
              ].second.replace("%username_0%", `@${participantIds[0]}`);
            } else {
              contentText = TranslationContext.getData()
                .chats.messages[action].none.replace(
                  "%username_0%",
                  `@${participantIds[0]}`
                )
                .replace("%username_1%", `@${participantIds[1]}`);
            }

            break;
          }
        }

        contentText = participantIds.reduce((value, participantId, index) => {
          return value.replace(`@${participantId}`, participantNames[index]);
        }, contentText);

        break;
      }
    }

    if (group && isSentOrUnsent) {
      suffixText =
        senderId === loggedId
          ? `${TranslationContext.getData().chats.you}:`
          : `${senderName}:`;
    }
  }

  return $(
    "div",
    {
      class:
        "flex-1 min-w-0 flex align-center color-secondary-60 font-size-16 h-30",
    },
    [
      $("div", { class: "flex align-center" }, [
        italicText !== null
          ? $("span", { class: "flex-1 italic" }, italicText)
          : sentByLogged
          ? $(
              "div",
              {
                class: "w-25 h-25 flex justify-start align-center font-size-14",
              },
              showLoading
                ? $("i", { class: "fas fa-circle-notch fa-spin" })
                : $(
                    "span",
                    {
                      class: hasError
                        ? "color-red-100"
                        : readByAll
                        ? "color-[cornflower-blue]-100"
                        : "color-secondary-60",
                    },
                    $("i", {
                      class: `${!hasError && receivedByAll ? "fas" : "far"} ${
                        hasError ? "fa-circle-xmark" : "fa-check-circle"
                      }`,
                    })
                  )
            )
          : null,
        suffixText !== null ? $("span", { class: "mr-5" }, suffixText) : null,
        icon !== null
          ? $(
              "span",
              { class: "mr-5 font-size-14" },
              $("i", { class: `fas ${icon}` })
            )
          : null,
      ]),
      contentText !== null
        ? $(
            "span",
            { class: "overflow-hidden white-space-nowrap text-ellipsis" },
            contentText
          )
        : null,
    ]
  );
}

export default Wrapper.wrapContext(
  Message,
  [
    AuthContext,
    ChatTypingContext,
    ChatRecordingContext,
    SendMessageContext,
    DocumentContext,
    SendMessageContext,
  ],
  () => {
    const { loggedId } = AuthContext.getState();

    return { loggedId };
  },
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    const typingData = ChatTypingContext.getData();
    const recordingData = ChatRecordingContext.getData();

    let typing = null;
    let recording = null;

    let typingDate = null;
    let recordingDate = null;

    let typingId = null;
    let recordingId = null;

    let typingName = null;
    let recordingName = null;

    if (chatId in typingData) {
      typing = typingData[chatId];
    }

    if (chatId in recordingData) {
      const items = recordingData[chatId];

      if (items.length > 0) {
        recording = items[items.length - 1];
      }
    }

    if (typing !== null) {
      typingDate = new Date(typing.startedAt);
    }

    if (recording !== null) {
      recordingDate = new Date(recording.startedAt);
    }

    if (typing !== null) {
      typingId = typing.user;
    }

    if (recording !== null && (typing === null || recordingDate > typingDate)) {
      typingId = null;
      recordingId = recording.user;
    }

    if (typingId !== null) {
      typingName = documents.User[typingId].name;
    }

    if (recordingId !== null) {
      recordingName = documents.User[recordingId].name;
    }

    return { typingName, recordingName };
  },
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    const { latestMessage: messageId } = documents.Chat[chatId];

    return { messageId };
  },
  ({ chatId, messageId: tmpId }) => {
    const data = SendMessageContext.getData();

    let messageId = tmpId;

    let loading = false;
    let done = false;
    let error = null;

    let sent = false;

    if (chatId in data && tmpId in data[chatId]) {
      const item = data[chatId][tmpId];

      ({ loading, done, error } = item);

      if (done) {
        messageId = item.messageId;
      }

      sent = true;
    }

    return { sent, loading, done, error, messageId };
  },
  ({ messageId }) => {
    const documents = DocumentContext.getDocuments();

    let action = null;
    let type = null;
    let text = null;
    let duration = null;
    let receivedByAll = false;
    let readByAll = false;
    let senderId = null;
    let senderName = null;
    let participantIds = [];
    let mentionIds = [];
    let chatId = null;
    let group = false;

    if (messageId !== null) {
      const message = documents.Message[messageId];

      ({
        action,
        type,
        text,
        receivedByAll,
        readByAll,
        sender: senderId,
        chat: chatId,
        participants: participantIds,
        mentions: mentionIds,
      } = message);

      const { media: mediaId } = message;

      if (mediaId !== null) {
        ({ duration } = documents.Media[mediaId]);
      }
    }

    if (chatId !== null) {
      ({ group } = documents.Chat[chatId]);
    }

    if (senderId !== null) {
      ({ name: senderName } = documents.User[senderId]);
    }

    const participantNames = participantIds.map((userId) => {
      const { name } = documents.User[userId];

      return name;
    });

    const mentionNames = mentionIds.map((mentionId) => {
      const { name } = documents.User[mentionId];

      return name;
    });

    return {
      action,
      type,
      text,
      duration,
      receivedByAll,
      readByAll,
      senderId,
      senderName,
      participantIds,
      participantNames,
      mentionIds,
      mentionNames,
      group,
    };
  }
);
