import $, { Wrapper } from "untrue";

import ChatWindowContext from "../../../context/ChatWindowContext";

import Chat from "./Chat/Chat";

function Chats({ ids }) {
  return $(
    "div",
    { class: "fixed bottom-0 right-0 left-0 pr-330 pointer-events-none" },
    $(
      "div",
      { class: "px-5 flex justify-end" },
      ids.map((id, index) => $(Chat, { key: id, windowId: id, index }))
    )
  );
}

export default Wrapper.wrapContext(Chats, ChatWindowContext, () => {
  const windows = ChatWindowContext.getWindows();

  const ids = windows.map((window) => window.id);

  return { ids };
});
