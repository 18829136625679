import $, { Component, Ref } from "untrue";

class Scrollable extends Component {
  constructor(props) {
    super(props);

    this.scrollableRef = new Ref();

    this.waiting = false;
  }

  onScroll = () => {
    const {
      threshold = 400,
      horizontal = false,
      inverted = false,
      onEndReached = null,
    } = this.props;

    const scrollable = this.scrollableRef.current;

    const {
      offsetWidth,
      offsetHeight,
      scrollTop,
      scrollLeft,
      scrollWidth,
      scrollHeight,
    } = scrollable;

    let boolean = false;

    if (horizontal) {
      boolean = inverted
        ? scrollLeft <= (scrollWidth - offsetWidth) * -1 + threshold
        : scrollLeft >= scrollWidth - offsetWidth - threshold;
    } else {
      boolean = inverted
        ? scrollTop <= (scrollHeight - offsetHeight) * -1 + threshold
        : scrollTop >= scrollHeight - offsetHeight - threshold;
    }

    if (boolean) {
      if (!this.waiting) {
        this.waiting = true;

        if (onEndReached !== null) {
          onEndReached();
        }
      }
    } else {
      this.waiting = false;
    }
  };

  render() {
    const { className, children } = this.props;

    return $(
      "div",
      { ref: this.scrollableRef, class: className, onscroll: this.onScroll },
      children
    );
  }
}

export default Scrollable;
