import { Component } from "untrue";

import { client } from "../../../client";

class ChatsReceivedSubscriber extends Component {
  constructor(props) {
    super(props);

    this.on("mount", this.handleMount);
  }

  handleMount = () => {
    const subscription = client.subscribe({ markChatsAsReceived: null });

    subscription.unsubscribe();
  };
}

export default ChatsReceivedSubscriber;
