import $, { Wrapper } from "untrue";

import Helper from "../../../../../helpers/Helper";

import DocumentContext from "../../../../../context/DocumentContext";
import TranslationContext from "../../../../../context/TranslationContext";

import UserPictureBorder from "../../../../../components/UserPictureBorder";
import UserName from "../../../../../components/UserName";

function Parent({
  action,
  type,
  text,
  source,
  duration,
  senderId,
  mentionIds,
  mentionNames,
  onDismissReply,
}) {
  const isUnsent = action === "unsent";

  let icon = null;

  let contentText = null;

  if (isUnsent) {
    icon = "fa-ban";
  } else {
    switch (type) {
      case "image":
        icon = "fa-image";
        break;

      case "video":
        icon = "fa-video";
        break;

      case "voice":
        icon = "fa-microphone";
        break;

      case "audio":
        icon = "fa-music";
        break;
    }
  }

  if (isUnsent) {
    contentText = TranslationContext.getData().chats.messages.unsent;
  } else {
    if (text !== null) {
      contentText = mentionIds.reduce((value, mentionId, index) => {
        return value.replace(`@${mentionId}`, `${mentionNames[index]}`);
      }, text);
    } else {
      switch (type) {
        case "image": {
          contentText = TranslationContext.getData().chats.photo;

          break;
        }

        case "video": {
          contentText = `${
            TranslationContext.getData().chats.video
          } (${Helper.formatTime(duration)})`;

          break;
        }

        case "voice": {
          contentText = Helper.formatTime(duration);

          break;
        }

        case "audio": {
          contentText = TranslationContext.getData().chats.audio;

          break;
        }
      }
    }
  }

  const isImageOrVideo = ["image", "video"].includes(type);

  return $("div", { class: "relative bg-color-secondary-20 flex" }, [
    $("div", { class: "px-15 py-10 flex-1 min-w-0" }, [
      $("div", { class: "flex align-center" }, [
        $(UserPictureBorder, { userId: senderId, size: "w-20" }),
        $(
          "div",
          { class: "ml-10" },
          $(UserName, {
            userId: senderId,
            class: "font-bold font-size-14 color-secondary-60",
          })
        ),
      ]),
      $(
        "div",
        { class: "mt-5" },
        icon === null
          ? $(
              "p",
              {
                class:
                  "font-size-14 color-secondary-60 message-footer-parent-content",
              },
              contentText
            )
          : $("div", { class: "flex align-center" }, [
              $(
                "div",
                { class: "font-size-12 color-secondary-60" },
                $("i", { class: `fas ${icon}` })
              ),
              $(
                "span",
                {
                  class: "ml-5 text-truncate font-size-14 color-secondary-60",
                },
                contentText
              ),
            ])
      ),
    ]),
    isImageOrVideo
      ? $(
          "div",
          { class: "w-65 h-65" },
          $("img", {
            class: "w-100% h-100% object-fit-cover",
            src: source,
          })
        )
      : null,
    $(
      "div",
      { class: "absolute top-5 right-5" },
      $(
        "button",
        {
          class: `w-25 h-25 flex justify-center align-center rounded-100% ${
            isImageOrVideo
              ? "bg-color-secondary-20 font-size-16 color-white hover:bg-color-secondary-25"
              : "font-size-14 color-secondary-60 hover:bg-color-blank-100-10%"
          } transition-300ms`,
          onclick: onDismissReply,
        },
        $("i", { class: "fas fa-xmark" })
      )
    ),
  ]);
}

export default Wrapper.wrapContext(Parent, DocumentContext, ({ parentId }) => {
  const documents = DocumentContext.getDocuments();

  const {
    action,
    type,
    text,
    media: mediaId,
    mentions: mentionIds,
    sender: senderId,
  } = documents.Message[parentId];

  let source = null;
  let duration = null;

  if (mediaId !== null) {
    const media = documents.Media[mediaId];

    const { poster: posterId } = media;

    const poster = posterId !== null ? documents.Media[posterId] : null;

    const { small: smallId } = poster !== null ? poster : media;

    if (smallId !== null) {
      ({ source } = documents.MediaSize[smallId]);
    }

    ({ duration } = media);
  }

  const mentionNames = mentionIds.map(
    (mentionId) => documents.User[mentionId].name
  );

  return {
    action,
    type,
    text,
    source,
    duration,
    senderId,
    mentionIds,
    mentionNames,
  };
});
