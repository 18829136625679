import TranslationContext from "../context/TranslationContext";

class Helper {
  static USERNAME_PATTERN = /^@([a-zA-Z0-9_]{1,20})$/;
  static HASHTAG_PATTERN = /^(#[a-z\d-]+)$/;
  static USER_ID_PATTERN = /^@[a-f\d]{24}$/;
  static URL_PATTERN =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

  static getTitle(title) {
    return `${title} | ${TranslationContext.getData().title}`;
  }

  static formatCount(number, digits = 1) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    const item = lookup
      .slice()
      .reverse()
      .find((item) => {
        return number >= item.value;
      });

    return item !== undefined
      ? (number / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  static formatTime(ms) {
    const format = (val) => `0${Math.floor(val)}`.slice(-2);

    const seconds = Math.ceil(ms / 1000);

    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;

    const fragments = [hours, minutes, seconds % 60].map(format);

    const join = fragments.slice(fragments[0] === "00" ? 1 : 0).join(":");

    return join.charAt(0) === "0" ? join.slice(1) : join;
  }

  static formatDateTimeNumber(number) {
    return `${number < 10 ? 0 : ""}${number}`;
  }

  static formatDateTime(dateString) {
    const date = new Date(dateString);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const am = hours < 12;

    const hour = hours === 0 ? 12 : hours <= 12 ? hours : hours - 12;
    const minute = this.formatDateTimeNumber(minutes);

    return `${hour}:${minute} ${
      am
        ? TranslationContext.getData().dates.am
        : TranslationContext.getData().dates.pm
    }`;
  }

  static getChatRequestKey({ chatId, userId }) {
    return chatId !== null ? `Chat-chatId-${chatId}` : `Chat-userId-${userId}`;
  }
}

export default Helper;
