import { Context } from "untrue";

import UploadHelper from "../helpers/UploadHelper";

import RequestContext from "./RequestContext";

import { client } from "../client";

class CreatePostContext extends Context {
  constructor() {
    super();

    this.data = {};
  }

  getData() {
    return this.data;
  }

  insert(text, figures, albumId, placeId, coordinates) {
    const key = Date.now().toString();

    this.data[key] = {
      loading: false,
      done: false,
      error: null,
      params: { text, figures, albumId, placeId, coordinates },
    };

    this.run();
  }

  async run() {
    const keys = Object.keys(this.data);

    const loading = keys.some((key) => this.data[key].loading);

    if (loading) {
      return;
    }

    const index = keys.findIndex((key) => {
      const item = this.data[key];

      return !item.loading && !item.done && item.error === null;
    });

    if (index === -1) {
      return;
    }

    const key = keys[index];

    await this.createPost(key);

    this.run();
  }

  async createPost(key) {
    const item = this.data[key];

    item.loading = true;

    this.update();

    try {
      const { text, figures, albumId, placeId, coordinates } = item.params;

      const postFigures = await Promise.all(
        figures.map(async (figure) => {
          const { media, tags } = figure;

          if (media.type === "image") {
            const upload = UploadHelper.createUpload(media);

            return { upload, tags };
          }

          const videoId = await UploadHelper.createVideo(media);

          return { videoId, tags };
        })
      );

      const response = await client.request({
        createPost: {
          text,
          figures: postFigures,
          albumId,
          placeId,
          coordinates,
        },
      });

      const data = response.data();

      const {
        createPost: { id: postId },
      } = data;

      RequestContext.onCreatePost(postId);

      item.done = true;
    } catch (error) {
      item.error = error;
    } finally {
      item.loading = false;

      this.update();
    }
  }
}

export default new CreatePostContext();
