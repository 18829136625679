import { Context } from "untrue";

class ViewMediaModalContext extends Context {
  constructor() {
    super();

    this.state = { item: null };
  }

  view({ source, type, duration }) {
    const item = { source, type, duration };

    this.updateState({ item });
  }

  clear() {
    this.updateState({ item: null });
  }
}

export default new ViewMediaModalContext();
