import $, { Component } from "untrue";

import IconButton from "./IconButton";

import { client } from "../../../../../../client";

class TransactionButton extends Component {
  constructor(props) {
    super(props);

    this.state = { count: null };

    this.subscription = null;

    this.on("mount", this.onMountSubscription);
    this.on("unmount", this.onUnmountSubscription);
  }

  onMountSubscription = () => {
    this.subscription = client.subscribe({ notSeenTransactionsCount: null });

    this.subscription.on("data", (data) => {
      const { notSeenTransactionsCount } = data;

      this.updateState({ count: notSeenTransactionsCount });
    });
  };

  onUnmountSubscription = () => {
    this.subscription.unsubscribe();
  };

  render() {
    const { count } = this.state;

    return $(IconButton, {
      counterKey: "transactionsCount",
      name: "transactions",
      icon: "fa-dollar-sign",
      count,
      ...this.props,
    });
  }
}

export default TransactionButton;
