import $, { Wrapper } from "untrue";

import DocumentContext from "../../../../../../../context/DocumentContext";

function Album({ picture }) {
  return $("img", { class: "w-50 aspect-1/1 rounded-6", src: picture });
}

export default Wrapper.wrapContext(Album, DocumentContext, ({ albumId }) => {
  const documents = DocumentContext.getDocuments();

  const { picture: pictureId } = documents.Album[albumId];

  const { small: smallId } = documents.Media[pictureId];

  const { source: picture } = documents.MediaSize[smallId];

  return { picture };
});
