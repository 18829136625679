import $, { Wrapper } from "untrue";

import TranslationContext from "../../../../../context/TranslationContext";
import RequestContext from "../../../../../context/RequestContext";
import DocumentContext from "../../../../../context/DocumentContext";

import Block from "./Block";

function DateWrapper({ requestKey, chatId, date, blocksCount, ...props }) {
  const split = date.split("-");

  const dateObj = new Date(split[0], split[1], split[2]);

  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  yesterday.setTime(yesterday.getTime() - 1000 * 60 * 60 * 24);

  const isToday = dateObj.getTime() === today.getTime();

  const isYesterday = dateObj.getTime() === yesterday.getTime();

  let dateText = "";

  if (isToday) {
    dateText = TranslationContext.getData().dates.today;
  } else if (isYesterday) {
    dateText = TranslationContext.getData().dates.yesterday;
  } else {
    dateText = `${
      TranslationContext.getData().dates.months[dateObj.getMonth()]
    } ${dateObj.getDate()}`;

    if (dateObj.getFullYear() !== now.getFullYear()) {
      dateText += `, ${dateObj.getFullYear()}`;
    }
  }

  const blocks = [];

  for (let i = 0; i < blocksCount; i++) {
    blocks.push(
      $(
        "div",
        { class: "my-5" },
        $(Block, {
          requestKey,
          chatId,
          date,
          blockIndex: i,
          ...props,
        })
      )
    );
  }

  return $("div", { class: "flex flex-column" }, [
    $(
      "div",
      { class: "flex justify-center my-10 mx-15" },
      $(
        "div",
        { class: "bg-color-secondary-20 rounded-6 px-10 py-5" },
        $(
          "span",
          { class: "font-size-12 color-secondary-70 font-bold" },
          dateText
        )
      )
    ),
    blocks,
  ]);
}

export default Wrapper.wrapContext(
  DateWrapper,
  [RequestContext, DocumentContext],
  ({ requestKey, date }) => {
    const requests = RequestContext.getRequests();
    const documents = DocumentContext.getDocuments();

    const {
      data: { nodes: messageIds },
    } = requests[requestKey].data.messages;

    const dateMessageIds = messageIds.filter((messageId) => {
      const { createdAt } = documents.Message[messageId];

      const tmpDate = new Date(createdAt);

      const string = `${tmpDate.getFullYear()}-${tmpDate.getMonth()}-${tmpDate.getDate()}`;

      return string === date;
    });

    const reverseMessageIds = [...dateMessageIds].reverse();

    const blocks = [];

    let prevMessageId = null;

    let currentBlockIndex = -1;

    for (const messageId of reverseMessageIds) {
      const { sender: senderId } = documents.Message[messageId];

      if (senderId !== null) {
        if (prevMessageId !== null) {
          const { sender: prevSenderId } = documents.Message[prevMessageId];

          if (senderId !== prevSenderId) {
            currentBlockIndex++;
          }
        } else {
          currentBlockIndex++;
        }
      } else {
        currentBlockIndex++;
      }

      const blockMessageIds =
        currentBlockIndex <= blocks.length - 1 ? blocks[currentBlockIndex] : [];

      blocks[currentBlockIndex] = [...blockMessageIds, messageId];

      prevMessageId = messageId;
    }

    const blocksCount = blocks.length;

    return { blocksCount };
  }
);
