import $, { Wrapper } from "untrue";

import RequestContext from "../../../../../context/RequestContext";
import DocumentContext from "../../../../../context/DocumentContext";
import TranslationContext from "../../../../../context/TranslationContext";

import Content from "./Content";

function Footer({ member, group, ...props }) {
  if (member !== null && !member) {
    return $(
      "div",
      {
        class:
          "bg-color-secondary-20 flex items-center justify-center text-align-center px-20 py-10",
      },
      $(
        "span",
        { class: "color-secondary-60" },
        group
          ? TranslationContext.getData().chats.nonMember.group
          : TranslationContext.getData().chats.nonMember.user
      )
    );
  }

  return $(Content, props);
}

export default Wrapper.wrapContext(
  Footer,
  [RequestContext, DocumentContext],
  ({ requestKey }) => {
    const requests = RequestContext.getRequests();

    let chatId = null;

    const { data = null } = requests?.[requestKey] ?? {};

    if (data !== null) {
      chatId = data.chat;
    }

    return { chatId };
  },
  ({ chatId }) => {
    const documents = DocumentContext.getDocuments();

    let member = null;
    let group = null;

    if ("Chat" in documents && chatId in documents.Chat) {
      ({ member, group } = documents.Chat[chatId]);
    }

    return { member, group };
  }
);
