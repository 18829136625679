import $ from "untrue";

import { Head } from "@untrue/web";

import Helper from "../../helpers/Helper";

import TranslationContext from "../../context/TranslationContext";

function NotFound() {
  return [
    $(
      Head,
      $("title", Helper.getTitle(TranslationContext.getData().titles.notFound))
    ),
    $("div", { class: "container mx-auto p-30" }, "Not found."),
  ];
}

export default NotFound;
